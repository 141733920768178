import { useQuery } from 'react-query';

import { useToken } from '@zerowaste/components';

import axios from 'axios';


export default function useProfile({ refetch }={}) {
  const { token, setToken } = useToken();

  return useQuery(
    ['profile', { token, refetch }],
    () => !!token && axios.get('/api/profile/', { params: { refetch } }).then(
      ({data}) => data,
      (err) => {
        if (err.response?.status === 401) {
          console.warn(err.response.data?.detail);
          if (err.response.data?.detail === 'User inactive or deleted.') {
            // token invalid so bugger off
            setToken(null);
          } else if (err.response.data?.detail === 'Invalid token.') {
            // token invalid so bugger off
            setToken(null);
          }
        }
      }
    ), {
      enabled: false
    }
  );

}
