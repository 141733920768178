import { useMutation, useQuery } from 'react-query';

import axios from 'axios';
import { useState } from 'react';

export const useCategories = ({ params = {}, options = {} } = {}) => {
  if (!('ordering' in params)) {
    params.ordering = 'code';
  }

  return useQuery(
    ['categories', params],
    () => axios.get('/api/categories/', { params }).then(({ data }) => data),
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      ...options,
    }
  );
}

export const useCategory = (id, { options = {} } = {}) =>
  useQuery(
    ['categories', { id }],
    () => axios.get(`/api/categories/${id}/`).then(({ data }) => data),
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      ...options,
    }
  );

export const useDeleteCategoryImage = (id, onSuccess) =>
  useMutation(
    () =>
      axios.delete(`/api/categories/${id}/images/`).then(({ data }) => data),
    { onSuccess }
  );

export const useUploadCategoryImage = (id, onSuccess) => {
  const [progress, setProgress] = useState(0);

  const resetProgress = () => {
    setProgress(0);
  };
  const uploadMutation = useMutation(
    (data) =>
      axios
        .post(`/api/categories/${id}/images/`, data, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: (progressEvent) => {
            const progress = (progressEvent.loaded / progressEvent.total) * 100;
            setProgress(progress);
          },
          onDownloadProgress: (progressEvent) => {
            const progress = (progressEvent.loaded / progressEvent.total) * 100;
            setProgress(progress);
          },
        })
        .then(({ data }) => data),
    { onSuccess, onMutate: resetProgress }
  );

  return { progress, uploadMutation };
};

export const useCreateCategory = (onSuccess) =>
  useMutation(
    (data) => axios.post('/api/categories/', data).then(({ data }) => data),
    { onSuccess }
  );

export const useUpdateCategory = (id, onSuccess) =>
  useMutation(
    (data) =>
      axios.patch(`/api/categories/${id}/`, data).then(({ data }) => data),
    { onSuccess }
  );

export const useDeleteCategory = (id, onSuccess) =>
  useMutation(
    (data) =>
      axios.delete(`/api/categories/${id}/`, data).then(({ data }) => data),
    { onSuccess }
  );
