import { Container, Divider, Header } from 'semantic-ui-react';

import useProfile from '../queries/useProfile';

import RepairmanProfileForm from './repairmen/RepairmanProfileForm';

export default function RepairmanProfile() {
  const profile = useProfile();

  return (
    <>
      <Container textAlign="center">
        <Header as="h1" className="colored atmgreen">
          Διαχείριση στοιχείων λογαριασμού
        </Header>
        <Divider className="orange" />
        <Divider hidden />
      </Container>
      <RepairmanProfileForm repairmanId={profile.data?.repairman.id} />
    </>
  );
}
