import { Helmet } from 'react-helmet';
import {
  useHistory,
  useParams,
  useRouteMatch,
  Redirect,
  Link,
  Route,
  Switch,
} from 'react-router-dom';

import {
  Button,
  Divider,
  Grid,
  Icon,
  Message,
  Modal,
  Popup,
  Segment,
  Table,
} from 'semantic-ui-react';

import { OrientedPagination } from '@zerowaste/components';

import getObjectValue from 'lodash/get';

function ModalSkeleton({ onClose, type, nameGenitive, form, readOnly }) {
  const params = useParams();
  const typeId = params[type + 'Id'];

  return (
    <Modal size="small" open onClose={onClose} dimmer="inverted">
      <Modal.Header>
        {typeId === 'new'
          ? `Δημιουργία ${nameGenitive}`
          : readOnly
          ? `Πληροφορίες ${nameGenitive}`
          : `Επεξεργασία ${nameGenitive}`}
      </Modal.Header>
      <Modal.Content>{form}</Modal.Content>
    </Modal>
  );
}

export default function ManageDataContainer({
  baseUrl,
  pageTitle,
  type,
  nameGenitive,
  data,
  loading,
  fetching,
  error,
  headerCells = [],
  dataCellProps = {},
  dataAttributes,
  filters,
  form,
  imageModal: ImageModal,
  readOnly,
  disableAdd,
  ordering,
  setOrdering,
  orderingNames,
  extraCells: ExtraCells,
}) {
  const history = useHistory();
  const match = useRouteMatch();
  const params = useParams();
  const { page } = params;

  const showPagination = data?.total_pages > 1;
  const paginationProps = {
    padded: false,
    activePage: page,
    totalPages: data?.total_pages,
    onPageChange: (e, { activePage }) =>
      history.push({ pathname: `${activePage}` }),
    disabled: data?.total_pages === 1,
  };

  const getSorted = (name) =>
    ordering === name
      ? 'descending'
      : ordering === `-${name}`
      ? 'ascending'
      : null;

  const handleHeaderClick = (name) => {
    setOrdering((oldState) => {
      if (oldState === name) {
        return `-${name}`;
      } else {
        return name;
      }
    });
  };

  const handleModalClose = () => {
    history.push(match.url);
  };

  if (!page) {
    return <Redirect to={`${baseUrl}/page/1`} />;
  }

  return (
    <>
      <Helmet title={pageTitle} />
      <Divider hidden />
      <Grid>
        <Grid.Row>
          <Grid.Column width={7}>
            {showPagination && <OrientedPagination {...paginationProps} />}
          </Grid.Column>
          <Grid.Column width={9}>{filters && filters}</Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Segment basic loading={loading} placeholder={loading}>
              <Table
                sortable={!!ordering}
                celled
                selectable
                className="pointable"
                fixed
                style={{
                  opacity: fetching ? '0.5' : '1',
                }}
              >
                <Table.Header>
                  <Table.Row>
                    {headerCells.map((cellName, index) => (
                      <Table.HeaderCell
                        style={{
                          width: cellName === 'Κωδικός' ? '150px' : 'unset',
                          maxWidth: '300px',
                          pointerEvents: orderingNames?.[index]
                            ? 'all'
                            : 'none',
                        }}
                        key={index}
                        sorted={getSorted(orderingNames?.[index])}
                        onClick={() =>
                          orderingNames?.[index] &&
                          handleHeaderClick(orderingNames?.[index])
                        }
                      >
                        {cellName}
                      </Table.HeaderCell>
                    ))}
                    {ImageModal && (
                      <Table.HeaderCell
                        style={{ pointerEvents: 'none', width: '65px' }}
                      />
                    )}
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {data?.results.map((data) => (
                    <Table.Row
                      key={data.id}
                      onClick={(e) => {
                        e.target.tagName !== 'BUTTON' &&
                          history.push(`${match.url}/${type}/${data.id}`);
                      }}
                    >
                      {dataAttributes.map((attribute, index) => {
                        const value = getObjectValue(data, attribute);
                        return (
                          <Table.Cell
                            singleLine
                            style={{ maxWidth: '300px' }}
                            key={index}
                            {...dataCellProps[attribute]}
                          >
                            {value}
                          </Table.Cell>
                        );
                      })}
                      {ExtraCells && <ExtraCells data={data} />}
                      {ImageModal && (
                        <Table.Cell>
                          <Popup
                            content={`Φωτογραφίες ${nameGenitive}`}
                            trigger={
                              <Button
                                positive={!!data.images?.length}
                                icon={
                                  <Icon
                                    name="image"
                                    style={{ pointerEvents: 'none' }}
                                  />
                                }
                                onClick={() => {
                                  history.push(
                                    `${match.url}/${type}/${data.id}/image`
                                  );
                                }}
                              />
                            }
                          />
                        </Table.Cell>
                      )}
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
              {data?.results.length === 0 && (
                <Message info>Δεν βρεθηκαν δεδομένα</Message>
              )}
              {error && <Message error>Προέκυψε κάποιο σφάλμα</Message>}
              {!readOnly && !disableAdd && (
                <Button
                  as={Link}
                  to={`${match.url}/${type}/new`}
                  floated="right"
                  positive
                  icon="plus"
                  labelPosition="left"
                  content={`Προσθήκη ${nameGenitive}`}
                />
              )}
              <Switch>
                <Route
                  exact
                  path={`${baseUrl}/page/${page}/${type}/:${type}Id(\\d+${
                    readOnly || disableAdd ? '' : '|new'
                  })`}
                >
                  <ModalSkeleton
                    onClose={handleModalClose}
                    type={type}
                    nameGenitive={nameGenitive}
                    form={form}
                    readOnly={readOnly}
                  />
                </Route>
                {ImageModal && (
                  <Route
                    path={`${baseUrl}/page/${page}/${type}/:${type}Id/image`}
                  >
                    <ImageModal onClose={handleModalClose} />
                  </Route>
                )}
              </Switch>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
}
