import { useState } from 'react';

export default function useForm(defaultValues = {}) {
  const [formData, setFormData] = useState({
    ...defaultValues,
    materials: [],
  });


  const handleDelete = (index) => {
    setFormData((formData) => ({
      ...formData,
      materials: [...formData.materials.filter((_, i) => i !== index)],
    }));
  };

  const handleFormChange = (name, value) => {
    setFormData((formData) => ({
      ...formData,
      [name]: value,
    }));
  };

  const handleMaterialUpdate = (material, index) => {
    setFormData((formData) => ({
      ...formData,
      materials: [
        ...formData.materials.map((formMaterial, i) => {
          if (i === index) {
            return { ...formMaterial, ...material };
          } else {
            return formMaterial;
          }
        }),
      ],
    }));
  };

  const handleMaterialAdd = (material) => {
    setFormData((formData) => ({
      ...formData,
      materials: [...formData.materials, material],
    }));
  };

  const handleFormClear = () => {
    setFormData({ ...defaultValues, materials: [] });
  };

  return {
    formData,
    handleMaterialAdd,
    handleMaterialUpdate,
    handleFormChange,
    handleFormClear,
    handleDelete,
  };
}
