import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { Helmet } from 'react-helmet';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import {
  Button,
  Container,
  Divider,
  Form,
  Grid,
  Header,
  List,
  Loader,
  Menu,
  Message,
  Modal,
  Segment,
  Table,
} from 'semantic-ui-react';

import { ConfirmPopup, OrientedPagination } from '@zerowaste/components';

import {
  useCancelReservation,
  useMakeReservation,
  useReservation,
  useReservationLocation,
  useReservations,
} from '../queries/reservations';
import { useMaterialItem } from '../queries/materialItems';

import { MaterialCard } from './ReusePickup';

import useProfile from '../queries/useProfile';
import { useMaterialExport } from '../queries/materials';
import { UserSelect } from './TransactionForm';

function ReservationInfo({
  onClose,
  isParticipant,
  isFreshReservation,
  onDismiss,
}) {
  const profile = useProfile();

  const isEmployee =
    profile.data?.is_supervisor === false && profile.data?.is_employee === true;
  const isSupervisor = profile.data?.is_supervisor === true;

  const { reservationId } = useParams();
  const queryClient = useQueryClient();
  const reservation = useReservation(reservationId);

  const cancelReservation = useCancelReservation(reservationId);
  const handleCancel = () => {
    cancelReservation.mutate(
      {},
      {
        onSuccess: () => {
          queryClient.invalidateQueries('reservations');
        },
      }
    );
  };

  const [kilos, setKilos] = useState(0);
  const handleChange = (e, { value }) => {
    setKilos(value);
  };
  const completeReservation = useMaterialExport();
  const handleCompleteReservation = () => {
    const dataToSubmit = {
      extraction_type: 'REUSE',
      materials: [
        {
          material: reservation.data?.item.material,
          amount: 1,
          kilos,
          item: reservation.data?.item.id,
        },
      ],
    };
    if (reservation.data?.participant) {
      dataToSubmit.user_id = reservation.data?.participant;
    } else {
      dataToSubmit.user_text = `${reservation.data?.first_name} ${reservation.data?.last_name}`;
    }
    completeReservation.mutate(dataToSubmit, {
      onSuccess: () => {
        queryClient.invalidateQueries('reservations');
      },
    });
  };

  return (
    <Modal size="small" open onClose={onClose} dimmer="inverted">
      <Modal.Header>Πληροφορίες κράτησης</Modal.Header>
      <Modal.Content>
        <Container
          as={Segment}
          basic
          loading={reservation.isLoading}
          disabled={reservation.isLoading}
        >
          {isFreshReservation && (
            <Message
              success
              content="Η κράτηση δημιουργήθηκε επιτυχώς"
              onDismiss={onDismiss}
            />
          )}
          {completeReservation.isSuccess && (
            <Message
              success
              content="Η κράτηση παραδόθηκε επιτυχώς"
              onDismiss={() => {
                completeReservation.reset();
              }}
            />
          )}
          <List
            className="material-description"
            divided
            relaxed="very"
            style={{ margin: '0 auto' }}
          >
            <List.Item>
              <List.Header>Κατάσταση:</List.Header>
              <List.Content floated="right">
                {reservation.data?.status === 'cancelled'
                  ? 'Ακυρωμένη'
                  : reservation.data?.status === 'active'
                  ? 'Ενεργή'
                  : reservation.data?.status === 'expired'
                  ? 'Ληγμένη'
                  : 'Ολοκληρωμένη'}
              </List.Content>
            </List.Item>
            {!isParticipant && (
              <>
                <List.Item>
                  <List.Header>Εγγεγραμμένος:</List.Header>
                  <List.Content floated="right">
                    {reservation.data?.participant ? 'Ναι' : 'Όχι'}
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Header>Ονοματεπώνυμο:</List.Header>
                  <List.Content floated="right">
                    {reservation.data?.first_name} {reservation.data?.last_name}
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Header>Email:</List.Header>
                  <List.Content floated="right">
                    {reservation.data?.email || '-'}
                  </List.Content>
                </List.Item>
              </>
            )}
            <List.Item>
              <List.Header>Ημερομηνία υποβολής</List.Header>
              <List.Content floated="right">
                {new Date(reservation.data?.created_at).toLocaleString('el')}
              </List.Content>
            </List.Item>
            <List.Item>
              {(reservation.data?.status === 'active' ||
                reservation.data?.status === 'expired' ||
                reservation.data?.status === 'cancelled') && (
                <>
                  <List.Header>
                    Ημερομηνία{' '}
                    {reservation.data?.status === 'cancelled'
                      ? 'ακύρωσης'
                      : 'λήξης'}
                  </List.Header>
                  <List.Content floated="right">
                    {new Date(reservation.data?.expires_at).toLocaleString(
                      'el'
                    )}
                  </List.Content>
                </>
              )}
              {reservation.data?.status === 'completed' && (
                <>
                  <List.Header>Ημερομηνία παράδοσης</List.Header>
                  <List.Content floated="right">
                    {new Date(reservation.data?.delivered_at).toLocaleString(
                      'el'
                    )}
                  </List.Content>
                </>
              )}
            </List.Item>
          </List>
        </Container>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <Header as="h3">Υλικό</Header>
          <MaterialCard
            height="250px"
            images={reservation.data?.item.images || []}
            meta={reservation.data?.item.material_name}
            title={reservation.data?.item.description}
          />
        </div>
        {cancelReservation.isSuccess && (
          <Message
            success
            content="Η κράτηση ακυρώθηκε επιτυχώς"
            onDismiss={() => {
              cancelReservation.reset();
            }}
          />
        )}
      </Modal.Content>
      <Modal.Actions>
        {reservation.data?.status === 'active' && isEmployee && (
          <div style={{ marginBottom: '16px' }}>
            <ConfirmPopup
              position="top center"
              trigger={
                <Button
                  positive
                  content="Παράδοση κράτησης"
                  loading={completeReservation.isLoading}
                />
              }
              onConfirm={handleCompleteReservation}
              error={
                completeReservation.isError &&
                'Δεν ήταν δυνατή η παράδοση της κράτησης'
              }
              onDismissError={() => completeReservation.reset()}
              positive="Υποβολή"
              negative="Ακύρωση"
            >
              <Header size="small" dividing>
                Προσθέστε τα κιλά του υλικού
              </Header>
              <Form style={{ marginBottom: '14px' }}>
                <Form.Input
                  name="kilos"
                  type="number"
                  input={{ inputMode: 'decimal', min: 0, step: 0.01 }}
                  value={kilos || 0}
                  onChange={handleChange}
                />
              </Form>
            </ConfirmPopup>
          </div>
        )}
        <Button onClick={onClose} content="Επιστροφή" />
        {reservation.data?.status === 'active' && isSupervisor && (
          <ConfirmPopup
            position="top center"
            trigger={
              <Button
                loading={cancelReservation.isLoading}
                disabled={cancelReservation.isLoading}
                negative
                floated="left"
                content="Ακύρωση κράτησης"
              />
            }
            onConfirm={handleCancel}
            error={
              cancelReservation.isError &&
              'Δεν ήταν δυνατή η ακύρωση της κράτησης'
            }
            onDismissError={() => cancelReservation.reset()}
            positive="Ναι"
            negative="Όχι"
          >
            <Header size="small" dividing>
              Θέλετε να ακυρώσετε αυτήν την κράτηση;
            </Header>
          </ConfirmPopup>
        )}
      </Modal.Actions>
    </Modal>
  );
}
function CreateReservation({ onClose, parentUrl, makeReservation }) {
  const queryClient = useQueryClient();
  const history = useHistory();

  const [userType, setUserType] = useState('registered');

  const { itemId } = useParams();
  const materialItem = useMaterialItem(itemId);

  const [emailSearch, setEmailSearch] = useState('');
  const [formData, setFormData] = useState(() => ({}));

  const handleMenuClick = (e, { name }) => {
    if (userType !== name) {
      setUserType(name);
      setFormData({});
      setEmailSearch('');
      makeReservation.reset();
    }
  };
  const handleFormChange = (e, { name, value }) => {
    setFormData((formData) => ({ ...formData, [name]: value }));
  };

  const handleSubmit = () => {
    const dataToSubmit =
      userType === 'registered'
        ? {
            item: itemId,
            participant: formData.user_id,
          }
        : {
            item: itemId,
            first_name: formData.first_name,
            last_name: formData.last_name,
            telephone: formData.telephone,
            email: formData.email,
          };
    makeReservation.mutate(dataToSubmit, {
      onSuccess: (data) => {
        queryClient.invalidateQueries('reservations');
        history.push(`${parentUrl}/reservation/${data.id}`);
      },
    });
  };

  return (
    <Modal size="small" open onClose={onClose} dimmer="inverted">
      <Modal.Header>Δημιουργία κράτησης</Modal.Header>
      <Modal.Content>
        <Menu secondary pointing size="large">
          <Menu.Item
            active={userType === 'registered'}
            name="registered"
            onClick={handleMenuClick}
          >
            Εγγεγραμμένος χρήστης
          </Menu.Item>
          <Menu.Item
            active={userType === 'not-registered'}
            name="not-registered"
            onClick={handleMenuClick}
          >
            Μη εγγεγραμμένος χρήστης
          </Menu.Item>
        </Menu>
        <Form id="reservation-form" onSubmit={handleSubmit}>
          {userType === 'registered' && (
            <UserSelect
              showAnonymous={false}
              email={formData.user_email}
              onChange={(name, value, e) => {
                handleFormChange(e, { name, value });
              }}
              emailSearch={emailSearch}
              setEmailSearch={setEmailSearch}
              emailError={makeReservation.error?.response.data}
              resetErrors={() => {
                makeReservation.reset();
              }}
            />
          )}
          {userType === 'not-registered' && (
            <>
              <Form.Group widths="equal">
                <Form.Input
                  label="Όνομα"
                  name="first_name"
                  onChange={handleFormChange}
                  required
                />
                <Form.Input
                  name="last_name"
                  onChange={handleFormChange}
                  label="Επώνυμο"
                  required
                />
              </Form.Group>
              <Form.Input
                name="email"
                onChange={handleFormChange}
                label="Email"
                type="email"
              />
              <Form.Input
                name="telephone"
                onChange={handleFormChange}
                label="Τηλέφωνο"
                type="telephone"
                required
              />
            </>
          )}
        </Form>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <Divider hidden />
          <Header as="h3">Υλικό</Header>
          <MaterialCard
            height="250px"
            images={materialItem.data?.images || []}
            meta={materialItem.data?.material_name}
            title={materialItem.data?.description}
          />
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button
          form="reservation-form"
          loading={makeReservation.isLoading}
          disabled={makeReservation.isLoading}
          positive
          content="Δημιουργία"
        />
        <Button content="Επιστροφή" onClick={onClose} />
      </Modal.Actions>
    </Modal>
  );
}

function HoverableRow({ onClick, rowFlags, reservation, isParticipant }) {
  const {
    first_name,
    last_name,
    participant,
    email,
    item,
    created_at,
    status,
    expires_at,
    delivered_at,
  } = reservation;

  const [isHovered, setIsHovered] = useState(false);

  const hoveredStyle = { filter: isHovered ? 'brightness(97%)' : undefined };

  return (
    <div
      style={{
        display: 'contents',
        cursor: 'pointer',
      }}
      onClick={onClick}
      onMouseEnter={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
    >
      <Table.Row {...rowFlags} verticalAlign="top" style={{ ...hoveredStyle }}>
        {!isParticipant && (
          <>
            <Table.Cell rowSpan={2}>{participant ? 'Ναι' : 'Όχι'}</Table.Cell>
            <Table.Cell rowSpan={2}>
              {first_name} {last_name}
            </Table.Cell>
            <Table.Cell rowSpan={2}>{email || '-'}</Table.Cell>
          </>
        )}
        <Table.Cell rowSpan={2}>
          {item.material_name} {item.description}
        </Table.Cell>
        <Table.Cell>Υποβολής</Table.Cell>
        <Table.Cell>{new Date(created_at).toLocaleString('el')}</Table.Cell>
        <Table.Cell rowSpan={2}>
          <strong>
            {status === 'cancelled'
              ? 'Ακυρωμένη'
              : status === 'active'
              ? 'Ενεργή'
              : status === 'expired'
              ? 'Ληγμένη'
              : 'Ολοκληρωμένη'}
          </strong>
        </Table.Cell>
      </Table.Row>

      {(status === 'active' ||
        status === 'expired' ||
        status === 'cancelled') && (
        <Table.Row {...rowFlags} style={{ ...hoveredStyle }}>
          <Table.Cell>
            {status === 'cancelled' ? 'Ακύρωσης' : 'Λήξης'}
          </Table.Cell>
          <Table.Cell>{new Date(expires_at).toLocaleString('el')}</Table.Cell>
        </Table.Row>
      )}

      {status === 'completed' && (
        <Table.Row {...rowFlags} style={{ ...hoveredStyle }}>
          <Table.Cell>Παράδοσης</Table.Cell>
          <Table.Cell>{new Date(delivered_at).toLocaleString('el')}</Table.Cell>
        </Table.Row>
      )}
    </div>
  );
}

export default function AdminReservations({ baseUrl }) {
  const pageTitle = 'Διαχείριση κρατήσεων';

  const profile = useProfile();
  const isParticipant = !!profile.data?.participant;

  const match = useRouteMatch();
  const history = useHistory();
  const params = useParams();
  const { page, reservationId } = params;

  const reservations = useReservations({ page }, { keepPreviousData: true });
  const showPagination = reservations.data?.total_pages > 1;
  const paginationProps = {
    padded: false,
    activePage: page,
    totalPages: reservations.data?.total_pages,
    onPageChange: (e, { activePage }) =>
      history.push({ pathname: `${activePage}` }),
    disabled: reservations.data?.total_pages === 1,
  };

  const makeReservation = useMakeReservation();

  const handleModalClose = () => {
    history.push(match.url);
    makeReservation.reset();
  };

  // we need this to get the page for the reservation if needed
  const reservationLocation = useReservationLocation(reservationId);

  if (!page) {
    if (reservationId) {
      // we need to figure out the page of this item.
      if (reservationLocation.isLoading || reservationLocation.isIdle) {
        // we need to wait a bit the server!
        return <Loader />;
      } else if (reservationLocation.isSuccess) {
        return (
          <Redirect
            to={`${baseUrl}/page/${reservationLocation.data.page}/reservation/${reservationId}`}
          />
        );
      }
    }

    // redirect to first page if reservation was not found or not provided
    return <Redirect to={`${baseUrl}/page/1`} />;
  }

  return (
    <>
      <Helmet title={pageTitle} />
      <Divider hidden />
      <Grid>
        <Grid.Row>
          <Grid.Column width={7}>
            {showPagination && <OrientedPagination {...paginationProps} />}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Segment
              basic
              loading={reservations.isLoading}
              placeholder={reservations.isLoading}
            >
              <Table
                celled
                structured
                fixed
                style={{
                  opacity:
                    reservations.isPreviousData && reservations.isFetching
                      ? '0.5'
                      : '1',
                }}
              >
                <Table.Header>
                  <Table.Row>
                    {!isParticipant && (
                      <>
                        <Table.HeaderCell>Εγγεγραμμένος</Table.HeaderCell>
                        <Table.HeaderCell>Όνομα</Table.HeaderCell>
                        <Table.HeaderCell>Email</Table.HeaderCell>
                      </>
                    )}
                    <Table.HeaderCell>Υλικό</Table.HeaderCell>
                    <Table.HeaderCell colSpan={2}>Ημερομηνίες</Table.HeaderCell>
                    <Table.HeaderCell>Κατάσταση</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {reservations.data?.results.map((reservation) => {
                    const { status, id } = reservation;
                    const rowFlags = {
                      positive: status === 'active',
                      negative: status === 'cancelled' || status === 'expired',
                      warning: status === 'completed',
                    };
                    const handleRowClick = (e) => {
                      history.push(`${match.url}/reservation/${id}`);
                    };
                    return (
                      <HoverableRow
                        key={id}
                        isParticipant={isParticipant}
                        onClick={handleRowClick}
                        reservation={reservation}
                        rowFlags={rowFlags}
                      />
                    );
                  })}
                </Table.Body>
              </Table>
              {reservations.data?.results?.length === 0 && (
                <Message info>Δεν βρεθηκαν δεδομένα</Message>
              )}
              {reservations.isError && (
                <Message error>Προέκυψε κάποιο σφάλμα</Message>
              )}
              <Switch>
                <Route
                  exact
                  path={`${match.path}/reservation/:reservationId(\\d+)`}
                >
                  <ReservationInfo
                    onClose={handleModalClose}
                    isParticipant={isParticipant}
                    isFreshReservation={makeReservation.isSuccess}
                    onDismiss={() => {
                      makeReservation.reset();
                    }}
                  />
                </Route>
                {!isParticipant && (
                  <Route
                    exact
                    path={`${match.path}/reservation/new/item/:itemId(\\d+)`}
                  >
                    <CreateReservation
                      onClose={handleModalClose}
                      parentUrl={match.url}
                      makeReservation={makeReservation}
                    />
                  </Route>
                )}
              </Switch>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
}
