import { Container, Divider, Header, Message } from 'semantic-ui-react';

import useProfile from '../queries/useProfile';

import RepairmanOffersTable from './repairmen/RepairmanOffersTable';

export default function RepairmanOffers() {
  const profile = useProfile();
  const isVerified = profile.data?.repairman.is_verified;

  return (
    <>
      <Container textAlign="center">
        <Header as="h1" className="colored atmgreen">
          Διαχείριση Προσφορών
        </Header>
        <Divider className="orange" />
        <Divider hidden />
      </Container>
      <Container>
        {isVerified ? (
          <RepairmanOffersTable repairmanId={profile.data?.repairman.id} />
        ) : (
          <Container text>
            <Message
              icon="info circle"
              header="Απαιτείται ενεργοποίηση"
              content={
                <>
                  <p>
                    Για να δημιουργήσετε προσφορές πρεπει να ενεργοποιηθεί ο
                    λογαριασμός σας
                  </p>
                  <p>Παρακαλώ επικοινωνήστε με το ΚΔΕΥ της περιοχής σας</p>
                </>
              }
              info
            />
          </Container>
        )}
      </Container>
    </>
  );
}
