import { useMutation, useQuery } from 'react-query';
import axios from 'axios';

export const useReservations = (params, options) =>
  useQuery(
    ['reservations', params],
    () => axios.get('/api/reservations/', { params }).then(({ data }) => data),
    {
      refetchOnWindowFocus: false,
      ...options,
    }
  );

export const useReservation = (id, { enabled = true, ...options } = {}) =>
  useQuery(
    ['reservations', { id }],
    () => axios.get(`/api/reservations/${id}/`).then(({ data }) => data),
    {
      enabled: id ? enabled : false,
      ...options,
    }
  );

export const useReservationLocation = (
  id,
  { page, ...params } = {}, // never include page in params here
  { enabled = true, ...options } = {}
) =>
  useQuery(
    ['reservations', id, 'page', { params }],
    () =>
      axios
        .get(`/api/reservations/locate/${id}/`, { params })
        .then(({ data }) => data),
    { enabled: id ? enabled : false, ...options }
  );

export const useMakeReservation = () =>
  useMutation(
    ({ item, participant, first_name, last_name, email, telephone }) =>
      axios
        .post(`/api/reservations/`, {
          item,
          participant,
          first_name,
          last_name,
          email,
          telephone,
        })
        .then(({ data }) => data)
  );

export const useCancelReservation = (id) =>
  useMutation((data) =>
    axios.post(`/api/reservations/${id}/cancel/`, data).then(({ data }) => data)
  );
