import { useMutation, useQuery } from 'react-query';

import axios from 'axios';

export const useSubcategories = ({ params = {}, options = {} }) =>
  useQuery(
    ['subcategories', params],
    () => axios.get('/api/subcategories/', { params }).then(({ data }) => data),
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      ...options,
    }
  );

export const useSubcategory = (id, { options = {} }) =>
  useQuery(
    ['subcategories', { id }],
    () => axios.get(`/api/subcategories/${id}/`).then(({ data }) => data),
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      ...options,
    }
  );

export const useCreateSubcategory = (onSuccess) =>
  useMutation(
    (data) => axios.post('/api/subcategories/', data).then(({ data }) => data),
    { onSuccess }
  );

export const useUpdateSubcategory = (id, onSuccess) =>
  useMutation(
    (data) =>
      axios.patch(`/api/subcategories/${id}/`, data).then(({ data }) => data),
    { onSuccess }
  );

export const useDeleteSubcategory = (id, onSuccess) =>
  useMutation(
    (data) =>
      axios.delete(`/api/subcategories/${id}/`, data).then(({ data }) => data),
    { onSuccess }
  );
