import { Link, Route, useParams, useRouteMatch } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Container, Divider, Header, Item, Loader, Message } from 'semantic-ui-react';

import { useAnnouncement, useAnnouncements } from './queries/announcements';

import sanitizeHtml from 'sanitize-html';


function AnnouncementsList() {
  const match = useRouteMatch();
  const announcements = useAnnouncements({ status: 'PUB' });

  return (
    <>
      { announcements.isLoading && <Loader active /> }
      { announcements.isError && <Message error content="Κάτι πήγε στραβά!" /> }

      { announcements.isSuccess && 
        <Item.Group divided>
          { announcements.data?.length === 0 && <Header>Δεν υπάρχουν νέα</Header> }
          { announcements.data?.map((post) => (
              <Item key={post.id}>
                <Item.Content>
                  <Item.Header as={Link} to={`${match.url}/${post.id}`}>{ post.title }</Item.Header>
                  <Item.Extra>Αναρτήθηκε την { new Date(post.published_at).toLocaleDateString('el') }</Item.Extra>
                  <Item.Description dangerouslySetInnerHTML={{__html: sanitizeHtml(post.content)}} />
                  <Item.Extra>
                    <Link to={`${match.url}/${post.id}`}>Διαβάστε περισσότερα</Link>
                  </Item.Extra>
                </Item.Content>
              </Item>
            )
          )}
        </Item.Group>
      }

    </>
  );
}

const allowedTags = [...sanitizeHtml.defaults.allowedTags, 'img', 'video', 'figure', 'iframe'];
const allowedAttributes = false;
const allowedSchemes = [...sanitizeHtml.defaults.allowedSchemes, 'data' ];

function AnnoucementDetail() {
  const { postId } = useParams();
  const { isLoading, data: post } = useAnnouncement(postId);

  if (isLoading) return <Loader active />;

  return (<>
    <Helmet>
      <title>{ post.title } | Νέα</title>
      <meta property="og:type" content="article" />
      <meta property="og:title" content={post.title} />
      <meta property="og:image" content={post.cover_image} />
      <meta property="og:description" content={post.description} />
      <meta property="article:published_time" content={post.published_at} />
      <meta property="article:modified_time" content={post.modified_at} />
    </Helmet>

    <Item.Group>
      <Item>
        <Item.Content>
          <Item.Header>{ post.title }</Item.Header>
          <Item.Extra>Αναρτήθηκε την { new Date(post.published_at).toLocaleDateString('el') }</Item.Extra>
          <Item.Description className="ck-content" dangerouslySetInnerHTML={{__html: sanitizeHtml(post.content, {allowedTags, allowedAttributes, allowedSchemes})}} />
        </Item.Content>
      </Item>
    </Item.Group>
  </>);

}

function Announcements() {
  const match = useRouteMatch();

  return (<>
    <Helmet title="Νέα" />

    <Container>
      <Header as="h1" textAlign="center" className="colored atmgreen">Νέα</Header>

      <Divider className="orange" />
      <Divider hidden />
    </Container>

    <Container text>
      <Route exact path={match.path}>
        <AnnouncementsList />
      </Route>

      <Route path={`${match.path}/:postId(\\d+)`}>
        <AnnoucementDetail />
      </Route>

      <Divider hidden />
    </Container>
  </>);
}

export default Announcements;
