import { useState } from 'react';
import {
  generatePath,
  useHistory,
  useParams,
  useRouteMatch,
} from 'react-router-dom';

import { Button, Form } from 'semantic-ui-react';

import useCreateUpdate from '../hooks/useCreateUpdate';

import {
  useCategories,
  useCategory,
  useUploadCategoryImage,
  useDeleteCategoryImage,
  useCreateCategory,
  useUpdateCategory,
  useDeleteCategory,
} from '../queries/categories';

import { ConfirmPopup, ImageModal } from '@zerowaste/components';
import ManageDataContainer from './ManageDataContainer';
import SearchForm from './SearchForm';

import SuccessMessage, { ErrorMessage } from './SuccessMessage';

import { omit } from 'lodash';

function CategoryImageModal({ onClose }) {
  const { categoryId } = useParams();
  const category = useCategory(categoryId);

  const status = {
    isLoading: category.isLoading,
    isFetching: category.isFetching,
    isFetched: category.isFetched,
  };

  return (
    <ImageModal
      mutationId={categoryId}
      mutationType="categories"
      useUploadMutation={useUploadCategoryImage}
      useDeleteMutation={useDeleteCategoryImage}
      hasOneImage
      images={category.data?.url}
      status={status}
      onClose={onClose}
    />
  );
}

function CategoryForm({ baseUrl, onClose }) {
  const { categoryId } = useParams();

  const {
    getValue,
    handleSubmit,
    handleChange,
    handleDelete,
    isNew,
    isUpdated,
    codeError,
    serverError,
    formError,
    isMutationLoading,
    isDeleteLoading,
    type: category,
  } = useCreateUpdate({
    typeId: categoryId,
    baseUrl: `${baseUrl}/category`,
    deleteUrl: baseUrl,
    typeName: 'categories',
    useCreateMutation: useCreateCategory,
    useUpdateMutation: useUpdateCategory,
    useDeleteMutation: useDeleteCategory,
    useType: useCategory,
  });

  return (
    <>
      {isNew && (
        <SuccessMessage message="Η κατηγορία δημιουργήθηκε επιτυχώς!" />
      )}
      {isUpdated && (
        <SuccessMessage message="Η κατηγορία ενημερώθηκε επιτυχώς!" />
      )}
      <Form autoComplete="off" id="category-form" onSubmit={handleSubmit}>
        <Form.Input
          required
          label="Όνομα"
          name="name"
          value={getValue('name')}
          onChange={handleChange}
        />
        <Form.Input
          label="Κωδικός (αφήστε το κενό για αυτόματη απόδοση κωδικού)"
          name="code"
          className={isNew ? 'highlighted' : ''}
          type="number"
          value={getValue('code')}
          onChange={handleChange}
          error={codeError}
        />

        <Form.Group widths={2}>
          <Form.Input
            type="number"
            min={0}
            required
            name="pick_up_points"
            label="Πόντοι παραλαβής"
            value={getValue('pick_up_points')}
            onChange={handleChange}
          />
          <Form.Input
            type="number"
            min={0}
            required
            name="turn_in_points"
            label="Πόντοι παράδοσης"
            value={getValue('turn_in_points')}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.TextArea
          label="Περιγραφή"
          name="body"
          value={getValue('body')}
          onChange={handleChange}
        />
        <Button
          loading={isMutationLoading}
          form="category-form"
          primary
          content={categoryId === 'new' ? 'Αποθήκευση' : 'Ενημέρωση'}
        />
        <Button
          onClick={(e) => {
            e.preventDefault();
            onClose();
          }}
          content={categoryId === 'new' ? 'Ακύρωση' : 'Επιστροφή'}
        />
        {category.data?.subcategories_count === 0 && (
          <ConfirmPopup
            position="right center"
            header="Θέλετε να διαγραφεί η κατηγορία αυτή;"
            trigger={
              <Button
                negative
                floated="right"
                content="Διαγραφή"
                onClick={(e) => e.preventDefault()}
                loading={isDeleteLoading}
                disabled={isDeleteLoading}
              />
            }
            onConfirm={handleDelete}
          />
        )}

        {serverError && (
          <ErrorMessage message="Προέκυψε κάποιο σφάλμα, παρακαλώ προσπαθήστε ξανά" />
        )}
        {formError && (
          <ErrorMessage message="Υπάρχει κάποιο σφάλμα με την φόρμα. Παρακαλώ ελέγξτε τα στοιχεία και προσπαθήστε ξανά" />
        )}
      </Form>
    </>
  );
}

function CategoryFilter({ loading, value, onSearch, resetPage }) {
  return (
    <SearchForm
      value={value}
      mobile
      loading={loading}
      onSearch={(value) => {
        onSearch(value);
        resetPage && resetPage();
      }}
    />
  );
}

export default function AdminCategories() {
  const pageTitle = 'Δημιουργία / Επεξεργασία κατηγορίας';

  const match = useRouteMatch();
  const history = useHistory();
  const params = useParams();
  const { page } = params;
  const baseUrl = generatePath(match.path, omit(params, ['page', 'pageKey']));

  const [search, setSearch] = useState();
  const [ordering, setOrdering] = useState('code');

  const categories = useCategories({
    params: { page, search, ordering },
    options: { keepPreviousData: true },
  });

  const handleSearch = (value) => {
    setSearch(value);
  };

  return (
    <ManageDataContainer
      baseUrl={baseUrl}
      data={categories.data}
      loading={categories.isLoading}
      fetching={categories.isPreviousData && categories.isFetching}
      error={categories.isError}
      pageTitle={pageTitle}
      filters={
        <CategoryFilter
          resetPage={() => {
            history.replace(`${baseUrl}/page/1`);
          }}
          value={search}
          onSearch={handleSearch}
          loading={categories.isFetching}
        />
      }
      type="category"
      nameGenitive="κατηγορίας"
      ordering={ordering}
      setOrdering={setOrdering}
      headerCells={['Κωδικός', 'Όνομα']}
      orderingNames={['code', 'name']}
      dataAttributes={['code', 'name']}
      form={
        <CategoryForm
          baseUrl={match.url}
          onClose={() => {
            history.push(match.url);
          }}
        />
      }
      imageModal={CategoryImageModal}
    />
  );
}
