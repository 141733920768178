import { useState } from 'react';
import {
  generatePath,
  useHistory,
  useParams,
  useRouteMatch,
} from 'react-router-dom';

import { Button, Dropdown, Form } from 'semantic-ui-react';

import { convertArrayToOptions } from '../utils/utils';

import useCreateUpdate from '../hooks/useCreateUpdate';

import { useCategories } from '../queries/categories';
import {
  useCreateSubcategory,
  useDeleteSubcategory,
  useSubcategories,
  useSubcategory,
  useUpdateSubcategory,
} from '../queries/subcategories';

import { ConfirmPopup } from '@zerowaste/components';
import ManageDataContainer from './ManageDataContainer';
import SearchForm from './SearchForm';
import SuccessMessage, { ErrorMessage } from './SuccessMessage';

import { omit } from 'lodash';

function SubCategoryFilter({ loading, category, search, onChange, resetPage }) {
  const categories = useCategories({ params: { page_size: 400 } });

  return (
    <div style={{ display: 'flex' }}>
      <Dropdown
        className="ellipsis"
        style={{ flexGrow: 1 }}
        clearable
        selection
        name="category"
        value={category}
        onChange={(e, { name, value }) => {
          onChange(e, { name, value });
          resetPage && resetPage();
        }}
        placeholder="Όλες οι κατηγορίες"
        selectOnBlur={false}
        loading={categories.isLoading}
        options={convertArrayToOptions({
          array: categories.data?.results,
          key: 'id',
          text: 'name',
          value: 'id',
        })}
      />
      <SearchForm
        name="search"
        mobile
        value={search}
        loading={loading}
        onSearch={(name, value, e) => {
          onChange(e, { name, value });
          resetPage && resetPage();
        }}
      />
    </div>
  );
}

function SubcategoryForm({ baseUrl, onClose }) {
  const { subcategoryId } = useParams();

  const {
    formData,
    handleSubmit,
    handleChange,
    handleDelete,
    getValue,
    isNew,
    isUpdated,
    type: subcategory,
    codeError,
    serverError,
    formError,
    isMutationLoading,
    isDeleteLoading,
  } = useCreateUpdate({
    typeId: subcategoryId,
    baseUrl: `${baseUrl}/subcategory`,
    deleteUrl: baseUrl,
    typeName: 'subcategories',
    useCreateMutation: useCreateSubcategory,
    useUpdateMutation: useUpdateSubcategory,
    useDeleteMutation: useDeleteSubcategory,
    useType: useSubcategory,
    extractCategoryId: true,
  });

  const submitWithNoError = () => {
    if (!(formData?.category || subcategory.data?.category)) {
      setError(true);
    } else {
      handleSubmit();
      setError(false);
    }
  };

  const [error, setError] = useState(false);

  const categories = useCategories({
    params: { page_size: 400 },
  });

  return (
    <>
      {isNew && (
        <SuccessMessage message="Η υποκατηγορία δημιουργήθηκε επιτυχώς!" />
      )}
      {isUpdated && (
        <SuccessMessage message="Η υποκατηγορία ενημερώθηκε επιτυχώς!" />
      )}
      <Form
        autoComplete="off"
        id="subcategory-form"
        onSubmit={submitWithNoError}
      >
        <Form.Dropdown
          required
          selection
          label="Κατηγορία"
          name="category"
          error={error && 'Πρέπει να επιλέξετε μία κατηγορία'}
          value={
            JSON.stringify(formData?.category) ||
            JSON.stringify(subcategory.data?.category)
          }
          onChange={(e, { name, value }) => {
            const valueObj = JSON.parse(value);
            handleChange(e, { name, value: valueObj });
            handleChange(e, {
              name: 'pick_up_points',
              value: valueObj.pick_up_points,
            });
            handleChange(e, {
              name: 'turn_in_points',
              value: valueObj.turn_in_points,
            });
            setError(false);
          }}
          placeholder="Επιλέξτε κατηγορία"
          loading={categories.isLoading}
          options={convertArrayToOptions({
            array: categories.data?.results,
            key: 'id',
            text: 'name',
          })}
        />
        <Form.Input
          name="name"
          required
          label="Υποκατηγορία"
          value={getValue('name')}
          onChange={handleChange}
        />
        <Form.Input
          className={isNew ? 'highlighted' : ''}
          type="number"
          min={0}
          name="code"
          label="Κωδικός (αφήστε το κενό για αυτόματη απόδοση κωδικού)"
          value={getValue('code')}
          onChange={handleChange}
          error={codeError}
        />

        <Form.Group widths={2}>
          <Form.Input
            type="number"
            min={0}
            required
            name="pick_up_points"
            label="Πόντοι παραλαβής"
            value={getValue('pick_up_points')}
            onChange={handleChange}
          />
          <Form.Input
            type="number"
            min={0}
            required
            name="turn_in_points"
            label="Πόντοι παράδοσης"
            value={getValue('turn_in_points')}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.TextArea
          name="body"
          label="Περιγραφή"
          value={getValue('body')}
          onChange={handleChange}
        />

        <Button
          loading={isMutationLoading}
          form="subcategory-form"
          primary
          content={subcategoryId === 'new' ? 'Αποθήκευση' : 'Ενημέρωση'}
        />
        <Button
          onClick={(e) => {
            e.preventDefault();
            onClose();
          }}
          content={subcategoryId === 'new' ? 'Ακύρωση' : 'Επιστροφή'}
        />
        {subcategory.data?.materials_count === 0 && (
          <ConfirmPopup
            position="right center"
            header="Θέλετε να διαγραφεί η υποκατηγορία αυτή;"
            trigger={
              <Button
                negative
                floated="right"
                content="Διαγραφή"
                onClick={(e) => e.preventDefault()}
                loading={isDeleteLoading}
                disabled={isDeleteLoading}
              />
            }
            onConfirm={handleDelete}
          />
        )}
        {serverError && (
          <ErrorMessage message="Προέκυψε κάποιο σφάλμα, παρακαλώ προσπαθήστε ξανά" />
        )}
        {formError && (
          <ErrorMessage message="Υπάρχει κάποιο σφάλμα με την φόρμα. Παρακαλώ ελέγξτε τα στοιχεία και προσπαθήστε ξανά" />
        )}
      </Form>
    </>
  );
}

export default function AdminSubcategories() {
  const pageTitle = 'Δημιουργία / Επεξεργασία υποκατηγορίας';

  const match = useRouteMatch();
  const history = useHistory();
  const params = useParams();
  const { page } = params;
  const baseUrl = generatePath(match.path, omit(params, ['page', 'pageKey']));

  const [filters, setFilters] = useState(() => ({}));

  const [ordering, setOrdering] = useState('barcode');

  const subCategories = useSubcategories({
    params: { page, ordering, ...filters },
    options: { keepPreviousData: true },
  });

  const handleCategoryChange = (e, { name, value }) => {
    setFilters((values) => ({ ...values, [name]: value }));
  };

  return (
    <ManageDataContainer
      baseUrl={baseUrl}
      data={subCategories.data}
      loading={subCategories.isLoading}
      fetching={subCategories.isPreviousData && subCategories.isFetching}
      error={subCategories.isError}
      pageTitle={pageTitle}
      filters={
        <SubCategoryFilter
          resetPage={() => {
            history.replace(`${baseUrl}/page/1`);
          }}
          category={filters.category}
          search={filters.search}
          onChange={handleCategoryChange}
          loading={subCategories.isFetching}
        />
      }
      type="subcategory"
      nameGenitive="υποκατηγορίας"
      ordering={ordering}
      setOrdering={setOrdering}
      headerCells={['Κωδικός', 'Κατηγορία', 'Όνομα']}
      orderingNames={['barcode', 'category__name', 'name']}
      dataAttributes={['barcode', 'category.name', 'name']}
      form={
        <SubcategoryForm
          baseUrl={match.url}
          onClose={() => {
            history.push(match.url);
          }}
        />
      }
    />
  );
}
