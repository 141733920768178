import { useMutation, useQuery } from 'react-query';

import axios from 'axios';

export const useOffers = (
  { repairman, material, is_available, material_item, is_for_item },
  options
) =>
  useQuery(
    [
      'offers',
      { repairman, material, is_available, material_item, is_for_item },
    ],
    () =>
      axios
        .get('/api/repair_offers/', {
          params: {
            repairman,
            material,
            is_available,
            material_item,
            is_for_item,
          },
        })
        .then(({ data }) => data),
    {
      refetchOnWindowFocus: false,
      ...options,
    }
  );
export const useOffer = (id, options) =>
  useQuery(
    ['offers', { id }],
    () => axios.get(`/api/repair_offers/${id}/`).then(({ data }) => data),
    {
      refetchOnWindowFocus: false,
      ...options,
    }
  );

export const useCreateOffer = () =>
  useMutation((data) =>
    axios.post('/api/repair_offers/', data).then(({ data }) => data)
  );

export const useUpdateOffer = (id) =>
  useMutation((data) =>
    axios.patch(`/api/repair_offers/${id}/`, data).then(({ data }) => data)
  );

export const useToggleOffer = (id) =>
  useMutation(() =>
    axios.post(`/api/repair_offers/${id}/toggle/`).then(({ data }) => data)
  );

export const useDeleteOffer = (id) =>
  useMutation(() => axios.delete(`/api/repair_offers/${id}/`));
