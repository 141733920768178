import { useMemo } from 'react';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import _ from 'lodash';
import dayjs from 'dayjs';


export default function DailyStats({ data, dateFrom, dateTo, dateDataField, lines=[], height=300, yAxisWidth=32, formatter, allowDecimals=true }) {
  const timeDomain = useMemo(
    () => {
      const requestedDateFrom = dateFrom && dayjs(dateFrom).startOf('day');
      // we take this from data, but cap it to start of that month if the interval is too long
      let minDate = dayjs(_.min(_.map(data, dateDataField))).startOf('day');
      if (requestedDateFrom && minDate.diff(requestedDateFrom, 'month') < 1) {
        minDate = requestedDateFrom;
      } else {
        minDate = minDate.startOf('month');
      }

      // we take it from request parameters only
      let maxDate;
      if (dateTo) {
        maxDate = dayjs(dateTo).startOf('day');
      } else {
        // means requested max date is today
        maxDate = dayjs().startOf('day');
      }

      return [minDate.valueOf(), maxDate.valueOf()];
    },
    [data, dateDataField, dateFrom, dateTo]
  );

  const timeTicks = useMemo(
    () => {
      // figure out how much data we have to render appropriate tick marks
      const minDate = dayjs(timeDomain[0]);
      const maxDate = dayjs(timeDomain[1]);

      let tickInterval;
      if (maxDate.diff(minDate, 'month') > 2) {
        // if data range spans more than two months, ticks are months
        tickInterval = 'month';
      } else if (maxDate.diff(minDate, 'week') > 2) {
        // if data range spans more than two weeks, ticks are weeks
        tickInterval = 'week';
      } else {
        // less than or equal to two weeks
        tickInterval = 'day';
      }

      const ticks = [];
      for (
        let tick = timeDomain[0];
        tick <= timeDomain[1];
        tick = dayjs(tick).add(1, tickInterval).startOf(tickInterval).valueOf()
      ) {
        ticks.push(tick);
      }
      // also add the max 
      ticks.push(timeDomain[1]);

      return ticks;
    },
    [timeDomain]
  );

  const dailyChartData = useMemo(
    () => {
      const zeroes = [];
      const zeroesObject = Object.fromEntries(lines.map(({ dataKey }) => [dataKey, 0]));
      for (
        let zero = timeDomain[0];
        zero <= timeDomain[1];
        zero = dayjs(zero).add(1, 'day').valueOf()
      ) {
        zeroes.push({ [dateDataField]: zero, ...zeroesObject } );
      }

      return _.sortedUniqBy(
        _.sortBy(data?.concat(zeroes), dateDataField),
        dateDataField
      );
    },
    [timeDomain, data, dateDataField, lines]
  );

  return (
    <ResponsiveContainer height={height}>
      <LineChart data={dailyChartData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={dateDataField} type="number" domain={timeDomain}
          tickFormatter={(value) => new Date(value).toLocaleDateString('el') }
          ticks={timeTicks}
        />
        <YAxis tickFormatter={formatter} allowDecimals={allowDecimals} width={yAxisWidth} />
        <Tooltip formatter={formatter} labelFormatter={(value) => new Date(value).toLocaleDateString('el')} />

        { data && _.size(lines) > 1 && <Legend layout="vertical" /> }

        {
          lines.map(({dataKey, ...props}) =>
            <Line key={dataKey}
              dataKey={dataKey} strokeWidth={2} strokeLinejoin="round" type="monotone" dot={false}
              {...props}
            />
          )
        }

      </LineChart>
    </ResponsiveContainer>
  );
}
