import { Card, Header, Icon } from 'semantic-ui-react';

export default function StatCard({ title, value, color, icon, ...props }) {
  return (
    <Card
      fluid
      style={{
        textAlign: 'center',
      }}
      {...props}
    >
      <Card.Content>
        <Icon name={icon} size="huge" />
        <Header size="small">{title}</Header>
      </Card.Content>
      <Card.Content>
        <Header size="huge" color={color}>
          {value}
        </Header>
      </Card.Content>
    </Card>
  );
}
