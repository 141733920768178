import { Helmet } from 'react-helmet';
import { Container, Divider, Header } from 'semantic-ui-react';
import AdminReservations from './AdminReservations';

export default function ParticipantReservations({ baseUrl }) {
  const pageTitle = 'Κρατήσεις';
  return (
    <>
      <Helmet title={pageTitle} />
      <Container>
        <Header
          as="h1"
          textAlign="center"
          className="colored atmgreen"
          content={pageTitle}
        />
        <Divider className="orange" />
      </Container>
      <Container>
        <Divider hidden />
        <AdminReservations baseUrl={baseUrl} />

        <Divider section hidden />
      </Container>
    </>
  );
}
