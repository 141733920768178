import { useMemo, useState } from 'react';

import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { Checkbox, Dropdown } from 'semantic-ui-react';

import { useCategories } from '../../queries/categories';
import { useDeliveryStatistics } from '../../queries/dashboard/delivered';

const COLORS = ['orange', 'green', 'brown'];

const EXTRACTION_TYPE_ENUM = {
  reused: 'Επαν/ποίηση',
  recycled: 'Ανακύκλωση',
  buried: 'Ταφή',
};

//todo add date params
export default function DeliveryStats({ params }) {
  const [selectedCategory, setSelectedCategory] = useState('all');
  const categories = useCategories({ params: { page_size: 400 } });

  const materialsDelivered = useDeliveryStatistics({
    category: selectedCategory !== 'all' ? selectedCategory : undefined,
    ...params,
  });

  const handleCategoryChange = (e, { value }) => {
    setSelectedCategory(value);
  };

  const barData = useMemo(() => {
    return materialsDelivered.data
      ? Object.entries(materialsDelivered.data).map(
          ([extraction_type, data]) => ({
            ...data,
            extraction_name: EXTRACTION_TYPE_ENUM[extraction_type],
          })
        )
      : Object.entries(EXTRACTION_TYPE_ENUM).map(
          ([extraction_type, extraction_name]) => ({
            extraction_name,
            extraction_type,
            amount: 0,
            kilos: 0,
          })
        );
  }, [materialsDelivered.data]);

  const [showKilos, setShowKilos] = useState(false);

  return (
    <>
      <Dropdown
        style={{ maxWidth: '200px', display: 'flex', margin: '0 auto' }}
        className="ellipsis"
        button
        scrolling
        value={selectedCategory}
        loading={categories.isLoading}
        onChange={handleCategoryChange}
        options={[
          { key: Date.now(), value: 'all', text: 'Συνολικά' },
          ...(categories.data?.results.map(({ id, name }) => ({
            key: id,
            value: id,
            text: name,
          })) || []),
        ]}
      />
      <ResponsiveContainer width="100%" height={400}>
        <BarChart width={400} height={400} data={barData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="extraction_name" />
          <YAxis />
          <Tooltip
            formatter={(value) => (showKilos ? value?.toFixed(2) : value)}
          />
          <Bar
            dataKey={showKilos ? 'kilos' : 'amount'}
            name="Ποσότητα"
            unit={showKilos ? ' κιλά' : ' τεμάχια'}
            barSize={30}
          >
            {barData?.map((data, index) => (
              <Cell key={index} fill={COLORS[index % COLORS.length]} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
      <Checkbox
        label="Προβολή κιλών"
        checked={showKilos}
        onChange={(e, { checked }) => setShowKilos(checked)}
      />
    </>
  );
}
