import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { Divider, Loader } from 'semantic-ui-react';

import useProfile from '../queries/useProfile';

import RepairmanProfile from './RepairmanProfile';
import RepairmanOffers from './RepairmanOffers';

import qs from 'qs';

function RepairmanHome() {
  const location = useLocation();
  const profile = useProfile();

  // if we are admin, let's send them to the full admin pages
  if (profile.isFetched && profile.data.is_supervisor) {
    return <Redirect to="/admin" />;
  }

  const { cancelTo, ...params } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  // if we are some other user and want to go to repairman, let's send them to login page
  if (profile.isFetched && !profile.data?.repairman) {
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: {
            returnTo: location.pathname + `?${qs.stringify(params)}`,
            cancelTo,
            role: 'repair',
          },
        }}
      />
    );
  }

  // we're in business then
  const repairman = profile.data?.repairman;

  const basePageTitle =
    repairman?.full_name &&
    `${repairman?.full_name} | Πλατφόρμα επαναχρησιμοποίησης – ΔΙΑΔΥΜΑ ΑΕ`;
  return (
    <>
      {basePageTitle && (
        <Helmet
          defaultTitle={basePageTitle}
          titleTemplate={`%s | ${basePageTitle}`}
        />
      )}

      {profile.isFetched ? (
        <Switch>
          <Route path="/repair" exact>
            <Redirect to="repair/offers" />
          </Route>
          <Route path="/repair/offers">
            <RepairmanOffers />
          </Route>
          <Route path="/repair/profile">
            <RepairmanProfile />
          </Route>
          <Route path="*">
            <Redirect to="/repair" />
          </Route>
        </Switch>
      ) : (
        <Loader inline="centered" size="massive" active />
      )}

      <Divider hidden />
    </>
  );
}

export default RepairmanHome;
