import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import axios from 'axios';
import qs from 'qs';
import contentDisposition from 'content-disposition';

export const useMaterials = ({ params = {}, options = {} } = {}) =>
  useQuery(
    ['materials', params],
    () =>
      axios
        .get('/api/materials/', {
          params,
          paramsSerializer: (params) =>
            qs.stringify(params, { arrayFormat: 'repeat' }),
        })
        .then(({ data }) => data),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: Infinity,
      ...options,
    }
  );

export const useMaterialByBarcode = ({ params = {}, options = {} }) =>
  useQuery(
    ['materials', params],
    () =>
      axios
        .get('/api/materials/', {
          params,
        })
        .then(({ data }) => data),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      ...options,
    }
  );

export const useMaterial = (
  id,
  { enabled = true, refetchOnWindowFocus = false, options: { ...options } } = {}
) =>
  useQuery(
    ['materials', { id }],
    () => axios.get(`/api/materials/${id}/`).then(({ data }) => data),
    {
      enabled: id ? enabled : false,
      refetchOnWindowFocus,
      ...options,
    }
  );

export const useDeleteMaterialImage = (id, onSuccess) =>
  useMutation(
    () => axios.delete(`/api/material_images/${id}/`).then(({ data }) => data),
    { onSuccess }
  );

export const useUploadMaterialImage = (id, onSuccess) => {
  const [progress, setProgress] = useState(0);

  const resetProgress = () => {
    setProgress(0);
  };
  const uploadMutation = useMutation(
    (data) =>
      axios
        .post(`/api/materials/${id}/images/`, data, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: (progressEvent) => {
            const progress = (progressEvent.loaded / progressEvent.total) * 100;
            setProgress(progress);
          },
          onDownloadProgress: (progressEvent) => {
            const progress = (progressEvent.loaded / progressEvent.total) * 100;
            setProgress(progress);
          },
        })
        .then(({ data }) => data),
    {
      onSuccess,
      onMutate: resetProgress,
    }
  );
  return { progress, uploadMutation };
};

export const useCreateMaterial = (onSuccess) =>
  useMutation(
    (data) => axios.post('/api/materials/', data).then(({ data }) => data),
    { onSuccess }
  );

export const useUpdateMaterial = (id, onSuccess) =>
  useMutation(
    (data) =>
      axios.patch(`/api/materials/${id}/`, data).then(({ data }) => data),
    { onSuccess }
  );

export const useDeleteMaterial = (id, onSuccess) =>
  useMutation(
    (data) =>
      axios.delete(`/api/materials/${id}/`, data).then(({ data }) => data),
    { onSuccess }
  );

export const useMaterialImport = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (data) =>
      axios.post('/api/materials/import/', data).then(({ data }) => data),
    {
      onSuccess: (_, variables) => {
        variables.user_text && queryClient.invalidateQueries('anonymous');
        queryClient.invalidateQueries('history');
        queryClient.invalidateQueries('materials');
      },
    }
  );
};

export const useMaterialExport = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (data) =>
      axios.post('/api/materials/export/', data).then(({ data }) => data),
    {
      onSuccess: (_, variables) => {
        variables.user_text && queryClient.invalidateQueries('anonymous');
        queryClient.invalidateQueries('history');
        queryClient.invalidateQueries('materials');
      },
    }
  );
};

export const useMaterialsStockExport = (params, options) => {
  return useQuery(
    ['materialsStockExport', { params }],
    () =>
      axios
        .get('/api/materials/export_stock/', { params, responseType: 'blob' })
        .then(({ data, headers }) => {
          let filename;
          try {
            const { parameters } = contentDisposition.parse(
              headers['content-disposition']
            );
            filename = parameters?.filename || undefined;
          } catch (err) {
            console.warn(err);
          }

          const file = new File(
            [data],
            filename ?? 'Διαθέσιμες ποσότητες υλικών.xlsx',
            { type: data.type }
          );

          return file;
        }),
    {
      enabled: false,
      staleTime: 0,
      cacheTime: 0,
      ...options,
    }
  );
};
