import { useEffect, useRef, useState } from 'react';
import { Link, NavLink, Route, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import { Helmet } from 'react-helmet';

import { Button, Checkbox, Container, Divider, Form, Grid, Header, Icon, List, Loader, Menu, Message, Popup, Segment } from 'semantic-ui-react';

import ConfirmPopup from './ConfirmPopup';

import {
  useAnnouncement,
  useAnnouncementImages,
  useAnnouncements,
  useSelectAnnouncementCover,
} from './queries/announcements';
import { useToken } from './context/token';
import ImageModal from './ImageModal';

import axios from 'axios';

export function AnnouncementForm({ baseUrl }) {
  // we do this to dynamically load the ckeditor, so that we can render the app on server as well
  const editorRef = useRef();
  const [editorLoaded, setEditorLoaded] = useState(false);
  const { CKEditor, Editor } = editorRef.current || {};

  useEffect(() => {
    editorRef.current = {
      CKEditor: require('@ckeditor/ckeditor5-react').CKEditor,
      Editor: require('ckeditor5-custom-build'),
    };
    setEditorLoaded(true);
  }, []);

  const history = useHistory();

  const queryClient = useQueryClient();
  const { postId } = useParams();
  const match = useRouteMatch();

  const {
    isFetched,
    data: post,
    refetch,
  } = useAnnouncement(postId);

  const postImages = useAnnouncementImages(postId, {
    refetchOnWindowFocus: false,
  });

  const [formChanged, setFormChanged] = useState(false);
  const [postData, setPostData] = useState({ title: '' });

  useEffect(() => {
    if (post && isFetched) {
      setPostData(post);
      setFormChanged(false);
    }
  }, [isFetched, post]);

  const saveMutation = useMutation(
    (data) => axios.patch(`/api/announcements/${postId}/`, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('announcements');
        queryClient.invalidateQueries(['announcementImages', postId])
        refetch();
        // reset to hide the check mark effect
        setTimeout(saveMutation.reset, 1500);
      },
    }
  );

  const destroyMutation = useMutation(
    () => axios.delete(`/api/announcements/${postId}/`),
    { onSuccess: () => {
        queryClient.invalidateQueries('announcements');
        history.replace(baseUrl);
      },
    }
  );

  const handleChange = (e, { name, value }) => {
    setPostData((d) => ({ ...d, [name]: value }));
    setFormChanged(true);
  };

  const handleSubmit = () => saveMutation.mutate(postData);

  const handleReset = () => {
    setPostData(post);
    setFormChanged(false);
  };

  const handlePublish = (publish) => saveMutation.mutate({ status: publish ? 'PUB' : 'DRA' });
  const handleDestroy = () => destroyMutation.mutate();

  const handleImageModalClose = () => {
    history.push(match.url);
  };

  // we need this to upload images (admins only can do that)
  const { token } = useToken();

  return (
    <Segment secondary clearing>
      <Helmet title={post?.title} />

      <Form onSubmit={handleSubmit}>
        <Form.TextArea required rows={1} label="Τίτλος" name="title" value={postData.title} onChange={handleChange} />

        <Form.Field>
          <label>Περιεχόμενο</label>
          { editorLoaded ? <CKEditor id="contentEditor"
            key={postId}
            editor={ Editor }
            config={{
              toolbar: {
                items: [
                  'heading',
                  '|',
                  'bold',
                  'italic',
                  'underline',
                  'link',
                  'bulletedList',
                  'numberedList',
                  'horizontalLine',
                  '|',
                  'outdent',
                  'indent',
                  '|',
                  'imageInsert',
                  'blockQuote',
                  'insertTable',
                  'mediaEmbed',
                  'undo',
                  'redo'
                ]
              },
              language: 'el',
              image: {
                toolbar: [
                  'imageStyle:inline',
                  'imageStyle:block',
                  'imageStyle:side',
                  '|',
                  'toggleImageCaption',
                  'imageTextAlternative',
                ]
              },
              table: {
                contentToolbar: [
                  'tableColumn',
                  'tableRow',
                  'mergeTableCells'
                ]
              },
              mediaEmbed: { previewsInData: true },
              simpleUpload: {
                uploadUrl: `/api/announcements/${postId}/images/`,
                headers: {
                  Authorization: `Token ${token}`,
                }
              },
            }}
            data={postData.content || ''}
            onChange={ (e, editor) => handleChange(e, { name: 'content', value: editor.getData() }) }
          /> : <div>Παρακαλώ περιμένετε να φορτώσει το περιβάλλον επεξεργασίας...</div> 
        }
        </Form.Field>
        
        {/* <Form.TextArea rows={8} label="Περιεχόμενο" name="content" value={postData.content} onChange={handleChange} /> */}

        <Menu text>
          <Menu.Item>
            <Checkbox toggle label="Δημοσίευση"
              checked={post?.status === 'PUB'}
              onChange={(e, {checked}) => handlePublish(checked)}
              disabled={!post}
            />
          </Menu.Item>
        </Menu>

        <Menu text stackable>
          { post &&
            <Menu.Item>
              <ConfirmPopup
                position="right center"
                header="Θέλετε να διαγραφεί η ανάρτηση αυτή;"
                trigger={
                  <Button type="button" negative
                    content="Διαγραφή"
                    disabled={post?.status !== 'DRA' || destroyMutation.isLoading}
                    loading={destroyMutation.isLoading}
                  />
                }
                onConfirm={handleDestroy}
                error={destroyMutation.isError && 'Δεν ήταν δυνατή η διαγραφή της ανάρτησης.' }
                onDismissError={() => destroyMutation.reset()}
              />
            </Menu.Item>
          }

          <Menu.Menu position="right">
          {postImages.data?.length > 0 && (
              <Menu.Item>
                <Button
                  as={Link}
                  to={`${match.url}/cover`}
                  content="Επιλογή κύριας εικόνας"
                  color="teal"
                />
              </Menu.Item>
            )}
            { saveMutation.isError && 
              <Popup trigger={<Menu.Item><Icon circular bordered inverted name="cancel" color="red" /></Menu.Item>}
                open on="click" onClose={() => saveMutation.reset()}
                content="Δεν ήταν δυνατή η αποθήκευση της ανάρτησης."
              />
            }
            { saveMutation.isSuccess && <Menu.Item><Icon circular bordered inverted name="check" color="green" /></Menu.Item> }
            <Menu.Item>
              <Button primary content="Αποθήκευση"
                loading={saveMutation.isLoading}
                disabled={saveMutation.isLoading} />
            </Menu.Item>
            <Menu.Item>
              { post ?
                  <Button type="reset" content="Επαναφορά" onClick={handleReset} disabled={!formChanged} />
                : <Button type="button" as={Link} to={baseUrl}>Ακύρωση</Button>
              }
            </Menu.Item>
          </Menu.Menu>
        </Menu>

      </Form>
      <Route exact path={`${match.path}/cover`}>
        <ImageModal
          mutationId={postId}
          mutationType={['announcementImages', postId]}
          images={postImages.data}
          status={{
            isLoading: postImages.isLoading,
            isFetching: postImages.isFetching,
            isFetched : postImages.isFetched,
          }}
          onClose={handleImageModalClose}
          useSelectMutation={useSelectAnnouncementCover}
          hideUpload
          preview
        />
      </Route>
    </Segment>
  );
}

function AnnouncementsAdmin() {
  const match = useRouteMatch();
  const history = useHistory();

  const queryClient = useQueryClient();
  const announcements = useAnnouncements();

  const createMutation = useMutation(
    () => axios.post(`/api/announcements/`, { title: 'Νέα ανάρτηση '}),
    {
      onSuccess: ({ data: { id } }) => {
        queryClient.invalidateQueries('announcements');
        history.replace(`${match.url}/${id}`);
      },
    }
  );

  const handleCreatePost = () => {
    createMutation.mutate();
  }

  return (
    <Container>
      <Header as="h1" textAlign="center" className="colored atmgreen">Διαχείριση αναρτήσεων</Header>
      <Divider className="orange" />
      <Divider hidden />

      <Grid centered>
        <Grid.Row>
          <Grid.Column width={4}>
            { announcements.isLoading && <Loader active /> }
            { announcements.isError && <Message error content="Κάτι πήγε στραβά!" /> }

            <List selection style={{ maxHeight: '50vh', overflow: 'auto' }}>
              { announcements.data?.map(({ id, title, published_at, created_at, status }) => 
                <List.Item key={id} as={NavLink} to={`${match.url}/${id}`}>
                  <List.Content >
                    <List.Header>
                      <Header>{ new Date(published_at || created_at).toLocaleString('el') }</Header>
                      <Header sub dividing>{ status === 'PUB' ? 'Δημοσιευμένο' : 'Πρόχειρο' }</Header>
                    </List.Header>
                    <List.Description >
                      <p>{ title }</p>
                    </List.Description>
                  </List.Content>
                </List.Item>
              ) }
            </List>

            <Button fluid positive icon="plus" labelPosition="left" content="Νέα ανάρτηση" onClick={handleCreatePost} />

          </Grid.Column>
          <Grid.Column width={12}>

            <Route path={`${match.path}/:postId(\\d+)`}>
              <AnnouncementForm baseUrl={match.url} />
            </Route>

            { createMutation.isLoading && <Segment placeholder secondary loading /> }

          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Divider section hidden />
    </Container>
  )
}

export default AnnouncementsAdmin;
