import { useState } from 'react';

import { Form, Icon } from 'semantic-ui-react';

export default function SearchForm({ value, onSearch, mobile, loading, name }) {
  const [input, setInput] = useState(value || '');

  const handleSearch = (value) => name ? onSearch(name, value) : onSearch(value)

  const handleClear = () => {
    setInput('');
    handleSearch('');
  };

  return (
    <Form onSubmit={() => handleSearch(input)} className="no-margin-form">
      <Form.Group style={{ justifyContent: 'end' }}>
        <Form.Input
          icon={
            input ? <Icon name="delete" link onClick={handleClear} /> : 'search'
          }
          value={input}
          name={name}
          onChange={(e, { value }) => setInput(value)}
        />
        <Form.Button
          fluid={mobile}
          primary
          content="Αναζήτηση"
          loading={input ? loading : false}
        />
      </Form.Group>
    </Form>
  );
}
