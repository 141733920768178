import { Grid } from "semantic-ui-react";

import StatCard from "./StatCard";

import { useRepairStatistics } from "../../queries/dashboard/transactions";

export default function RepairmenStatistics({ params }) {
  const { data: repairStats } = useRepairStatistics(params);

  return (
    <Grid stackable columns="equal">
      <Grid.Column>
        <StatCard
          title="Eπισκευαστές"
          icon="wrench"
          value={repairStats?.repairmen ?? 0}
        />
      </Grid.Column>
      <Grid.Column>
        <StatCard
          title="Προσφορές επισκευής"
          icon="tag"
          value={repairStats?.repair_offers ?? 0}
        />
      </Grid.Column>
    </Grid>
  );
}
