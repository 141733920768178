import { NavLink, useLocation } from 'react-router-dom';
import { Menu } from 'semantic-ui-react';

import { ResponsiveContainer } from '@zerowaste/components';

import useProfile from '../queries/useProfile';

import qs from 'qs';

export default function ReuseContainer({
  children,
  onLogin,
  onRegister,
  onLogout,
  showAuth = true,
}) {
  const profile = useProfile();
  const user = profile.data;

  const participant = user?.participant;
  const isSupervisor = user?.is_supervisor;
  const isStaff = user?.is_employee || user?.is_supervisor;
  const isEmployeeOnly = user?.is_employee && !user?.is_supervisor;
  const isRepairman = !!user?.repairman;

  const location = useLocation();
  const { code } = qs.parse(location.search, { ignoreQueryPrefix: true });

  const userMenuItems = participant && (
    <>
      <Menu.Item as={NavLink} to="/profile">
        Λογαριασμός
      </Menu.Item>
      <Menu.Item as={NavLink} to="/reservations">
        Κρατήσεις
      </Menu.Item>
      <Menu.Item as={NavLink} to="/history">
        Ιστορικό
      </Menu.Item>
    </>
  );

  const repairmanMenuItems = isRepairman && (
    <>
      <Menu.Item as={NavLink} to="/repair/offers">
        Προσφορές
      </Menu.Item>
      <Menu.Item as={NavLink} to="/repair/profile">
        Προφιλ
      </Menu.Item>
    </>
  );

  return (
    <ResponsiveContainer
      headerTitle="Πλατφόρμα επαναχρησιμοποίησης υλικών"
      className="header-menu"
      userPaths={['/profile', '/history', '/repair']}
      contentPaths={['/', '/recycle', '/questions', '/news']}
      user={user}
      showLoginRegister={profile.isFetched && !code}
      onLogin={onLogin}
      onLogout={onLogout}
      onRegister={onRegister}
      showAuth={showAuth}
      notLoggedIn={profile.isFetched && !profile.data}
      displaySettings={!!user?.participant}
      menuItems={
        <>
          <Menu.Item style={{ flexShrink: 1 }} as={NavLink} to="/" exact>
            Αρχικη
          </Menu.Item>
          <Menu.Item style={{ flexShrink: 1 }} as={NavLink} to="/reuse-centers">
            Κέντρα Επαναχρησιμοποίησης
          </Menu.Item>
          <Menu.Item style={{ flexShrink: 1 }} as={NavLink} to="/pick-up">
            Θέλω να παραλάβω
          </Menu.Item>
          <Menu.Item style={{ flexShrink: 1 }} as={NavLink} to="/turn-in">
            Θέλω να παραδώσω
          </Menu.Item>
          <Menu.Item style={{ flexShrink: 1 }} as={NavLink} to="/repairmen">
            Επισκευή
          </Menu.Item>
          <Menu.Item style={{ flexShrink: 1 }} as={NavLink} to="/questions">
            Συχνες ερωτησεις
          </Menu.Item>
          <Menu.Item style={{ flexShrink: 1 }} as={NavLink} to="/news">
            Νεα
          </Menu.Item>
        </>
      }
      userMenu={showAuth && (userMenuItems || repairmanMenuItems)}
      adminMenu={
        isStaff &&
        showAuth && (
          <>
            {isSupervisor && (
              <Menu.Item as={NavLink} to="/admin/dashboard">
                Επισκοπηση
              </Menu.Item>
            )}
            {isEmployeeOnly && (
              <Menu.Item as={NavLink} to="/admin/pickup">
                Παραλαβή
              </Menu.Item>
            )}
            {isEmployeeOnly && (
              <Menu.Item as={NavLink} to="/admin/turnin">
                Παράδοση
              </Menu.Item>
            )}
            <Menu.Item as={NavLink} to="/admin/history">
              Ιστορικο
            </Menu.Item>
            <Menu.Item as={NavLink} to="/admin/material-manage">
              Υλικά
            </Menu.Item>
            {isSupervisor && (
              <Menu.Item as={NavLink} to="/admin/kdey">
                ΚΔΕΥ
              </Menu.Item>
            )}
            {isSupervisor && (
              <Menu.Item as={NavLink} to="/admin/repairmen">
                Επισκευαστές
              </Menu.Item>
            )}
            {isSupervisor && (
              <Menu.Item as={NavLink} to="/admin/news">
                Ανακοινώσεις
              </Menu.Item>
            )}
          </>
        )
      }
    >
      {children}
    </ResponsiveContainer>
  );
}
