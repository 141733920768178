import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';


export const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export const ScrollToHere = ({ only, divider, homepath='/', paths=[] }) => {
  const location = useLocation();
  const isInitialMount = useRef(true);
  const ref = useRef();

  useEffect(() => {
    const doScroll = paths.length === 0 || paths.some((p) => location.pathname.startsWith(p));

    // do not scroll on initial mount when showing home
    if (doScroll && !(isInitialMount.current && location.pathname === homepath) && ref.current && ref.current.offsetParent) {
      const offsetY = ref.current.offsetTop + ref.current.offsetParent.offsetTop;
      // alert(`${offsetY} ${window.scrollY}`);
      if (window.scrollY > offsetY) {
        window.scrollTo(0, offsetY);
      }
    }

    if (isInitialMount.current) {
      // clear initial mount flag
      isInitialMount.current = false;
    }
  });

  return (
    <Grid style={{ margin: divider ? '.75rem 0' : 0, height: divider ? undefined : 0 }}>
      <Grid.Column only={only}>
        <div ref={ref}></div> 
      </Grid.Column>
    </Grid>
  );
};
