import { ResponsiveContainer } from 'recharts';
import { Loader } from 'semantic-ui-react';

import { useCategories } from '../../queries/categories';
import { useMaterialsCollected } from '../../queries/dashboard/transactions';
import ReusePieChart from './ReusePieChart';

export default function ImportStats({ params }) {
  const categories = useCategories({ params: { page_size: 400 } });

  const materialsCollected = useMaterialsCollected(params);

  const names = categories.data?.results;

  const pieData = materialsCollected.data?.map((cat) => ({
    ...cat,
    name: names?.find((name) => name.id === cat.id)?.name,
  }));

  return (
    <ResponsiveContainer width="100%" height={400}>
      {materialsCollected.isFetching ? (
        <Loader active size="massive" />
      ) : (
        <ReusePieChart data={pieData} />
      )}
    </ResponsiveContainer>
  );
}
