import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import {
  Header,
  Container,
  Segment,
  Divider,
  Accordion,
  List,
  Table,
  Icon,
} from 'semantic-ui-react';

import { useKdeys } from '../queries/kdeys';
import { useCategories } from '../queries/categories';

export default function FaqPage({ registerUrl }) {
  const [activeIndexes, setActiveIndexes] = useState([]);

  const location = useLocation();

  const kdeys = useKdeys();
  const categories = useCategories({ params: { page_size: 400 } });

  const panels = useMemo(
    () => [
      {
        key: 'whatis',
        title: {
          id: 'whatis',
          content: 'Τι είναι η zerowaste πλατφόρμα της ΔΙΑΔΥΜΑ;',
        },
        content: {
          content: (
            <Segment basic padded size="large">
              <p>
                Η ΔΙΑΔΥΜΑ ακολουθώντας τα ευρωπαϊκά πρότυπα εντάσσει στη
                φιλοσοφία της την ανταποδοτική διαχείριση αποβλήτων. Για το λόγο
                αυτό οι πολίτες της Δυτικής Μακεδονίας που συμμετέχουν στην
                επαναχρησιμοποίηση μέσω των Κέντρων Δημιουργικής
                Επαναχρησιμοποίησης Υλικών θα επιβραβεύονται. Θα έχουν τη
                δυνατότητα συλλογής πόντων τους οποίους και θα εξαργυρώνουν σε
                συμβεβλημένα καταστήματα. Για περισσότερες πληροφορίες
                επισκεφθείτε τη σελίδα για την{' '}
                <a href="https://rewards.diadyma.gr/">εξαργύρωση πόντων</a>.
              </p>
              <p>
                Η zerowaste πλατφόρμα είναι ένας κοινός τόπος εγγραφής σε όλες
                τις εναλλακτικές, καλές πρακτικές διαχείρισης αποβλήτων που
                εφαρμόζει η ΔΙΑΔΥΜΑ, όπως η επαναχρησιμοποίηση και η{' '}
                <a href="https://cookingoils.diadyma.gr/">
                  ανακύκλωση τηγανέλαιων
                </a>
                . Μπορείτε να εγγραφείτε και να απολαύσετε μοναδικά προνόμια
                επιβράβευσης πατώντας <a href={registerUrl}>εδώ</a>.
              </p>
            </Segment>
          ),
        },
      },
      {
        key: 'win',
        title: {
          id: 'win',
          content:
            'Τι κερδίζω από την εγγραφή μου στην πλατφόρμα επαναχρησιμοποίησης;',
        },
        content: {
          content: (
            <Segment basic padded size="large">
              <p>
                Με την εγγραφή στην πλατφόρμα, απλοποιείται η διαδικασία σε
                περίπτωση μελλοντικής χρήσης, υπάρχει πρόσβαση στο ιστορικό
                σχετικά με τη χρήση του συστήματος, υπάρχει δυνατότητα
                επεξεργασίας και τροποποίησης των στοιχείων, καθώς και
                δυνατότητα συλλογής πόντων οι οποίοι θα κερδίζονται για
                συναλλαγές που γίνονται στο σύστημα. Κάθε επόμενη φορά για να
                συνδεθείτε θα επιλέγετε «Σύνδεση» και θα συμπληρώνετε το email
                τον κωδικό που είχατε δηλώσει κατά τη δημιουργία του
                λογαριασμού. Αν ξεχάσετε τον κωδικό υπάρχει δυνατότητα ανάκτησης
                μέσω του email.
              </p>
            </Segment>
          ),
        },
      },
      {
        key: 'rewards',
        title: {
          id: 'rewards',
          content:
            'Πως λειτουργεί το σύστημα των πόντων (για τους εγγεγραμμένους πολίτες);',
        },
        content: {
          content: (
            <Segment basic padded size="large">
              <p>
                Επιβραβεύουμε την επαναχρησιμοποίηση, ως καλή πρακτική
                διαχείρισης των αποβλήτων!
              </p>
              <p>
                Κάθε πολίτης που εγγράφεται στην πλατφόρμα ZeroWaste της
                ΔΙΑΔΥΜΑ, έχει τη δυνατότητα να συλλέγει πόντους επιβράβευσης για
                κάθε υλικό που επαναχρησιμοποιεί (είτε παραδίδει, είτε
                παραλαμβάνει). Οι κερδισμένοι πόντοι, μπορούν να εξαργυρωθούν σε
                μοναδικές προσφορές και δώρα (για περισσότερες πληροφορίες
                σχετικά με την επιβράβευση 🡪
                <a href="https://rewards.diadyma.gr/">
                  https://rewards.diadyma.gr/
                </a>
                .
              </p>
              <p>
                Ο αριθμός των πόντων εξαρτάται από το είδος της συναλλαγής, τα
                τεμάχια και τον τύπο του υλικού. Στον παρακάτω πίνακα φαίνεται η
                αξία της κάθε κατηγορίας υλικών σε πόντους.
              </p>
              <Table>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Κατηγορίες Υλικών</Table.HeaderCell>
                    <Table.HeaderCell>
                      Παράδοση στο ΚΔΕΥ (πόντοι ανά τεμάχιο)
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      Παραλαβή από το ΚΔΕΥ (πόντοι ανά τεμάχιο)
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>ΒΙΒΛΙΑ</Table.Cell>
                    <Table.Cell>50</Table.Cell>
                    <Table.Cell>60</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>ΟΙΚΙΑΚΑ ΣΚΕΥΗ</Table.Cell>
                    <Table.Cell>30</Table.Cell>
                    <Table.Cell>40</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>ΕΙΔΗ ΟΙΚΙΑΚΗΣ ΧΡΗΣΗΣ</Table.Cell>
                    <Table.Cell>30</Table.Cell>
                    <Table.Cell>40</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>ΕΙΔΗ ΠΡΟΣΩΠΙΚΗΣ ΠΕΡΙΠΟΙΗΣΗΣ</Table.Cell>
                    <Table.Cell>20</Table.Cell>
                    <Table.Cell>30</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>ΕΡΓΑΛΕΙΑ</Table.Cell>
                    <Table.Cell>30</Table.Cell>
                    <Table.Cell>40</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>
                      ΜΙΚΡΕΣ ΗΛΕΚΤΡΙΚΕΣ &amp; ΗΛΕΚΤΡΟΝΙΚΕΣ ΣΥΣΚΕΥΕΣ*
                    </Table.Cell>
                    <Table.Cell>300</Table.Cell>
                    <Table.Cell>500</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>
                      ΜΕΓΑΛΕΣ ΗΛΕΚΤΡΙΚΕΣ &amp; ΗΛΕΚΤΡΟΝΙΚΕΣ ΣΥΣΚΕΥΕΣ**
                    </Table.Cell>
                    <Table.Cell>1000</Table.Cell>
                    <Table.Cell>500</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>
                      ΕΙΔΗ ΕΝΔΥΣΗΣ-ΥΠΟΔΗΣΗΣ-ΑΞΕΣΟΥΑΡ-ΕΙΔΗ ΤΑΞΙΔΙΟΥ
                    </Table.Cell>
                    <Table.Cell>10</Table.Cell>
                    <Table.Cell>20</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>ΛΕΥΚΑ ΕΙΔΗ</Table.Cell>
                    <Table.Cell>20</Table.Cell>
                    <Table.Cell>40</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>ΜΟΚΕΤΕΣ-ΧΑΛΙΑ</Table.Cell>
                    <Table.Cell>300</Table.Cell>
                    <Table.Cell>400</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>ΕΠΙΠΛΑ</Table.Cell>
                    <Table.Cell>1000</Table.Cell>
                    <Table.Cell>500</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>ΕΙΔΗ ΧΟΜΠΙ- ΔΡΑΣΤΗΡΙΟΤΗΤΕΣ</Table.Cell>
                    <Table.Cell> 50</Table.Cell>
                    <Table.Cell>60</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>ΠΑΙΧΝΙΔΙΑ</Table.Cell>
                    <Table.Cell>10</Table.Cell>
                    <Table.Cell>20</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>ΠΑΙΔΙΚΑ ΕΙΔΗ</Table.Cell>
                    <Table.Cell>30</Table.Cell>
                    <Table.Cell>40</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>CD/DVD</Table.Cell>
                    <Table.Cell>10</Table.Cell>
                    <Table.Cell>20</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>ΕΙΔΗ ΚΑΤΟΙΚΙΔΙΩΝ ΖΩΩΝ</Table.Cell>
                    <Table.Cell>40</Table.Cell>
                    <Table.Cell>50</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>ΕΠΟΧΙΑΚΑ ΕΙΔΗ</Table.Cell>
                    <Table.Cell>20</Table.Cell>
                    <Table.Cell>40</Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
              <div>-</div>
              <div>
                * Μικρές Ηλεκτρικές και Ηλεκτρονικές Συσκευές θεωρούνται οι
                εξής: Εξοπλισμός υπολογιστή – Τηλεφωνία – Εικόνα &amp; Ήχος,
                Καλώδια και Οικιακός εξοπλισμός- Μικροσυσκευές
              </div>
              <div>
                **Μεγάλες Ηλεκτρικές και Ηλεκτρονικές Συσκευές θεωρούνται οι
                εξής: Ψυγεία, Πλυντήρια ρούχων, Πλυντήρια πιάτων, Καταψύκτες,
                Κουζίνες, Φούρνος μικροκυμάτων, Ηλεκτρικές σκούπες, Τηλεοράσεις,
                Υπολογιστές και Οθόνες υπολογιστή και Εκτυπωτές.
              </div>
              <div>-</div>
              <div>Προσοχή!</div>
              <div>
                <List bulleted>
                  <List.Item>
                    Οι πόντοι δίνονται μόνο για τα υλικά που είναι κατάλληλα για
                    επαναχρησιμοποίηση. Υλικά που θα οδηγούνται για ανακύκλωση ή
                    ταφή δε θα λαμβάνουν πόντους.
                  </List.Item>
                  <List.Item>
                    Αν ένα υλικό επιστραφεί πίσω στα ΚΔΕΥ τότε γι’ αυτό το
                    υλικό, ο εγγεγραμμένος χρήστης δε θα λαμβάνει πόντους.
                  </List.Item>
                </List>
              </div>
            </Segment>
          ),
        },
      },
      {
        key: 'where',
        title: {
          id: 'where',
          content:
            'Που βρίσκονται τα Κέντρα Δημιουργικής Επαναχρησιμοποίησης Υλικών (ΚΔΕΥ);',
        },
        content: {
          content: (
            <Segment basic padded size="large">
              {kdeys.data?.map(({ name: centerName, address }) => (
                <p>
                  <div>{centerName}:</div>
                  <div>Διεύθυνση: {address}</div>
                </p>
              ))}
            </Segment>
          ),
        },
      },
      {
        key: 'contact',
        title: {
          id: 'contact',
          content: 'Πως μπορώ να επικοινωνήσω με τα ΚΔΕΥ;',
        },
        content: {
          content: (
            <Segment basic padded size="large">
              <List
                items={[
                  {
                    key: 'email',
                    icon: <Icon name="mail outline" />,
                    content: (
                      <a href="mailto:zerowaste@diadyma.gr">
                        zerowaste@diadyma.gr
                      </a>
                    ),
                  },
                ]}
              />
              <List
                items={[
                  {
                    key: 'website',
                    icon: <Icon name="globe" />,
                    content: (
                      <a href="https://diadyma.gr/">https://diadyma.gr/</a>
                    ),
                  },
                ]}
              />
              {kdeys.data?.map(({ name: centerName, telephone }) => (
                <>
                  <Header as="h3" size="small">
                    {centerName}
                  </Header>
                  <List
                    items={[
                      {
                        key: 'tel',
                        icon: <Icon name="call" />,
                        content: <a href={`tel:${telephone}`}>{telephone}</a>,
                      },
                    ]}
                  />
                </>
              ))}
            </Segment>
          ),
        },
      },
      {
        key: 'schedule',
        title: {
          id: 'schedule',
          content: 'Ποιο είναι το ωράριο των ΚΔΕΥ;',
        },
        content: {
          content: (
            <Segment basic padded size="large">
              <Header as="h3">Ωράριο ΚΔΕΥ</Header>
              <List>
                <List.Item>ΔΕΥΤΕΡΑ 08:00 – 15:00</List.Item>
                <List.Item>ΤΡΙΤΗ 08:00 – 15:00</List.Item>
                <List.Item>ΤΕΤΑΡΤΗ 08:00 – 15:00</List.Item>
                <List.Item>ΠΕΜΠΤΗ 08:00 – 15:00</List.Item>
                <List.Item>ΠΑΡΑΣΚΕΥΗ 08:00 – 15:00</List.Item>
                <List.Item>ΣΑΒΒΑΤΟ 09:00 – 13:00</List.Item>
              </List>
            </Segment>
          ),
        },
      },
      {
        key: 'operate',
        title: {
          id: 'operate',
          content: 'Ποιος λειτουργεί τα ΚΔΕΥ;',
        },
        content: {
          content: (
            <Segment basic padded size="large">
              <p>
                Η λειτουργία τους γίνεται από τη ΔΙΑΔΥΜΑ Α.Ε. με τη συνεργασία
                των ομώνυμων Δήμων, ενώ υπάρχει η δυνατότητα υποστήριξης και από
                φορείς της κοινωνικής οικονομίας και εθελοντικές ομάδες.
              </p>
            </Segment>
          ),
        },
      },
      {
        key: 'free',
        title: {
          id: 'free',
          content: 'Τα υλικά διατίθενται δωρεάν;',
        },
        content: {
          content: (
            <Segment basic padded size="large">
              <p>
                Ναι. Τα Κέντρα Δημιουργικής Επαναχρησιμοποίησης Υλικών της
                Περιφέρειας Δυτικής Μακεδονίας είναι τοπικά μη κερδοσκοπικού
                χαρακτήρα κέντρα συλλογής και διάθεσης υλικών.
              </p>
            </Segment>
          ),
        },
      },
      {
        key: 'participate',
        title: {
          id: 'participate',
          content: 'Πως μπορώ να συμμετέχω στη συλλογή υλικών;',
        },
        content: {
          content: (
            <Segment basic padded size="large">
              <p>
                Ο κάθε πολίτης μπορεί είτε να παραδίδει αντικείμενα στους χώρους
                των ΚΔΕΥ, που είναι σε καλή κατάσταση και δεν τα χρειάζεται,
                είτε να αποκτά υλικά χωρίς κόστος.
              </p>
            </Segment>
          ),
        },
      },
      {
        key: 'pickup-turnin',
        title: {
          id: 'pickup-turnin',
          content:
            'Η παραλαβή και η παράδοση των υλικών γίνεται αποκλειστικά στον τόπο του κάθε ΚΔΕΥ;',
        },
        content: {
          content: (
            <Segment basic padded size="large">
              <p>
                Προς το παρόν ναι. Για την παράδοση και μεταφορά ογκωδών υλικών
                στο ΚΔΕΥ (πχ. καναπές, ψυγείο κλπ), μπορείτε να απευθύνεστε
                επίσης και στην υπηρεσία καθαριότητας του Δήμου σας.
              </p>
            </Segment>
          ),
        },
      },
      {
        key: 'when',
        title: {
          id: 'when',
          content: 'Πότε μπορώ να φέρω κάποιο υλικό από το σπίτι μου;',
        },
        content: {
          content: (
            <Segment basic padded size="large">
              <p>
                Οποιαδήποτε στιγμή μέσα στο ωράριο λειτουργίας των ΚΔΕΥ. Καλό θα
                ήταν πριν την επίσκεψη, να καλείτε στο αντίστοιχο ΚΔΕΥ και να
                κλείνετε ραντεβού, για την καλύτερη εξυπηρέτησή σας.
              </p>
            </Segment>
          ),
        },
      },
      {
        key: 'matarials',
        title: { id: 'materials', content: 'Ποια υλικά δέχονται τα ΚΔΕΥ;' },
        content: {
          content: (
            <Segment basic padded size="large">
              <p>
                Τα υλικά που συγκεντρώνονται προς επαναχρησιμοποίηση
                περιλαμβάνουν:
              </p>
              <List bulleted>
                {categories.data?.results.map(({ name: categoryName }) => (
                  <List.Item>{categoryName}</List.Item>
                ))}
                {/* <List.Item>
                  Βιβλία – Χαρτικά – Είδη γραφείου – Γραφική ύλη
                </List.Item>
                <List.Item>
                  Οικιακά σκεύη (πιάτα, ποτήρια, μαγειρικά σκεύη, κλπ)
                </List.Item>
                <List.Item>Είδη οικιακής χρήσης – Διακοσμητικά</List.Item>
                <List.Item>
                  Είδη προσωπικής περιποίησης (σεσουάρ, ξυριστικές μηχανές, κλπ)
                </List.Item>
                <List.Item>Εργαλεία (παντός τύπων)</List.Item>
                <List.Item>
                  Ηλεκτρικές &amp; Ηλεκτρονικές Συσές (ψυγεία, πλυντήρια, Η/Υ,
                  μικροσυσκευές, κλπ)
                </List.Item>
                <List.Item>
                  Είδη ένδυσης – Υπόδησης – Αξεσουάρ – Είδη ταξιδιού
                </List.Item>
                <List.Item>Λευκά ειδή (πετσέτες, σεντόνια, κλπ)</List.Item>
                <List.Item>Μοκέτες – χαλιά</List.Item>
                <List.Item>
                  Είδη επίπλωσης (καρέκλες, τραπέζια, καναπέδες, σύνθετα, κλπ)
                </List.Item>
                <List.Item>
                  Είδη χόμπι-Δραστηριότητες (Όργανα γυμναστικής, μουσικά όργανα,
                  εξοπλισμός αυτοκινήτου-μηχανής-ποδηλάτου, είδη αθλητισμού
                  κ.ά.)
                </List.Item>
                <List.Item>Παιχνίδια (διάφορα είδη)</List.Item>
                <List.Item>
                  Παιδικά είδη (ρούχα, είδη βρεφοανάπτυξης, παιδικά καθίσματα,
                  κλπ)
                </List.Item>
                <List.Item>
                  CD/DVD (ταινιών, μουσικής, παιχνιδιών, κλπ)
                </List.Item>
                <List.Item>
                  Είδη για κατοικίδια ζώα (σπίτια, κλουβιά, είδη περιποίησης,
                  κλπ)
                </List.Item>
                <List.Item>
                  Εποχιακά είδη (χριστουγεννιάτικα, πασχαλιάτικα, καλοκαιρινά,
                  αποκριάτικα κλπ)
                </List.Item> */}
              </List>
            </Segment>
          ),
        },
      },
      {
        key: 'electronic-device',
        title: {
          id: 'electronic-device',
          content:
            'Υπάρχει τρόπος να ελέγξω αν λειτουργεί κάποια ηλεκτρονική συσκευή;',
        },
        content: {
          content: (
            <Segment basic padded size="large">
              <p>Ναι, υπάρχει κατάλληλος χώρος και εξοπλισμός μέσα στο ΚΔΕΥ.</p>
            </Segment>
          ),
        },
      },
    ],
    [registerUrl, kdeys.data, categories.data]
  );

  useEffect(() => {
    let id = location.hash.substring(1);
    let activeIndex = panels.findIndex((panel) => panel.key === id);
    setActiveIndexes(activeIndex !== -1 ? [activeIndex] : []);
    let activeAccordion = document.getElementById(id);
    activeAccordion?.scrollIntoView();
  }, [location, panels]);

  const handleTitleClick = (e, { index }) =>
    setActiveIndexes((activeIndexes) => {
      if (activeIndexes.includes(index)) {
        if (activeIndexes.length === 1) {
          window.history.pushState({}, '', `${location.pathname}`);
        }
        return activeIndexes.filter((i) => i !== index);
      } else {
        window.history.pushState(
          {},
          '',
          `${location.pathname}#${panels[index].key}`
        );
        return [...activeIndexes, index];
      }
    });

  const pageTitle = 'Συχνές ερωτήσεις';
  return (
    <>
      <Helmet title={pageTitle} />
      <Container>
        <Header
          as="h1"
          textAlign="center"
          className="colored atmgreen"
          content={pageTitle}
        />
        <Divider className="orange" />
      </Container>

      <Segment basic attached className="colored lightgreen">
        <Container>
          <Divider hidden />
          <Accordion
            exclusive={false}
            activeIndex={activeIndexes}
            className="green"
            panels={panels}
            onTitleClick={handleTitleClick}
          />

          <Divider section hidden />
          <Divider className="fat" />
          <Divider hidden />
        </Container>
      </Segment>
    </>
  );
}
