import { useState } from 'react';
import { Popup, Button } from 'semantic-ui-react';


export default function ConfirmPopup({
  children,
  header='Είστε σίγουροι;',
  positive='Ναι', negative='Όχι', dismiss='OK', secondary,
  trigger, onConfirm, onSecondaryConfirm,
  error=false, onDismissError,
  ...props
}) {
  const [confirmOpen, setConfirmOpen] = useState(false);

  const handleOpenConfirm = () => setConfirmOpen(true);
  const handleCloseConfirm = () => setConfirmOpen(false);

  const handleConfirm = () => {
    setConfirmOpen(false);
    onConfirm();
  };

  const handleSecondaryConfirm = onSecondaryConfirm && (() => {
    setConfirmOpen(false);
    onSecondaryConfirm();
  });

  const handleDismissError = () => {
    if (onDismissError) {
      onDismissError();
    }
    handleCloseConfirm();
  }

  return (
    <Popup position="bottom center" on="click" {...props}
      trigger={trigger}
      open={confirmOpen || !!error}
      onOpen={handleOpenConfirm}
      onClose={handleCloseConfirm}
    >
      <Popup.Header content={error || (!children && header)} />
      { error ?
          <Popup.Content>
            {/* <Button primary content="Επανάληψη" onClick={handleConfirm} /> */}
            <Button content={dismiss} onClick={handleDismissError} />
          </Popup.Content>
        : <>
            { children && <Popup.Content content={children} /> }
            <Popup.Content>
              <Button.Group fluid>
                <Button primary content={positive} onClick={handleConfirm} />
                { handleSecondaryConfirm && <Button secondary content={secondary} onClick={handleSecondaryConfirm} /> }
                <Button content={negative} onClick={handleCloseConfirm} />
              </Button.Group>
            </Popup.Content>
          </>
      }
    </Popup>
  );
}
