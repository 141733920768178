import { useEffect, useState } from 'react';

import {
  Button,
  Form,
  Icon,
  Input,
  Loader,
  Message,
  Modal,
  Popup,
} from 'semantic-ui-react';

import { convertArrayToOptions } from '../utils/utils';

import useProfile from '../queries/useProfile';
import { useCategories } from '../queries/categories';
import { useParticipants } from '../queries/participants';
import { useAnonymousUsers } from '../queries/transactionHistory';
import { useMaterialByBarcode, useMaterials } from '../queries/materials';
import { useSubcategories } from '../queries/subcategories';

import { MaterialItemList } from './ReusePickup';
import { useMaterialItems } from '../queries/materialItems';
import { useDebounce } from '@zerowaste/components';

export function UserSelect({
  isTurnin = false,
  emailSearch,
  setEmailSearch,
  email,
  name,
  onChange,
  userEmailDisabled,
  userTextDisabled,
  showAnonymous = true,
  emailError,
  resetErrors,
}) {
  const debouncedEmailSearch = useDebounce(emailSearch, 300);
  const users = useParticipants(
    { search: debouncedEmailSearch },
    {
      enabled: emailSearch.length > 0,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );

  const anonymousUsers = useAnonymousUsers({
    options: { enabled: showAnonymous },
  });
  return (
    <>
      <Form.Dropdown
        disabled={userEmailDisabled}
        label="Email πολίτη"
        name="user_email"
        selection
        clearable
        search
        noResultsMessage={
          users.isLoading ? (
            <Loader active inline size="mini" />
          ) : (
            !users.isIdle && 'Δεν βρέθηκαν αποτελέσματα'
          )
        }
        selectOnNavigation={false}
        selectOnBlur={false}
        options={convertArrayToOptions({
          array: users.data,
          key: 'id',
          text: 'email',
          value: 'email',
        })}
        searchQuery={emailSearch}
        value={email || ''}
        onSearchChange={(e, { searchQuery }) => {
          setEmailSearch(searchQuery);
        }}
        onChange={(e, { value, options }) => {
          setEmailSearch(value);
          onChange('user_email', value || undefined);
          onChange(
            'user_id',
            options.find((user) => user.value === value)?.key || undefined
          );

          isTurnin && onChange('extraction_type', 'REUSE');
        }}
        onBlur={() => {
          setEmailSearch(email || '');
        }}
        error={emailError && 'Αυτό το πεδίο δεν μπορεί να είναι κενό'}
        onClick={() => {
          resetErrors?.();
        }}
      />

      {showAnonymous && (
        <Form.Dropdown
          disabled={userTextDisabled}
          label="Όνομα μη-εγγεγραμμένου πολίτη"
          name="user_text"
          selection
          clearable
          search
          selectOnNavigation={false}
          selectOnBlur={false}
          noResultsMessage={null}
          options={anonymousUsers.data?.map((user, index) => ({
            key: index,
            text: user,
            value: user,
          }))}
          searchQuery={name || ''}
          value={name || ''}
          onSearchChange={(e, { name, searchQuery }) => {
            const val = searchQuery === '' ? undefined : searchQuery;
            onChange(name, val);
            isTurnin && onChange('extraction_type', 'REUSE');
          }}
          onChange={(e, { name, value }) => {
            const val = value === '' ? undefined : value;
            onChange(name, val);
            isTurnin && onChange('extraction_type', 'REUSE');
          }}
        />
      )}
    </>
  );
}

export function MaterialModal({
  formMaterial,
  onSubmit,
  onClose,
  displayKg,
  amountError,
  setAmountError,
}) {
  const [material, setMaterial] = useState(formMaterial);
  const handleChange = (name, value) => {
    setMaterial((material) => ({ ...material, [name]: value }));
    setHasErrors(false);
  };
  const [hasErrors, setHasErrors] = useState(false);

  const errors = {
    material: !material.material,
    amount: !material.amount,
    kilos: displayKg && !parseFloat(material.kilos),
    description:
      material.materialObj?.eligible && !displayKg && !material.description,
    item: material.materialObj?.eligible && displayKg && !material.item,
  };

  const handleSubmit = (material) => {
    if (Object.values(errors).some((val) => val === true)) {
      setHasErrors(true);
    } else {
      onSubmit(material);
      onClose();
    }
  };
  return (
    <Modal open dimmer="inverted" onClose={onClose}>
      <Modal.Header>Στοιχεία υλικού προς {displayKg ? 'παράδοση' : 'παραλαβή'}</Modal.Header>
      <Modal.Content>
        <MaterialSelect
          error={hasErrors && errors}
          displayKg={displayKg}
          amountError={amountError}
          setAmountError={setAmountError}
          kilos={material.kilos}
          category={material.category}
          subcategory={material.subcategory}
          barcode={material.barcode}
          barcodeSearch={material.barcodeSearch}
          materialObj={material.materialObj}
          amount={material.amount}
          description={material.description}
          item={material.item}
          handleChange={(name, value) => handleChange(name, value)}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button positive onClick={() => handleSubmit(material)}>
          Προσθήκη
        </Button>
        <Button onClick={onClose}>Ακύρωση</Button>
      </Modal.Actions>
    </Modal>
  );
}

function MaterialSelect({
  error,
  category,
  subcategory,
  barcode,
  barcodeSearch,
  materialObj,
  amount,
  handleChange,
  displayKg = false,
  kilos,
  amountError,
  setAmountError,
  description,
  item,
}) {
  const categories = useCategories({ params: { page_size: 400 } });
  const subcategories = useSubcategories({
    params: { page_size: 400, category },
    options: { enabled: !!category },
  });
  const materials = useMaterials({
    params: { page_size: 400, category, subcategory },
    options: { enabled: !!subcategory },
  });

  useMaterialByBarcode({
    params: { barcode },
    options: {
      enabled: !!barcode,
      onSuccess: (data) => {
        if (data.count > 0) {
          handleChange('category', data.results[0].subcategory.category.id);
          handleChange('subcategory', data.results[0].subcategory.id);
          handleChange('material', data.results[0].id);
          handleChange('materialObj', data.results[0]);
          handleChange('amount', undefined);
          displayKg
            ? handleChange('item', undefined)
            : handleChange('description', undefined);
          data.results[0].eligible && handleChange('amount', 1);
          displayKg && setAmountError(false);
        }
      },
    },
  });

  const profile = useProfile();

  const materialAvailabilityByKdey =
    materialObj?.availability.find(
      ({ recycle_center }) => recycle_center.id === profile.data?.recycle_center
    )?.amount || 0;

  const handleCategorySelect = (name, value) => {
    handleChange(name, value);
    handleChange('subcategory', undefined);
    handleChange('material', undefined);
    handleChange('materialObj', undefined);
    handleChange('barcodeSearch', undefined);
    handleChange('barcode', undefined);
    handleChange('material', undefined);
    handleChange('amount', undefined);
    displayKg
      ? handleChange('item', undefined)
      : handleChange('description', undefined);
    displayKg && setAmountError(false);
  };

  const handleSubcategorySelect = (name, value) => {
    handleChange(name, value);
    handleChange('material', undefined);
    handleChange('materialObj', undefined);
    handleChange('barcodeSearch', '');
    handleChange('barcode', undefined);
    handleChange('material', undefined);
    handleChange('amount', undefined);
    displayKg
      ? handleChange('item', undefined)
      : handleChange('description', undefined);
    displayKg && setAmountError(false);
  };

  const handleBarcodeChange = (value) => {
    const matchBarcode = /^\d{1,2}.\d{3}.\d{3}$/;
    handleChange('barcodeSearch', value);
    if (matchBarcode.test(value)) {
      handleChange('barcode', value);
    }
  };

  const handleMaterialItemSelect = (item) => {
    handleChange('item', item?.id);
    handleChange('item_description', item?.description);
    handleChange('item_barcode', item?.barcode);
  };

  const materialItems = useMaterialItems(
    {
      material: materialObj?.id,
      is_available: true,
      recycle_center: profile.data?.recycle_center,
      page: 'all',
    },
    {
      enabled: !!materialObj?.eligible && profile.isFetched,
    }
  );

  useEffect(() => {
    error?.kilos && document.getElementById('material-form').scrollIntoView();
  }, [error?.kilos]);

  const availableAmountShown = materialObj
    ? materialAvailabilityByKdey !== undefined
      ? materialAvailabilityByKdey
      : 0
    : '';

  return (
    <>
      <Form id="material-form">
        <Form.Group widths="equal">
          <Form.Dropdown
            className="nowrap"
            selection
            compact
            label="Κατηγορία"
            name="category"
            value={category || ''}
            onChange={(e, { name, value }) => {
              handleCategorySelect(name, value);
            }}
            placeholder="Επιλέξτε κατηγορία"
            loading={categories.isLoading}
            options={convertArrayToOptions({
              array: categories.data?.results,
              key: 'id',
              text: 'name',
              value: 'id',
            })}
          />
          <Form.Dropdown
            className="nowrap"
            selection
            label="Υποκατηγορία"
            name="subcategory"
            disabled={!category}
            value={subcategory || ''}
            onChange={(e, { name, value }) => {
              handleSubcategorySelect(name, value);
            }}
            placeholder="Επιλέξτε υποκατηγορία"
            loading={subcategories.isLoading}
            options={convertArrayToOptions({
              array: subcategories.data?.results,
              key: 'id',
              text: 'name',
              value: 'id',
            })}
          />
          <Form.Dropdown
            className="nowrap"
            selection
            label="Υλικό"
            name="material"
            disabled={!subcategory}
            value={JSON.stringify(materialObj) || ''}
            onChange={(e, { name, value }) => {
              handleChange(name, JSON.parse(value).id);
              handleChange('materialObj', JSON.parse(value));
              handleChange('barcodeSearch', JSON.parse(value).barcode);
              JSON.parse(value).eligible && handleChange('amount', 1);
            }}
            placeholder="Επιλέξτε υλικό"
            loading={materials.isLoading}
            options={convertArrayToOptions({
              array: materials.data?.results,
              key: 'id',
              text: 'body',
            })}
            error={error?.material && 'Αυτό το πεδίο δεν μπορεί να είναι κενό'}
          />
        </Form.Group>
        {materialObj?.eligible && !displayKg && (
          <Form.Input
            label="Όνομα μοναδικού υλικού"
            name="description"
            value={description || ''}
            onChange={(e, { name, value }) => handleChange(name, value)}
            error={
              error?.description && 'Αυτό το πεδίο δεν μπορεί να είναι κενό'
            }
          />
        )}
        <Form.Group>
          <Form.Input
            width={displayKg ? 6 : 8}
            label="Barcode υλικού"
            placeholder="Αναζήτηση μέσω barcode"
            name="barcode"
            value={barcodeSearch || ''}
            onChange={(e, { value }) => {
              handleBarcodeChange(value);
            }}
            onBlur={() => {
              if (materialObj) {
                handleChange('barcodeSearch', materialObj.barcode);
              }
            }}
          />
          <Form.Input
            width={displayKg ? 6 : 8}
            label={
              <Popup
                content={`Το πλήθος των τεμαχίων προς ${
                  displayKg ? 'παράδοση' : 'παραλαβή'
                }`}
                trigger={
                  <label>
                    Πλήθος τεμαχίων{' '}<Icon name="info circle" />
                  </label>
                }
              />
            }
            disabled={!materialObj || materialObj.eligible}
            error={
              (displayKg &&
                amountError &&
                'Το πλήθος ξεπερνάει τα διαθέσιμα τεμάχια') ||
              (error?.amount && 'Αυτό το πεδίο δεν μπορεί να είναι κενό')
            }
          >
            <Input
              name="amount"
              type="number"
              input={{ inputMode: 'numeric', min: 0 }}
              value={amount || 0}
              label={{ content: `${availableAmountShown || 0} διαθέσιμα` }}
              labelPosition="right"
              onChange={(e, { name, value }) => {
                handleChange(name, parseInt(value));
                if (displayKg) {
                  if (parseInt(value) > materialAvailabilityByKdey) {
                    setAmountError(true);
                  } else {
                    setAmountError(false);
                  }
                }
              }}
            />
          </Form.Input>
          {displayKg && (
            <Form.Input
              width={4}
              label={
                <Popup
                  content="Τα κιλά του υλικού που θα παραδοθεί"
                  trigger={
                    <label>
                      Παράδοση Kg <Icon name="info circle" />
                    </label>
                  }
                />
              }
              name="kilos"
              type="number"
              input={{ inputMode: 'decimal', min: 0, step: 0.01 }}
              disabled={!materialObj}
              value={kilos || 0}
              onChange={(e, { name, value }) => {
                handleChange(name, value);
              }}
              error={error?.kilos && 'Αυτό το πεδίο δεν μπορεί να είναι κενό'}
            />
          )}
        </Form.Group>
        {displayKg && materialObj?.eligible === true && (
          <>
            <MaterialItemList
              materialItems={materialItems.data}
              submitOrder={false}
              onSelect={handleMaterialItemSelect}
              selectedItem={item}
              disabledReserved={true}
              loading={materialItems.isLoading}
            />
            {materialItems.data?.length === 0 && (
              <Message info content="Δεν υπάρχουν διαθέσιμα μοναδικά υλικά" />
            )}
            {error?.item && (
              <Message
                color="red"
                content="Παρακαλώ επιλέξτε ένα διαθέσιμο μοναδικό υλικό"
              />
            )}
          </>
        )}
      </Form>
    </>
  );
}
