import { Icon, Input } from 'semantic-ui-react';

import { useDebounce } from './hooks/useDebounce';


export default function SearchFilterInput({ loading, value, setValue, ...props }) {
  const debouncedLoading = useDebounce(!!value && loading, 300);

  return (
    <Input placeholder="Φίλτρο" fluid {...props}
      icon={value ? <Icon name="delete" link onClick={() => setValue('')} /> : 'search'}
      loading={debouncedLoading}
      value={value}
      onChange={(e, { value }) => setValue(value)}
    />
  );
}
