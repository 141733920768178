import { useMutation, useQuery } from 'react-query';

import axios from 'axios';

export const useRepairmen = ({ is_verified, search, page }, options) =>
  useQuery(
    ['repairmen', { is_verified, search, page }],
    () =>
      axios
        .get('/api/repairmen/', { params: { is_verified, search, page } })
        .then(({ data }) => data),
    { ...options }
  );

export const useRepairman = (id, options) =>
  useQuery(
    ['repairmen', { id }],
    () => axios.get(`/api/repairmen/${id}/`).then(({ data }) => data),
    { ...options }
  );

export const useSaveRepairman = (id) =>
  useMutation((data) =>
    axios.patch(`/api/repairmen/${id}/`, data).then(({ data }) => data)
  );

export const useEnableRepairman = (id) =>
  useMutation(() =>
    axios.post(`/api/repairmen/${id}/verify/`).then(({ data }) => data)
  );

export const useDisableRepairman = (id) =>
  useMutation(() =>
    axios.post(`/api/repairmen/${id}/unverify/`).then(({ data }) => data)
  );

export const useDeleteRepairman = (id) =>
  useMutation(() => axios.delete(`/api/repairmen/${id}/`));
