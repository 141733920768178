import { Menu, Pagination, Icon, Divider } from 'semantic-ui-react';


export default function OrientedPagination({ labeled, vertical, activePage, totalPages, onPageChange, padded=true, ...props }) {
  return (<>
    { labeled &&
      <Menu compact pointing
        vertical={vertical}
        fluid={vertical}
        secondary={!vertical}
        attached={vertical && 'top'}
        {...props}
      >
        <Menu.Item header content="Σελίδες" />
      </Menu>
    }

    <Pagination compact pointing
      vertical={vertical}
      fluid={vertical}
      secondary={!vertical}

      attached={vertical && 'bottom'}
      className={vertical ? 'left' : ''}

      boundaryRange={1} siblingRange={1}
      // nextItem={null} prevItem={null}
      nextItem={{ content: <Icon name={`angle ${vertical ? 'down' : 'right'}`} /> }} prevItem={{ content: <Icon name={`angle ${vertical ? 'up' : 'left'}`} /> }}
      firstItem={null} lastItem={null}
      // firstItem={{ content: <Icon name={`angle double ${vertical ? 'up' : 'left'}`} /> }} lastItem={{ content: <Icon name={`angle double ${vertical ? 'down' : 'right'}`} /> }}

      activePage={activePage} totalPages={totalPages}
      onPageChange={onPageChange}

      {...props}
    />

    { !vertical && padded && <Divider clearing hidden /> }

  </>);
}
