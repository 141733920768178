import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';

export default function useCreateUpdate({
  typeId,
  baseUrl,
  deleteUrl,
  typeName,
  sideEffect,
  useType,
  useCreateMutation,
  useUpdateMutation,
  useDeleteMutation,
  extractCategoryId = false,
  defaultState = {},
}) {
  const queryClient = useQueryClient();
  const location = useLocation();
  const history = useHistory();

  const [formData, setFormData] = useState(() => ({ ...defaultState }));

  const isNew = location.state?.isNew;

  const onCreateSuccess = (data) => {
    queryClient.invalidateQueries([typeName]);
    sideEffect && queryClient.invalidateQueries([sideEffect]);
    history.push(`${baseUrl}/${data.id}`, { isNew: true });
  };

  const onUpdateSuccess = (data) => {
    queryClient.invalidateQueries([typeName]);
    sideEffect && queryClient.invalidateQueries([sideEffect]);
  };

  const onDeleteSuccess = () => {
    queryClient.invalidateQueries([typeName]);
    sideEffect && queryClient.invalidateQueries([sideEffect]);
    history.replace(`${deleteUrl}`);
  };

  const type = useType(typeId, {
    options: {
      enabled: typeId !== 'new',
      onSuccess: () => {
        setFormData({});
      },
    },
  });

  const create = useCreateMutation?.(onCreateSuccess) || {};
  const update = useUpdateMutation?.(typeId, onUpdateSuccess);
  const deleteMutation = useDeleteMutation?.(typeId, onDeleteSuccess);

  const codeError =
    (create.error?.response.data['code'] ||
      create.error?.response.data['non_field_errors']?.[0].includes(
        'unique set'
      ) ||
      update.error?.response.data['code'] ||
      update.error?.response.data['non_field_errors']?.[0].includes(
        'unique set'
      )) &&
    'Ο κωδικός που επιλέξατε χρησιμοποιείτε';

  const serverError =
    create.error?.response.status === 500 ||
    update.error?.response.status === 500 ||
    deleteMutation?.error?.response.status === 500;

  const formError =
    !codeError &&
    (create.error?.response.status === 400 ||
      update.error?.response.status === 400 ||
      deleteMutation?.error?.response.status === 400);

  const isMutationLoading = create.isLoading || update.isLoading;
  const isDeleteLoading = deleteMutation?.isLoading;

  const getValue = (name) =>
    (formData[name] === undefined ? type.data?.[name] : formData[name]) ?? '';

  const handleSubmit = () => {
    const finalFormData = extractCategoryId
      ? { ...formData, category: formData.category?.id }
      : formData;
    if (typeId === 'new') {
      create.mutate(
        Object.fromEntries(
          Object.entries(finalFormData).filter(([_, value]) => value != null)
        )
      );
    } else {
      update.mutate(
        Object.fromEntries(
          Object.entries(finalFormData).filter(([_, value]) => value != null)
        )
      );
    }
  };

  const handleChange = (e, { name, value }) => {
    setFormData((formData) => ({ ...formData, [name]: value }));
  };

  const handleDelete = () => {
    deleteMutation.mutate();
  };
  const isUpdated = update.isSuccess;

  return {
    formData,
    isNew,
    isUpdated,
    type,
    getValue,
    handleSubmit,
    handleChange,
    handleDelete,
    isMutationLoading,
    isDeleteLoading,
    codeError,
    serverError,
    formError,
  };
}
