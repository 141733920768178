import { useEffect } from 'react';
import {
  Route,
  Switch,
  Redirect,
  useHistory,
  useLocation,
} from 'react-router-dom';

import { Loader } from 'semantic-ui-react';

import { Announcements, useToken } from '@zerowaste/components';

import ReuseIntro from './ReuseIntro';
import ReuseCenters from './ReuseCenters';
import ReusePickup from './ReusePickup';
import ReuseTurnIn from './ReuseTurnIn';
import FaqPage from './FaqPage';
import Terms from './Terms';
import { PasswordChangeForm, PasswordResetModal } from './PasswordReset';
import ActivateAccount from './ActivateAccount';

import ParticipantProfile from './ParticipantProfile';
import { TransactionHistory } from './TransactionHistory';
import { ParticipantApplicationFormInfo } from './ParticipantApplicationForm';
import ReuseRepairmen from './ReuseRepairmen';
import ParticipantReservations from './ParticipantReservations';

import useProfile from '../queries/useProfile';

import axios from 'axios';
import qs from 'qs';

function ExecuteEffect({ onMount }) {
  useEffect(onMount);
  return null;
}

function ReuseHome({ onLogin, onRegister, registerUrl }) {
  const location = useLocation();
  const history = useHistory();

  const { setToken } = useToken();

  const { code, state: returnTo } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  useEffect(() => {
    if (!code) return;

    axios.post('/api/zerowaste/auth/', { code }).then(
      ({ data }) => {
        setToken(data.token);
        history.replace(returnTo);
      },
      (err) => {
        // TODO show something to the user ???
        console.warn(err);
        // just remove the code part, and stay at home page
        history.replace({ search: '' });
      }
    );
  }, [code, returnTo, setToken, history]);

  const profile = useProfile();
  const unknownOrParticipant = !profile.data || profile.data.participant;
  const redirectNonParticipant = !profile.data
    ? '/'
    : profile.data.repairman
    ? '/repair'
    : '/admin';

  return (
    <>
      <Switch>
        <Route path="/" exact>
          <ReuseIntro onRegister={onRegister('/profile')} />
        </Route>

        <Route path="/reuse-centers">
          <ReuseCenters />
        </Route>

        <Route path="/pick-up">
          <ReusePickup />
        </Route>

        <Route path="/turn-in">
          <ReuseTurnIn />
        </Route>

        <Route path="/questions">
          <FaqPage registerUrl={registerUrl} />
        </Route>

        <Route path="/news">
          <Announcements />
        </Route>
        <Route path="/repairmen">
          <ReuseRepairmen />
        </Route>

        <Route path="/terms">
          <Terms />
        </Route>

        <Route exact path="/application-form">
          <ParticipantApplicationFormInfo />
        </Route>

        <Route exact path="/password/reset" component={PasswordResetModal} />
        <Route
          path="/password/reset/confirm/:uid/:token"
          component={PasswordChangeForm}
        />
        <Route
          path="/account/activate/:uid/:token"
          component={ActivateAccount}
        />

        {profile.isFetched && !code && unknownOrParticipant && (
          <>
            <Route key="profile" path="/profile">
              {profile.data?.participant ? (
                <ParticipantProfile />
              ) : (
                <ExecuteEffect onMount={onLogin('/profile')} />
              )}
            </Route>
            <Route
              key="reservations"
              path={[
                '/reservations/:pageKey(page)/:page(\\d+)',
                '/reservations/:reservationId(\\d+)',
                '/reservations',
              ]}
            >
              {profile.data?.participant ? (
                <ParticipantReservations baseUrl="/reservations" />
              ) : (
                <ExecuteEffect onMount={onLogin('/reservations')} />
              )}
            </Route>
            <Route
              key="history"
              path={[
                '/history/page/:page(\\d+)',
                '/history/:historyId(\\d+)',
                '/history',
              ]}
            >
              {profile.data?.participant ? (
                <TransactionHistory baseUrl="history" />
              ) : (
                <ExecuteEffect onMount={onLogin('/history')} />
              )}
            </Route>
          </>
        )}

        {/* if we are still loading the user, let's show a loader instead of redirecting */}
        <Route path="*">
          {!profile.isFetched || code ? (
            <Loader inline="centered" size="massive" active />
          ) : (
            <Redirect to={redirectNonParticipant} />
          )}
        </Route>
      </Switch>
    </>
  );
}

export default ReuseHome;
