import { useCallback, useEffect } from 'react';
import { Switch, Route, useHistory, useLocation } from 'react-router-dom';

import { Helmet } from 'react-helmet';

import { Login, TokenProvider, useToken } from '@zerowaste/components';

import 'semantic-ui-css/semantic.min.css';
import './App.css';

import qs from 'qs';

import ReuseContainer from './components/ReuseContainer';
import ReuseFooter from './components/ReuseFooter';
import ReuseHome from './components/ReuseHome';
import ReuseAdmin from './components/ReuseAdmin';
import RepairmanSignup from './components/RepairmanSignup';
import RepairmanHome from './components/RepairmanHome';

import useProfile from './queries/useProfile';

const loginUrl = (returnTo, cancelTo) => `${process.env.REACT_APP_ZEROWASTE_LOGIN_URL}?${qs.stringify({
  destination: `/oauth/authorize/?${qs.stringify({
    client_id: process.env.REACT_APP_ZEROWASTE_CLIENT_ID,
    response_type: 'code',
    state: returnTo,
  })}`,
  cancelTo: cancelTo ?? window.location.href,
})}`;

const registerUrl = (returnTo, cancelTo) => `${process.env.REACT_APP_ZEROWASTE_REGISTER_URL}?${qs.stringify({
  destination: `/oauth/authorize/?${qs.stringify({
    client_id: process.env.REACT_APP_ZEROWASTE_CLIENT_ID,
    response_type: 'code',
    state: returnTo,
  })}`,
  cancelTo: cancelTo ?? window.location.href,
})}`;

const privatePageRegExp = /^\/(admin|repair|profile|history|reservations)/;

function ReuseLogin() {
  const { state } = useLocation();
  const header = `Σύνδεση στην πλατφόρμα${state?.role === 'repair' ? ' ως επισκευαστής' : ''}`;

  return (
    <>
      <Helmet title={header} />
      <Login
        passwordReset="/password/reset"
        header={header}
      />
    </>
  );
}

function Reuse() {
  const history = useHistory();
  const location = useLocation();

  const { token, setToken } = useToken();

  // token clear logic
  const { logout: logoutOnMount } = qs.parse(location.search, { ignoreQueryPrefix: true });
  useEffect(() => {
    if (logoutOnMount) {
      // clear search string
      history.replace({
        search: '',
      });

      setToken(null);
    }
  }, [logoutOnMount, history, setToken]);

  const profile = useProfile();

  const handleLogin = (returnTo, cancelTo) => () => {
    if (!returnTo) returnTo = window.location.pathname;

    if (process.env.REACT_APP_ZEROWASTE_CLIENT_ID) {
      cancelTo &&= `${window.location.protocol}//${window.location.host}${cancelTo}`;
      window.location = loginUrl(returnTo);
    } else {
      history.push({ pathname: '/login', state: { returnTo, cancelTo } });
    }
  };

  const handleRegister = (returnTo, cancelTo) => () => {
    if (!returnTo) returnTo = window.location.pathname;
    cancelTo &&= `${window.location.protocol}//${window.location.host}${cancelTo}`;
    window.location = registerUrl(returnTo, cancelTo);
  };

  const handleLogout = useCallback(() => {
    // check if we are not in a private page
    const privatePage = privatePageRegExp.test(window.location.pathname);
    // go to home to avoid re-login
    if (privatePage) history.push('/');
    // logout
    setToken(null);

    if (process.env.REACT_APP_ZEROWASTE_CLIENT_ID) {
      const returnTo = privatePage ? `${window.location.protocol}//${window.location.host}/` : window.location.href;
      window.location = `${process.env.REACT_APP_ZEROWASTE_LOGOUT_URL}?${qs.stringify({ returnTo })}`;
    }

  }, [setToken, history]);

  useEffect(() => {
    profile.refetch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <ReuseContainer onLogin={handleLogin('/profile', window.location.pathname)} onRegister={handleRegister('/profile')} onLogout={handleLogout}>

      <Helmet
        defaultTitle="Πλατφόρμα επαναχρησιμοποίησης υλικών &ndash; ΔΙΑΔΥΜΑ ΑΕ"
        titleTemplate="%s | Πλατφόρμα επαναχρησιμοποίησης υλικών &ndash; ΔΙΑΔΥΜΑ ΑΕ"
      >
        <meta property="og:locale" content="el_GR" />
        <meta property="og:site_name" content="Πλατφόρμα επαναχρησιμοποίησης υλικών &ndash; ΔΙΑΔΥΜΑ ΑΕ" />
      </Helmet>

      <main>
        <Switch>
          <Route path="/login">
            <ReuseLogin />
          </Route>
          <Route path="/signup">
            <Helmet title="Εγγραφή επισκευαστή" />
            <RepairmanSignup />
          </Route>
          <Route path="/repair">
            <RepairmanHome />
          </Route>
          <Route path="/admin">
            <Helmet defaultTitle="Διαχείριση | Πλατφόρμα επαναχρησιμοποίησης υλικών &ndash; ΔΙΑΔΥΜΑ ΑΕ" />
            <ReuseAdmin />
          </Route>
          <Route>
            <ReuseHome onLogin={handleLogin} onRegister={handleRegister} registerUrl={registerUrl('/profile')} />
          </Route>
        </Switch>
      </main>

      <ReuseFooter />
    </ReuseContainer>
  );
}

function App() {
  return (
    <TokenProvider>
        <Reuse />
    </TokenProvider>
  );
}

export default App;
