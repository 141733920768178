import { useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { Button, Container, Form, Header, Message, Modal } from 'semantic-ui-react'

import { useToken } from './context/token';

import axios from 'axios';
import qs from 'qs';


export default function Login({ header='Σύνδεση στην διαχείριση', passwordReset }) {
  const { setToken } = useToken();

  const history = useHistory();
  const location = useLocation();

  // TODO remove support for search string-based redirects
  let { returnTo='/admin', cancelTo='/' } = qs.parse(location.search, { ignoreQueryPrefix: true });
  returnTo ??= location.state?.returnTo ?? '/admin';
  cancelTo ??= location.state?.cancelTo ?? '/';

  const [username, setUserName] = useState();
  const [password, setPassword] = useState();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsLoading(true);
    axios.post('/api/auth/token/login', {
      email: username,
      password,
    }, {
      // this way we can login with a user even if we have a saved token
      // it's safe, as if it fails, the user will still be logged in
      headers: {'Authorization': undefined }
    }).then(
      ({ data }) => {
        setToken(data.auth_token);
        setIsLoading(false);
        history.replace(returnTo);
      },
      (err) => {
        // do nothing? 
        setError(true);
        setIsLoading(false);
      }
    );
  };

  useEffect(() => setError(false), [username, password]);

  return (
    <Modal 
      centered={false} dimmer="inverted" size="tiny"
      open onClose={() => history.replace(cancelTo)}
    >
      <Modal.Header>
        <Header as="h2" textAlign="center" content={header} />
      </Modal.Header>
      <Modal.Content>
        <Form id="loginForm" size="large" onSubmit={handleSubmit} error={error}>

            <Form.Input icon="user" iconPosition="left"
              name="email" input={{ inputMode: 'email' }}
              placeholder="Διεύθυνση email"
              onChange={e => setUserName(e.target.value)}
            />
            <Form.Input icon="lock" iconPosition="left" type="password"
              name="password"
              placeholder="Συνθηματικό"
              onChange={e => setPassword(e.target.value)}
            />

            <Container textAlign="center">
              { passwordReset && <Link to={passwordReset}>Ξέχασα το συνθηματικό μου</Link> }
            </Container>

            <Message error content="Αποτυχία σύνδεσης, παρακαλώ προσπαθήστε ξανά." />
          
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button form="loginForm" primary size="large" content="Σύνδεση" loading={isLoading} />
        <Button size="large" content="Επιστροφή" as={Link} to={cancelTo} replace />
      </Modal.Actions>
    </Modal>
  );
}
