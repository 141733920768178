import { Grid } from 'semantic-ui-react';

import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import StatCard from './StatCard';

import { useTransactionStats } from '../../queries/dashboard/transactions';

const COLORS = {
  reused: 'orange',
  recycled: 'green',
  buried: 'brown',
};

const BAR_COLORS = ['#218380', '#d81159'];

export function TotalTransactions({ params }) {
  const generalStats = useTransactionStats(params);

  const barData = [
    { name: 'Παραλαβές', amount: generalStats.data?.collected },
    { name: 'Παραδόσεις', amount: generalStats.data?.delivered },
  ];

  return (
    <ResponsiveContainer width="100%" height={438}>
      <BarChart width={400} height={400} data={barData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Bar dataKey="amount" name="Ποσότητα" unit=" τεμάχια" barSize={30}>
          {barData.map((data, index) => (
            <Cell key={index} fill={BAR_COLORS[index % BAR_COLORS.length]} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
}

export default function TransactionStats({ params }) {
  const generalStats = useTransactionStats(params);

  let {
    collected = 0,
    reused = 0,
    recycled = 0,
    buried = 0,
  } = generalStats.data ?? {};

  if (collected) {
    reused = Math.round((reused / collected) * 100);
    recycled = Math.round((recycled / collected) * 100);
    buried = Math.round((buried / collected) * 100);
  }

  return (
    <Grid stackable columns="equal">
      <Grid.Column>
        <StatCard
          title="Σύνολο επαναχρησιμοποίησης"
          icon="cube"
          color={COLORS['reused']}
          value={`${reused} %`}
        />
      </Grid.Column>
      <Grid.Column>
        <StatCard
          title="Σύνολο ανακύκλωσης"
          icon="cube"
          color={COLORS['recycled']}
          value={`${recycled} %`}
        />
      </Grid.Column>
      <Grid.Column>
        <StatCard
          title="Σύνολο ταφής"
          icon="cube"
          color={COLORS['buried']}
          value={`${buried} %`}
        />
      </Grid.Column>
    </Grid>
  );
}
