import { Helmet } from 'react-helmet';

import { Container, Divider, Header } from 'semantic-ui-react';

import PointsBalance from './PointsBalance';

export default function ParticipantProfile() {
  const pageTitle = 'Λογαριασμός';
  return (
    <>
      <Helmet title={pageTitle} />
      <Container>
        <Header
          as="h1"
          textAlign="center"
          className="colored atmgreen"
          content={pageTitle}
        />
        <Divider className="orange" />
      </Container>

      <Container text>
        <Divider hidden />
        <PointsBalance />

        <Divider section hidden />
      </Container>
    </>
  );
}
