import { Helmet } from 'react-helmet';

import {
  Container,
  Divider,
  Header,
  List,
  Segment,
  Table,
} from 'semantic-ui-react';

import { useKdeys } from '../queries/kdeys';
import { useCategories } from '../queries/categories';

export default function ReuseTurnIn() {
  const pageTitle = 'Διαδικασία Παράδοσης Υλικού';

  const kdeys = useKdeys();
  const categories = useCategories({
    params: { page_size: 400 },
  });

  return (
    <>
      <Helmet title={pageTitle} />

      <Container>
        <Header
          textAlign="center"
          as="h1"
          className="colored atmgreen"
          content={pageTitle}
        />
        <Divider className="orange" />
      </Container>

      <Segment padded="very" basic attached className="colored">
        <Container text>
          <p>Η παράδοση υλικών στο ΚΔΕΥ είναι δωρεάν.</p>
          <p>
            Για τα ογκώδη και βαριά αντικείμενα απευθυνθείτε στον οικείο Δήμο
            σας.
          </p>
          <Header as="h3">Ώρες παράδοσης:</Header>
          <List relaxed>
            <List.Item>08:30-14:30 (Καθημερινά)</List.Item>
            <List.Item>09:30-12:30 (Σάββατο)</List.Item>
          </List>
          <Header as="h3">Τα υλικά θα πρέπει:</Header>
          <div className="padded-left">
            <List bulleted>
              <List.Item>Να είναι σε καλή κατάσταση/λειτουργικά</List.Item>
              <List.Item>
                Να μην έχουν φθαρεί σε βαθμό που δεν μπορούν να
                επαναχρησιμοποιηθούν
              </List.Item>
              <List.Item>Οι ηλεκτρικές συσκευές να λειτουργούν</List.Item>
            </List>
          </div>
          <Header as="h3">Λίστα αποδεκτών υλικών:</Header>
          <div className="padded-left">
            <List bulleted>
              {categories.data?.results.map(({ name: categoryName }) => (
                <List.Item>{categoryName}</List.Item>
              ))}
              {/* <List.Item>
                Βιβλία – Χαρτικά – Είδη γραφείου – Γραφική ύλη
              </List.Item>
              <List.Item>
                Οικιακά σκεύη (πιάτα, ποτήρια, μαγειρικά σκεύη, κλπ)
              </List.Item>
              <List.Item>Είδη οικιακής χρήσης-Διακοσμητικά</List.Item>
              <List.Item>
                Είδη προσωπικής περιποίησης (σεσουάρ, ξυριστικές μηχανές, κλπ)
              </List.Item>
              <List.Item>Εργαλεία (παντός τύπων)</List.Item>
              <List.Item>
                Ηλεκτρικές & Ηλεκτρονικές Συσκευές (ψυγεία, πλυντήρια, Η/Υ,
                μικροσυσκευές, κλπ)
              </List.Item>
              <List.Item>
                Είδη ένδυσης – Υπόδησης – Αξεσουάρ – Είδη ταξιδιού
              </List.Item>
              <List.Item>Λευκά ειδή (πετσέτες, σεντόνια, κλπ)</List.Item>
              <List.Item>Μοκέτες – χαλιά</List.Item>
              <List.Item>
                Είδη επίπλωσης (καρέκλες, τραπέζια, καναπέδες, σύνθετα, κλπ)
              </List.Item>
              <List.Item>Είδη χόμπι-Δραστηριότητες (διάφορα είδη)</List.Item>
              <List.Item>Παιχνίδια (διάφορα είδη)</List.Item>
              <List.Item>
                Παιδικά είδη (είδη βρεφοανάπτυξης, παιδικά καθίσματα, κλπ)
              </List.Item>
              <List.Item>CD/DVD (ταινιών, μουσικής, παιχνιδιών, κλπ)</List.Item>
              <List.Item>
                Είδη για κατοικίδια ζώα (σπίτια, κλουβιά, είδη περιποίησης, κλπ)
              </List.Item>
              <List.Item>
                Εποχιακά είδη (χριστουγεννιάτικα, πασχαλιάτικα, καλοκαιρινά,
                κλπ)
              </List.Item> */}
            </List>
          </div>
          <Header textAlign="center" as="h3" size="small">
            Το ραντεβού είναι <u>προαιρετικό</u> και στοχεύει στην καλύτερη
            εξυπηρέτησή σας
          </Header>
          <p className="centered-text">
            Μπορείτε να κλείσετε ραντεβού στα τηλέφωνα:
          </p>
          <div className="intro-steps left">
            <Table basic="very">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Τηλέφωνο επικοινωνίας</Table.HeaderCell>
                  <Table.HeaderCell>ΚΔΕΥ</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {kdeys.data?.map(({ name: centerName, telephone }) => (
                  <Table.Row>
                    <Table.Cell>
                      <a href={`tel:${telephone}`}>{telephone}</a>
                    </Table.Cell>
                    <Table.Cell>{centerName}</Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </div>
          <Divider section hidden />
        </Container>
      </Segment>
    </>
  );
}
