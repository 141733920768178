import { Dropdown } from 'semantic-ui-react';

import { useKdeys } from '../queries/kdeys';

export default function KdeyDropdown({ kdey, onSelect }) {
  const kdeys = useKdeys();

  return (
    <Dropdown
      selectOnBlur={false}
      loading={kdeys.isLoading}
      options={
        kdeys.data?.map(({ id, name }) => ({
          key: id,
          text: name,
          value: id,
        })) || []
      }
      selection
      clearable
      placeholder="Όλα τα ΚΔΕΥ"
      value={kdey}
      onChange={(e, data) => onSelect(data.value)}
      style={{ minWidth: 'unset', width: '100%', maxWidth: '14em' }}
    />
  );
}
