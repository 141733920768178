import { createContext, useContext, useState } from 'react';

import axios from 'axios';

// so that we can mock run this on the server
const localStorage = typeof window === 'undefined' ? {
  setItem: () => {},
  getItem: () => {},
  removeItem: () => {},
} : window.localStorage;

const getToken = () => {
  const tokenString = localStorage.getItem('token');
  if (tokenString) {
    // add it in all axios requests
    axios.defaults.headers.common['Authorization'] = `Token ${tokenString}`;
  }

  return tokenString;
};

const AuthContext = createContext();

function TokenProvider(props) {
  const [token, setToken] = useState(getToken);

  const saveToken = (userToken) => {
    if (userToken) {
      localStorage.setItem('token', userToken);
      // add it in all axios requests
      axios.defaults.headers.common['Authorization'] = `Token ${userToken}`;
    } else {
      localStorage.removeItem('token');
      // clear it also from all axios requests
      axios.defaults.headers.common['Authorization'] = null;

      // make sure state reverts to null (initial state)
      userToken = null;
    }

    setToken(userToken);
  };

  return (
    <AuthContext.Provider value={{ token, setToken: saveToken }} {...props} />
  );
}

const useToken = () => useContext(AuthContext);

export { TokenProvider, useToken };
