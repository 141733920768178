import { useEffect } from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';

import { Loader } from 'semantic-ui-react';

import { AnnouncementsAdmin } from '@zerowaste/components';

import { Helmet } from 'react-helmet';
import AdminTurnΙn from './AdminTurnIn';
import AdminPickup from './AdminPickup';
import AdminHistory from './TransactionHistory';
import AdminKdeys from './AdminKdeys';
import AdminMaterialManage from './AdminMaterialManage';
import AdminRepairmen from './AdminRepairmen';
import ReuseDashboard from './ReuseDashboard';

import useProfile from '../queries/useProfile';

import qs from 'qs';


function ReuseAdmin({ onLogin }) {
  const location = useLocation();
  const profile = useProfile();

  const { cancelTo, ...params } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  useEffect(() => {
    if (profile.isFetched && !profile.data) {
      // if we are logged out and want to go to admin, let's redirect them to login page
      onLogin?.(location.pathname + `?${qs.stringify(params)}`, '/')();
    }
  }, [profile, location, params, onLogin])

  // wait for it...
  if (!profile.isFetched) {
    return <Loader inline="centered" size="massive" active />
  }

  // if we are repairman and want to go to admin, let's send them to the business admin pages
  if (profile.data?.repairman) {
    return <Redirect to="/repair/offers" />;
  }

  if (profile.data?.participant) {
    // we are a participant, just take them to the root
    return <Redirect to="/" replace />;
  }

  if (!profile.data) {
    if (onLogin) {
      // if logged out, useEffect will do the rest
      return null;
    } else {
      // useEffect won't do anything, fallback to local login screen
      return (
        <Redirect
          to={{
            pathname: '/login',
            state: {
              returnTo: location.pathname + `?${qs.stringify(params)}`,
              cancelTo,
              role: 'admin',
            },
          }}
        />
      ) ;
    }
  }

  const isSupervisor = profile.data?.is_supervisor;
  const isEmployeeOnly =
    profile.data?.is_employee && !profile.data?.is_supervisor;

  return (
    <Switch>
      <Route path="/admin" exact>
        <Redirect to={isSupervisor ? '/admin/dashboard' : '/admin/pickup'} />
      </Route>

      <Route path="/admin/material-manage">
        <AdminMaterialManage />
      </Route>
      {isEmployeeOnly && (
        <Route key="pickup" path="/admin/pickup">
          <AdminPickup />
        </Route>
      )}
      {isEmployeeOnly && (
        <Route key="turnin" path="/admin/turnin">
          <AdminTurnΙn />
        </Route>
      )}
      <Route key="history" path="/admin/history">
        <AdminHistory />
      </Route>

      {isSupervisor && (
        <>
          <Route key="dashboard" path="/admin/dashboard">
            <ReuseDashboard />
          </Route>
          <Route key="repairmen" path="/admin/repairmen">
            <AdminRepairmen />
          </Route>
          <Route key="kdey" path="/admin/kdey">
            <AdminKdeys />
          </Route>
          <Route key="news" path="/admin/news">
            <Helmet
              defaultTitle="Διαχείριση αναρτήσεων | Πλατφόρμα ανακύκλωσης τηγανέλαιων &ndash; ΔΙΑΔΥΜΑ ΑΕ"
              titleTemplate="%s | Διαχείριση αναρτήσεων | Πλατφόρμα ανακύκλωσης τηγανέλαιων &ndash; ΔΙΑΔΥΜΑ ΑΕ"
            />
            <AnnouncementsAdmin />
          </Route>
        </>
      )}

      <Route path="*">
        <Redirect to="/admin" />
      </Route>
    </Switch>
  );
}

export default ReuseAdmin;
