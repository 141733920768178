import { useState } from 'react';
import { createMedia } from '@artsy/fresnel';

import {
  Button,
  Container,
  Dropdown,
  Grid,
  Header,
  Icon,
  Image,
  Loader,
  Menu,
  Modal,
  Sidebar,
} from 'semantic-ui-react';

import { ScrollToHere } from './ScrollTo';

import diadymaImg from './images/DIADYMA_L.png';

const { MediaContextProvider, Media } = createMedia({
  breakpoints: {
    mobile: 0,
    tablet: 768,
    computer: 992,
  },
});

function SettingsModal({ open, onClose }) {
  const [isLoading, setIsLoading] = useState(true);

  const onCloseWithResetLoading = () => {
    onClose();
    setIsLoading(true);
  };

  return (
    <Modal
      size="small"
      open={open}
      onClose={onCloseWithResetLoading}
      dimmer="inverted"
      centered={false}
    >
      <Modal.Content>
        <iframe
          className={isLoading ? 'ui placeholder' : ''}
          style={{ maxWidth: 'unset' }}
          onLoad={() => {
            setIsLoading(false);
          }}
          width="100%"
          height={500}
          title="settings"
          src={process.env.REACT_APP_ZEROWASTE_SETTINGS_URL}
          loading="lazy"
          frameBorder="0"
          scrolling="no"
        ></iframe>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onCloseWithResetLoading}>Κλείσιμο</Button>
      </Modal.Actions>
    </Modal>
  );
}

function UserButton({ name, onLogout, onSettingsOpen }) {
  const [menuOpen, setMenuOpen] = useState(false);
  const toggle = () => setMenuOpen((prevOpen) => !prevOpen);
  const close = () => setMenuOpen(false);

  return (
    <Button.Group style={{ padding: '8px 0' }}>
      <Button active={menuOpen} onClick={toggle} icon="user" content={name} />
      <Dropdown
        className="button icon"
        open={menuOpen}
        onClick={toggle}
        value={null}
        onChange={close}
      >
        <Dropdown.Menu>
          {onSettingsOpen && (
            <Dropdown.Item
              text="Ρυθμίσεις"
              icon="setting"
              onClick={() => {
                onSettingsOpen();
              }}
            />
          )}
          <Dropdown.Item
            text="Έξοδος"
            icon="sign-out"
            onClick={() => {
              onLogout?.();
            }}
          />
        </Dropdown.Menu>
      </Dropdown>
    </Button.Group>
  );
}

function AuthSection({
  user,
  showLoginRegister,
  onLogin,
  onLogout,
  onRegister,
  displaySettings,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleModalOpen = () => {
    setIsModalOpen(true);
  };
  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      {user ? (
        <Menu.Item fitted>
          <UserButton
            name={user.email}
            onLogout={onLogout}
            onSettingsOpen={displaySettings && handleModalOpen}
          />
        </Menu.Item>
      ) : showLoginRegister ? (
        <div style={{ display: 'flex', gap: '8px', padding: '8px 0' }}>
          <Button
            icon="sign-in"
            content="Σύνδεση"
            onClick={() => {
              onLogin?.();
            }}
            style={{ margin: 0 }}
          />
          <Button
            className="signup-bt"
            icon="signup"
            content="Εγγραφή"
            onClick={() => {
              onRegister?.();
            }}
            style={{ margin: 0 }}
          />
        </div>
      ) : (
        <Menu.Item>
          <Loader inverted size="small" inline="centered" active />
        </Menu.Item>
      )}
      {displaySettings && (
        <SettingsModal onClose={handleModalClose} open={isModalOpen} />
      )}
    </>
  );
}

function ResponsiveHeader({ menuItems, title, showAuth, authSection }) {
  return (
    <div className="header-menu">
      <Grid container style={{ margin: 0, paddingBottom: '8px' }}>
        <Grid.Row only="tablet" centered style={{ alignItems: 'center' }}>
          <Grid.Column width={16}>
            <Header as="h1" textAlign="center" style={{ marginBottom: 0 }}>
              {title}
            </Header>
          </Grid.Column>
          <Grid.Column width={8}>
            <Image centered src={diadymaImg} />
          </Grid.Column>
          {showAuth && (
            <Grid.Column width={8} textAlign="center">
              <Menu secondary compact>
                {authSection}
              </Menu>
            </Grid.Column>
          )}
        </Grid.Row>
        <Grid.Column
          only="mobile computer"
          mobile={16}
          computer={4}
          style={{ margin: 0 }}
        >
          <Image centered src={diadymaImg} />
        </Grid.Column>
        <Grid.Column
          only="computer"
          width={12}
          textAlign="center"
          stretched
          style={{ padding: 0 }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <Header
              as="h1"
              textAlign="center"
              style={{ marginBottom: 0, marginTop: '16px' }}
            >
              {title}
            </Header>
            <Menu
              className="header-menu"
              secondary
              compact
              style={{ paddingBottom: '1rem' }}
            >
              {menuItems}
            </Menu>
          </div>
        </Grid.Column>
        <Grid.Column
          only="tablet"
          width={16}
          textAlign="center"
          stretched
          style={{ padding: 0, alignItems: 'center' }}
        >
          <Menu
            className="header-menu"
            secondary
            compact
            style={{ paddingBottom: '1rem' }}
          >
            {menuItems}
          </Menu>
        </Grid.Column>
      </Grid>
    </div>
  );
}

function DesktopContainer({
  children,
  notLoggedInMenu,
  notLoggedIn,
  userMenu,
  adminMenu,
  headerTitle,
  userPaths,
  contentPaths,
  menuItems,
  showAuth,
  authSection,
}) {
  return (
    <Media at="computer">
      <div className="header-content">
        <ResponsiveHeader
          menuItems={menuItems}
          title={headerTitle}
          showAuth={showAuth}
          authSection={authSection}
        />

        <ScrollToHere only="tablet" paths={userPaths} />
        {notLoggedInMenu ? (
          <Menu
            stackable
            borderless
            className="header-submenu header-not-logged-menu"
          >
            <Container
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              {notLoggedInMenu}
              {authSection}
            </Container>
          </Menu>
        ) : (
          notLoggedIn && (
            <Menu
              stackable
              borderless
              className="header-submenu header-not-logged-menu"
            >
              <Container
                style={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                {authSection}
              </Container>
            </Menu>
          )
        )}
        {userMenu && (
          <Menu
            stackable
            borderless
            className="header-submenu header-user-menu"
          >
            <Container>
              <Container style={{ display: 'flex' }}>{userMenu}</Container>
              {authSection}
            </Container>
          </Menu>
        )}
        <ScrollToHere only="tablet" paths={['/admin']} />
        {adminMenu && (
          <Menu
            stackable
            borderless
            className="header-submenu header-admin-menu"
          >
            <Container>
              <Container style={{ display: 'flex' }}>{adminMenu}</Container>
              {authSection}
            </Container>
          </Menu>
        )}

        <ScrollToHere paths={contentPaths} />
      </div>

      {children}
    </Media>
  );
}

function TabletContainer({
  children,
  notLoggedInMenu,
  userMenu,
  adminMenu,
  headerTitle,
  userPaths,
  contentPaths,
  menuItems,
  showAuth,
  authSection,
}) {
  return (
    <Media at="tablet">
      <div className="header-content">
        <ResponsiveHeader
          menuItems={menuItems}
          title={headerTitle}
          showAuth={showAuth}
          authSection={authSection}
        />

        <ScrollToHere only="tablet" paths={userPaths} />
        {notLoggedInMenu && (
          <Menu
            stackable
            borderless
            className="header-submenu header-not-logged-menu"
          >
            <Container>{notLoggedInMenu}</Container>
          </Menu>
        )}
        {userMenu && (
          <Menu
            stackable
            borderless
            className="header-submenu header-user-menu"
          >
            <Container>{userMenu}</Container>
          </Menu>
        )}
        <ScrollToHere only="tablet" paths={['/admin']} />
        {adminMenu && (
          <Menu
            stackable
            borderless
            className="header-submenu header-admin-menu"
          >
            <Container>{adminMenu}</Container>
          </Menu>
        )}

        <ScrollToHere paths={contentPaths} />
      </div>

      {children}
    </Media>
  );
}

function MobileContainer({
  children,
  menuItems,
  notLoggedInMenu,
  userMenu,
  adminMenu,
  showAuth,
  authSection,
}) {
  const [sidebarOpened, setSidebarOpened] = useState(false);

  const handleSidebarHide = () => setSidebarOpened(false);
  const handleToggle = () => setSidebarOpened(true);

  return (
    <Media at="mobile">
      <Sidebar.Pushable>
        <Sidebar
          animation="overlay"
          onHide={handleSidebarHide}
          visible={sidebarOpened}
          as={Menu}
          vertical
          borderless
          secondary
          className="header-menu"
          onClick={handleSidebarHide}
        >
          <Menu.Item icon={{ name: 'arrow left', link: true, size: 'large' }} />
          {menuItems}

          {userMenu && (
            <div className="header-submenu header-user-menu">{userMenu}</div>
          )}

          {adminMenu && (
            <div className="header-submenu header-admin-menu">{adminMenu}</div>
          )}
        </Sidebar>

        <Sidebar.Pusher dimmed={sidebarOpened}>
          <div className="header-content">
            <Menu secondary compact fluid className="header-title">
              <Menu.Item onClick={handleToggle}>
                <Icon link name="sidebar" size="large" />
              </Menu.Item>
              {showAuth && (
                <Menu.Menu position="right" style={{ paddingRight: '8px' }}>
                  {authSection}
                </Menu.Menu>
              )}
            </Menu>

            <ResponsiveHeader />
            {notLoggedInMenu && (
              <Menu
                stackable
                borderless
                className="header-submenu header-not-logged-menu"
                style={{ marginBottom: '1em' }}
              >
                <Container>{notLoggedInMenu}</Container>
              </Menu>
            )}
          </div>

          {children}
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </Media>
  );
}

export default function ResponsiveContainer({
  children,
  onLogin,
  onLogout,
  onRegister,
  showLoginRegister,
  user,
  displaySettings,
  ...props
}) {
  const authSection = (
    <AuthSection
      onLogin={onLogin}
      onLogout={onLogout}
      onRegister={onRegister}
      showLoginRegister={showLoginRegister}
      user={user}
      displaySettings={displaySettings}
    />
  );

  return (
    <MediaContextProvider>
      <DesktopContainer authSection={authSection} {...props}>
        {children}
      </DesktopContainer>
      <TabletContainer authSection={authSection} {...props}>
        {children}
      </TabletContainer>
      <MobileContainer authSection={authSection} {...props}>
        {children}
      </MobileContainer>
    </MediaContextProvider>
  );
}
