import { useMemo, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { Helmet } from 'react-helmet';

import {
  Container,
  Divider,
  Grid,
  Header,
  Segment,
  Sticky,
} from 'semantic-ui-react';

import { DateFilter } from '@zerowaste/components';
import DailyStats from './dashboard/DailyStats';
import AvailabilityPieChart from './dashboard/AvailabilityPieChart';
import ImportStats from './dashboard/ImportStats';
import ExportStats from './dashboard/ExportStats';
import TransactionStats, {
  TotalTransactions,
} from './dashboard/TransactionStats';
import VisitorStats from './dashboard/VisitorsStats';
import RepairmenStatistics from './dashboard/RepairmenStatistics';
import DeliveryStats from './dashboard/DeliveryStats';
import KdeyDropdown from './KdeyDropdown';

import dayjs from 'dayjs';
import 'dayjs/locale/el';
import axios from 'axios';
dayjs.locale('el');

function ReuseDashboard() {
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);

  const [kdey, setKdey] = useState();

  const handleKdeySelect = (value) => {
    if (value === '') {
      setKdey(undefined);
    } else {
      setKdey(value);
    }
  };

  const dateParams = useMemo(() => {
    return {
      date_after: dateFrom ? dayjs(dateFrom).format('YYYY-MM-DD') : undefined,
      date_before: dateTo ? dayjs(dateTo).format('YYYY-MM-DD') : undefined,
    };
  }, [dateFrom, dateTo]);

  const { data: dailyCollections } = useQuery(
    ['daily_visits', dateParams, kdey],
    () =>
      axios
        .get('/api/history/daily/', {
          params: { ...dateParams, recycle_center: kdey },
        })
        .then(({ data }) =>
          // convert to milliseconds
          data.map((d) => ({
            ...d,
            date_visited: dayjs(d.date_visited).valueOf(),
          }))
        )
  );

  const contextRef = useRef();
  const pageTitle = 'Επισκόπηση στατιστικών χρήσης';

  return (
    <Container>
      <Helmet title={pageTitle} />
      <Header
        as="h1"
        textAlign="center"
        className="colored atmgreen"
        content={pageTitle}
      />
      <Divider className="orange" />
      <Divider hidden />

      <Sticky context={contextRef}>
        <Segment
          raised
          style={{
            display: 'flex',
            alignItems: 'center',
            margin: 0,
            padding: 0,
            paddingRight: '1em',
            justifyContent: 'space-between',
          }}
        >
          <DateFilter
            dateFrom={dateFrom}
            setDateFrom={setDateFrom}
            dateTo={dateTo}
            setDateTo={setDateTo}
            basic={true}
          />
          <KdeyDropdown kdey={kdey} onSelect={handleKdeySelect} />
        </Segment>
      </Sticky>

      <Divider hidden />

      <div ref={contextRef}>
        <Grid columns={1} stretched>
          <Grid.Column>
            <TransactionStats
              params={{ recycle_center: kdey, ...dateParams }}
            />
          </Grid.Column>
          <Grid.Column>
            <Segment basic color="blue">
              <Header
                size="large"
                textAlign="center"
                content="Επισκέψεις πολιτών σε ΚΔΕΥ"
              />
              <Segment raised>
                <DailyStats
                  data={dailyCollections}
                  dateFrom={dateFrom}
                  dateTo={dateTo}
                  dateDataField="date_visited"
                  lines={[
                    {
                      dataKey: 'collected_amount',
                      name: 'Παραλαβές (τεμάχια)',
                      stroke: 'green',
                    },
                    {
                      dataKey: 'delivered_amount',
                      name: 'Παραδόσεις (τεμάχια)',
                      stroke: 'brown',
                    },
                  ]}
                />
              </Segment>
              <VisitorStats
                raised
                params={{ recycle_center: kdey, ...dateParams }}
              />
            </Segment>
          </Grid.Column>

          <Grid.Column>
            <Segment basic color="red">
              <Grid stackable stretched>
                <Grid.Column>
                  <Header
                    size="large"
                    textAlign="center"
                    content="Διαθεσιμότητα αποθήκης"
                  />
                  <Segment raised>
                    <AvailabilityPieChart params={{ recycle_center: kdey }} />
                  </Segment>
                </Grid.Column>
              </Grid>
            </Segment>
          </Grid.Column>

          <Grid.Column>
            <Segment basic color="orange">
              <Grid stackable stretched>
                <Grid.Column width={8}>
                  <Header
                    size="large"
                    textAlign="center"
                    content="Συνολική κίνηση"
                  />
                  <Segment raised>
                    <TotalTransactions
                      params={{ recycle_center: kdey, ...dateParams }}
                    />
                  </Segment>
                </Grid.Column>
                <Grid.Column width={8}>
                  <Header
                    size="large"
                    textAlign="center"
                    content="Παραδόσεις αποθήκης"
                  />
                  <Segment raised>
                    <DeliveryStats
                      params={{ recycle_center: kdey, ...dateParams }}
                    />
                  </Segment>
                </Grid.Column>
              </Grid>
            </Segment>
          </Grid.Column>

          <Grid.Column>
            <Segment basic color="yellow">
              <Grid stackable>
                <Grid.Column width={8}>
                  <Header
                    size="large"
                    textAlign="center"
                    content="Συνολικές παραλαβές"
                  />
                  <Segment raised>
                    <ImportStats
                      params={{ recycle_center: kdey, ...dateParams }}
                    />
                  </Segment>
                </Grid.Column>
                <Grid.Column width={8}>
                  <Header
                    size="large"
                    textAlign="center"
                    content="Συνολικές παραδόσεις"
                  />
                  <Segment raised>
                    <ExportStats
                      params={{ recycle_center: kdey, ...dateParams }}
                    />
                  </Segment>
                </Grid.Column>
              </Grid>
            </Segment>
          </Grid.Column>

          <Grid.Column>
            <Segment basic color="purple">
              <Header size="large" textAlign="center" content="Επισκευές" />
              <RepairmenStatistics params={dateParams} />
            </Segment>
          </Grid.Column>
        </Grid>
      </div>

      <Divider section hidden />
    </Container>
  );
}

export default ReuseDashboard;
