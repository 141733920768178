import { Fragment, useMemo, useRef, useState } from 'react';
import {
  Route,
  Redirect,
  useParams,
  useRouteMatch,
  useHistory,
} from 'react-router-dom';
import { Helmet } from 'react-helmet';

import {
  Container,
  Message,
  Table,
  Header,
  Grid,
  Divider,
  Button,
  Segment,
  Sticky,
  Ref,
  Modal,
  List,
  Loader,
  Popup,
  Icon,
} from 'semantic-ui-react';

import { OrientedPagination, DateFilter } from '@zerowaste/components';
import KdeyDropdown from './KdeyDropdown';

import useProfile from '../queries/useProfile';
import {
  useHistoryDetails,
  useHistoryLocation,
  useTransactionHistory,
  useTransactionHistoryExport,
} from '../queries/transactionHistory';
import { useMaterialItems } from '../queries/materialItems';

import SearchForm from './SearchForm';

import dayjs from 'dayjs';
import 'dayjs/locale/el';

const EXTRACTION_TYPES = {
  RECYCLE: 'Ανακύκλωση',
  REUSE: 'Επαναχρησιμοποίηση',
  DISPOSE: 'Ταφή',
};

const TRANSACTION_TYPES = {
  DELIVERY: 'Παράδοση',
  COLLECTION: 'Παραλαβή',
  INVENTORY: 'Απογραφή',
};

function HistoryDetail({ isParticipant, onClose }) {
  const { historyId } = useParams();

  const historyDetails = useHistoryDetails({ id: historyId });
  const {
    transaction_type,
    amount,
    delivery_note_number,
    user_text,
    kilos,
    points,
    extraction_type,
    municipality_visit,
    recycle_center_movement,
    material,
    recycle_center,
    created,
    participant,
  } = historyDetails.data || {};

  const extraction_label = EXTRACTION_TYPES[extraction_type] || '-';

  let name = TRANSACTION_TYPES[transaction_type], showPerson = false;
  if (isParticipant) {
    if (transaction_type === 'DELIVERY') {
      name = TRANSACTION_TYPES['COLLECTION'];
    } else if (transaction_type === 'COLLECTION') {
      name = TRANSACTION_TYPES['DELIVERY'];
    }

    showPerson = true; // TODO maybe hide this, but need to change layout to show points
    // make it bigger
    name =`${name} υλικού`;
  } else if (recycle_center_movement) {
    name = `Μεταφορά ${amount > 0 ? 'από' : 'προς'} άλλο ΚΔΕΥ`;
  } else if (transaction_type === 'COLLECTION') {
    if (municipality_visit) {
      name = `${name} από υπηρεσίες Δήμου`;
    } else if (user_text) {
      name = `${name} από πολίτη`;
      showPerson = true;
    } else {
      // make it bigger
      name =`${name} υλικού`;
    }
  } else {
    // COLLECTION or INVENTORY
    if (extraction_type) {
      name = `${extraction_label} υλικού`;
    } else {
      // make it bigger
      name =`${name} υλικού`;
    }
    showPerson = extraction_type === 'REUSE';
  }

  const materialItems = useMaterialItems({ referenced_in: historyId, page: 'all' });
  // it is guaranteed to be unique if any (could change in the future)
  const materialItem = materialItems.data?.[0];

  const displayAmount = transaction_type === 'INVENTORY' ? amount : Math.abs(amount)

  return (
    <Modal size="small" open onClose={onClose} dimmer="inverted">
      <Modal.Header>Λεπτομέρειες κίνησης</Modal.Header>
      <Modal.Content>
        {historyDetails.isLoading ? (
          <Loader />
        ) : (
          <>
            <Header textAlign="center">{name}</Header>
            <Segment
              padded={false}
              secondary
              style={{ color: 'black', maxWidth: '450px', margin: 'auto' }}
            >
              <List
                className="material-description full-width transparent"
                divided
                relaxed="very"
              >
                <List.Item>
                  <List.Header>ΚΔΕΥ:</List.Header>
                  <List.Content>{recycle_center?.name || '-'}</List.Content>
                </List.Item>
                {transaction_type === 'DELIVERY' && user_text && (
                  <List.Item>
                    <List.Header>Αριθμός δελτίου παράδοσης:</List.Header>
                    <List.Content>{delivery_note_number || '-'}</List.Content>
                  </List.Item>
                )}
                <List.Item>
                  <List.Header>Ημερομηνία</List.Header>
                  <List.Content>
                    {new Date(created).toLocaleString('el')}
                  </List.Content>
                </List.Item>
              </List>
            </Segment>
            <Header textAlign="center">
              {materialItem ? 'Μοναδικό υλικό' : 'Υλικό'}
            </Header>
            <Segment
              padded={false}
              secondary
              style={{ color: 'black', maxWidth: '450px', margin: 'auto' }}
            >
              <List
                className="material-description full-width transparent"
                divided
                relaxed="very"
              >
                {material && (
                  <>
                    <List.Item>
                      <List.Header>Barcode:</List.Header>
                      <List.Content>
                        {(materialItem
                          ? materialItem.barcode
                          : material.barcode) || '-'}
                      </List.Content>
                    </List.Item>
                    <List.Item>
                      <List.Header>Όνομα:</List.Header>
                      <List.Content>
                        {material.display_name || '-'}
                        {materialItem && ` - ${materialItem.description}`}
                      </List.Content>
                    </List.Item>
                  </>
                )}
                <List.Item>
                  <List.Header>Ποσότητα:</List.Header>
                  <List.Content>{displayAmount || '-'}</List.Content>
                </List.Item>
                {transaction_type === 'DELIVERY' && (
                  <List.Item>
                    <List.Header>Κιλά:</List.Header>
                    <List.Content>{kilos ?? '-'}</List.Content>
                  </List.Item>
                )}
              </List>
            </Segment>
            {showPerson && (
              <>
                <Header textAlign="center">Πολίτης</Header>
                <Segment
                  padded={false}
                  secondary
                  style={{
                    color: 'black',
                    maxWidth: '450px',
                    margin: 'auto',
                  }}
                >
                  <List
                    className="material-description full-width transparent"
                    divided
                    relaxed="very"
                  >
                    <List.Item>
                      <List.Header>Όνομα:</List.Header>
                      <List.Content>
                        {participant?.full_name || user_text || '-'}
                      </List.Content>
                    </List.Item>
                    {participant && (
                      <List.Item>
                        <List.Header>Email:</List.Header>
                        <List.Content>{participant.email}</List.Content>
                      </List.Item>
                    )}
                    <List.Item>
                      <List.Header>Εγγεγραμμένος:</List.Header>
                      <List.Content>{participant ? 'Ναι' : 'Όχι'}</List.Content>
                    </List.Item>
                    {participant && (
                      <List.Item>
                        <List.Header>Πόντοι:</List.Header>
                        <List.Content>{points ?? '-'}</List.Content>
                      </List.Item>
                    )}
                  </List>
                </Segment>
              </>
            )}
          </>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose}>Κλείσιμο</Button>
      </Modal.Actions>
    </Modal>
  );
}

function CollectionsTableComputer({
  isStaff,
  isParticipant,
  transactions,
  isFetching,
  onClearFilters,
  openDetails,
}) {
  return (
    <Table
      fixed
      celled
      selectable={!isParticipant}
      className={isParticipant ? '' : 'pointable'}
      style={{ opacity: isFetching ? '0.5' : '1' }}
    >
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Ημερομηνία</Table.HeaderCell>
          <Table.HeaderCell>Λειτουργία</Table.HeaderCell>
          <Table.HeaderCell style={{ pointerEvents: 'none' }}>
            Barcode
          </Table.HeaderCell>
          <Table.HeaderCell colSpan={2}>Υλικό</Table.HeaderCell>
          <Table.HeaderCell textAlign="right">Ποσότητα</Table.HeaderCell>
          {isParticipant ? (
            <Table.HeaderCell textAlign="right">Πόντοι</Table.HeaderCell>
          ) : (
            <Table.HeaderCell colSpan={2}>Χρήστης</Table.HeaderCell>
          )}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {transactions?.results.map((transaction, i) => {
          const {
            id,
            name,
            user,
            displayAmount,
            material,
            material_display_name,
            created,
            points,
          } = transaction;

          return (
            <Table.Row
              key={id}
              onClick={() => {
                !isParticipant && openDetails(id);
              }}
            >
              <Table.Cell>{created}</Table.Cell>
              <Table.Cell>{name}</Table.Cell>
              <Table.Cell>{material?.barcode ?? '-'}</Table.Cell>
              <Table.Cell colSpan={2} singleLine>{material_display_name ?? '-'}</Table.Cell>
              <Table.Cell textAlign="right">{displayAmount || '-'}</Table.Cell>
              {isParticipant ? (
                <Table.Cell textAlign="right">{points}</Table.Cell>
              ) : (
                <Table.Cell colSpan={2} singleLine>{user}</Table.Cell>
              )}
            </Table.Row>
          );
        })}
        {transactions?.count === 0 && (
          <Table.Row textAlign="center">
            <Table.Cell colSpan={isParticipant ? 7 : 8}>
              Δεν εντοπίστηκαν στοιχεία
              {isParticipant
                ? '.'
                : ' που ικανοποιούν τα φίλτρα που έχετε επιλέξει.'}
              {isStaff && (
                <>
                  <Divider hidden fitted />
                  <Button basic onClick={onClearFilters}>
                    Επαναφορά φίλτρων
                  </Button>
                </>
              )}
            </Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
    </Table>
  );
}

function CollectionsTableMobile({
  isStaff,
  isParticipant,
  transactions,
  onClearFilters,
}) {
  return (
    <div>
      <Table unstackable definition fixed>
        <Table.Body>
          {transactions?.results.map(
            (
              {
                name,
                user,
                displayAmount,
                material,
                material_display_name,
                created,
                points,
              },
              index
            ) => {
              return (
                <Fragment key={index}>
                  {index > 0 && (
                    <Table.Row>
                      <Table.Cell
                        style={{ backgroundColor: 'white' }}
                        colSpan={2}
                      />
                    </Table.Row>
                  )}
                  <Table.Row>
                    <Table.Cell>Ημερομηνία</Table.Cell>
                    <Table.Cell>{created}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Λειτουργία</Table.Cell>
                    <Table.Cell>{name}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Ποσότητα</Table.Cell>
                    <Table.Cell>{displayAmount || 'Μη διαθέσιμη ποσότητα'}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Barcode</Table.Cell>
                    <Table.Cell>
                      {material?.barcode ?? 'Μη διαθέσιμο barcode'}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Υλικό</Table.Cell>
                    <Table.Cell singleLine>
                      {material_display_name ?? 'Μη διαθέσιμο υλικό'}
                    </Table.Cell>
                  </Table.Row>
                  {isParticipant ? (
                    <Table.Row>
                      <Table.Cell>Πόντοι</Table.Cell>
                      <Table.Cell>{points}</Table.Cell>
                    </Table.Row>
                  ) : (
                    <Table.Row>
                      <Table.Cell>Χρήστης</Table.Cell>
                      <Table.Cell>{user}</Table.Cell>
                    </Table.Row>
                  )}
                </Fragment>
              );
            }
          )}

          {transactions?.count === 0 && (
            <Table.Row textAlign="center">
              <Table.Cell colSpan={2}>
                Δεν εντοπίστηκαν στοιχεία
                {isParticipant
                  ? '.'
                  : ' που ικανοποιούν τα φίλτρα που έχετε επιλέξει.'}
                {isStaff && (
                  <>
                    <Divider hidden fitted />
                    <Button basic onClick={onClearFilters}>
                      Επαναφορά φίλτρων
                    </Button>
                  </>
                )}
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </div>
  );
}

function CollectionsTable({ transactions, ...props }) {
  if (transactions.isLoading) {
    return <Segment basic placeholder loading />;
  }
  if (transactions.isError) {
    return (
      <Message
        error
        content="Οι πληροφορίες ιστορικού δεν είναι διαθέσιμες αυτή τη στιγμή."
      />
    );
  }

  // prepare some values transforms for display
  const transactionsPage = transactions.data?.results.map(
    ({ created, user_text, ...t }) => {
      created = new Date(created);

      let name = TRANSACTION_TYPES[t.transaction_type];;
      if (props.isParticipant) {
        if (t.transaction_type === 'DELIVERY') {
          name = TRANSACTION_TYPES['COLLECTION'];
        } else if (t.transaction_type === 'COLLECTION') {
          name = TRANSACTION_TYPES['DELIVERY'];
        }
      }

      let iconName;
      if (t.participant) {
        user_text = t.participant.full_name;
        iconName = 'user';
      } else if (!user_text) {
        // special cases
        if (t.recycle_center_movement) {
          iconName = t.amount > 0 ? 'sign-in' : 'sign-out';
          user_text = `Μεταφορά ${t.amount > 0 ? 'από' : 'προς'} ΚΔΕΥ`;
        } else if (t.transaction_type === 'COLLECTION') {
          iconName = 'sign-in';

          if (t.municipality_visit) {
            user_text = 'Παραλαβή από υπηρεσίες Δήμου';
          } else {
            user_text = 'Άγνωστος';
          }
        } else if (t.transaction_type === 'DELIVERY') {
            // delivery to not a person OR recycle center
          user_text = EXTRACTION_TYPES[t.extraction_type] || '-';
          // just put it in the icon
          if (t.extraction_type === 'RECYCLE') {
            iconName = 'recycle';
          } else if (t.extraction_type === 'DISPOSE') {
            iconName = 'trash';
          } else {
            iconName = 'question';
          }
        } else {
          // INVENTORY
          user_text = 'Απογραφή';
          if (t.amount > 0) {
            iconName = 'plus square';
          } else if (t.amount < 0) {
            iconName = 'minus square';
          } else {
            iconName = 'edit';
          }
        }

      } else {
        iconName = 'user secret';
      }

      let user = (
        <span>
          <Icon name={iconName} /> {user_text || '-'}
        </span>
      );

      return {
        ...t,
        created: (
          <Popup trigger={<span>{created.toLocaleDateString('el')}</span>}>
            <Popup.Content>{created.toLocaleTimeString('el')}</Popup.Content>
          </Popup>
        ),
        name,
        user,
        user_text,
        displayAmount: t.transaction_type === 'INVENTORY' ? t.amount : Math.abs(t.amount)
      };
    }
  );

  const transactionsData = transactions.data && {
    ...transactions.data,
    results: transactionsPage,
  };

  return (
    <Grid columns={1}>
      <Grid.Column only="mobile">
        <Ref innerRef={props.stickyPaginationRef}>
          <div style={{ textAlign: 'center' }}>
            <CollectionsTableMobile
              transactions={transactionsData}
              {...props}
            />
          </div>
        </Ref>
      </Grid.Column>
      <Grid.Column only="tablet computer">
        <CollectionsTableComputer
          transactions={transactionsData}
          isFetching={transactions.isPreviousData && transactions.isFetching}
          {...props}
        />
      </Grid.Column>
    </Grid>
  );
}

export function TransactionHistory({ baseUrl }) {
  const history = useHistory();
  const match = useRouteMatch();

  const { page, historyId } = useParams();

  //date filter
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);

  const dateParams = useMemo(() => {
    return {
      created_after: dateFrom
        ? dayjs(dateFrom).format('YYYY-MM-DD')
        : undefined,
      created_before: dateTo ? dayjs(dateTo).format('YYYY-MM-DD') : undefined,
    };
  }, [dateFrom, dateTo]);

  //kdey filter
  const [kdey, setKdey] = useState();

  const [search, setSearch] = useState('');

  const resetPage = () => {
    !hasOnePage && history.replace(`${baseUrl}/page/1`);
  };

  const handleKdeySelect = (value) => {
    if (value === '') {
      setKdey(undefined);
    } else {
      setKdey(value);
    }
    resetPage();
  };

  // pick up auth stuff
  const profile = useProfile();
  const isStaff = profile.data?.is_employee || profile.data?.is_supervisor;
  const isSupervisor = profile.data?.is_supervisor;
  const isParticipant = !!profile.data?.participant;

  const transactionHistory = useTransactionHistory({
    params: {
      page,
      recycle_center: kdey,
      ordering: '-created',
      search,
      ...dateParams,
    },
  });

  const hasOnePage = transactionHistory.data?.total_pages === 1;

  const handleDetailsOpen = (id) => {
    history.push(`${match.url}/entry/${id}`);
  };

  const handleDetailsClose = () => {
    history.push(match.url);
  };

  const handleCodeSearch = (value) => {
    resetPage();
    setSearch(value);
  };

  const handleFilterClear = () => {
    setKdey(null);
    setSearch('');
    setDateFrom();
    setDateTo();
  };

  //pagination
  const showPagination = transactionHistory.data?.total_pages > 1;
  const paginationProps = {
    activePage: page,
    totalPages: transactionHistory.data?.total_pages,
    onPageChange: (e, { activePage }) =>
      history.push({ pathname: `${activePage}`, search }),
    disabled: transactionHistory.data?.total_pages === 1,
    padded: false,
  };
  const stickyPaginationRef = useRef();

  const exportExcel = useTransactionHistoryExport({
    recycle_center: kdey,
    search,
    ...dateParams,
  });

  const handleExportData = () => {
    exportExcel.refetch().then((response) => {
      const file = new File([response.data], 'Ιστορικό κινήσεων.xlsx', {
        type: response.data.type,
      });
      const href = URL.createObjectURL(file);
      window.location = href;
      URL.revokeObjectURL(href);
    });
  };

  // we need this to get the page for the reservation if needed
  const historyLocation = useHistoryLocation(historyId);

  if (!page) {
    if (historyId) {
      // we need to figure out the page of this item.
      if (historyLocation.isLoading || historyLocation.isIdle) {
        // we need to wait a bit the server!
        return <Loader />;
      } else if (historyLocation.isSuccess) {
        return (
          <Redirect
            to={`${baseUrl}/page/${historyLocation.data.page}/entry/${historyId}`}
          />
        );
      }
    }

    // redirect to first page if reservation was not found or not provided
    return <Redirect to={`${baseUrl}/page/1`} />;
  }

  if (
    !transactionHistory.isPreviousData &&
    transactionHistory.data?.total_pages > 1 &&
    !page
  ) {
    return <Redirect to={{ pathname: `${baseUrl}/page/1`, search }} />;
  }

  return (
    <>
      {isParticipant && (
        <Container>
          <Header as="h1" textAlign="center" className="colored atmgreen">
            Ιστορικό
          </Header>
          <Divider className="orange" />
          <Divider hidden />
        </Container>
      )}

      <Container>
        {!isParticipant ? (
          <Grid>
            <Grid.Row>
              {/* computer layout */}
              {(!isStaff || isSupervisor) && (
                <Grid.Column width={8} only="computer tablet">
                  <KdeyDropdown kdey={kdey} onSelect={handleKdeySelect} />
                </Grid.Column>
              )}
              <Grid.Column
                width={!isStaff || isSupervisor ? 8 : 16}
                only="computer tablet"
                textAlign="right"
              >
                <Button
                  content="Εξαγωγή σε Excel"
                  icon="download"
                  onClick={handleExportData}
                  loading={exportExcel.isFetching}
                  disabled={exportExcel.isFetching}
                />
                <Divider hidden />
              </Grid.Column>
              <Grid.Column width={16} only="computer tablet">
                <DateFilter
                  dateFrom={dateFrom}
                  setDateFrom={setDateFrom}
                  dateTo={dateTo}
                  setDateTo={setDateTo}
                  onDateChange={resetPage}
                />
                <Divider hidden />
              </Grid.Column>
              <Grid.Column width={8} only="computer">
                {showPagination && <OrientedPagination {...paginationProps} />}
              </Grid.Column>
              <Grid.Column width={8} only="computer" textAlign="right">
                <SearchForm
                  key={search}
                  value={search}
                  onSearch={handleCodeSearch}
                  loading={transactionHistory.isLoading}
                />
              </Grid.Column>

              {/* tablet layout */}
              <Grid.Column width={14} only="tablet">
                {showPagination && (
                  <OrientedPagination
                    {...paginationProps}
                    boundaryRange={1}
                    siblingRange={0}
                    size="large"
                    pointing={false}
                    secondary={false}
                  />
                )}
              </Grid.Column>
              <Grid.Column width={2} only="tablet">
                <SearchForm
                  key={search}
                  value={search}
                  onSearch={handleCodeSearch}
                  loading={transactionHistory.isLoading}
                  mobile
                />
              </Grid.Column>

              {/* mobile layout */}
              {(!isStaff || isSupervisor) && (
                <Grid.Column width={9} only="mobile">
                  <KdeyDropdown kdey={kdey} onSelect={handleKdeySelect} />
                </Grid.Column>
              )}
              <Grid.Column
                width={!isStaff || isSupervisor ? 7 : 16}
                only="mobile"
                textAlign="right"
              >
                <Button
                  content="Εξαγωγή σε Excel"
                  icon="download"
                  size="small"
                  onClick={handleExportData}
                  loading={exportExcel.isFetching}
                  disabled={exportExcel.isFetching}
                />
                <Divider hidden />
              </Grid.Column>
              <Grid.Column width={16} only="mobile">
                <SearchForm
                  key={search}
                  value={search}
                  onSearch={handleCodeSearch}
                  loading={transactionHistory.isLoading}
                  mobile
                />
                <Divider hidden />
              </Grid.Column>
              <Grid.Column width={16} only="mobile">
                <DateFilter
                  dateFrom={dateFrom}
                  setDateFrom={setDateFrom}
                  dateTo={dateTo}
                  setDateTo={setDateTo}
                  onDateChange={resetPage}
                />
                <Divider hidden />
              </Grid.Column>
              {showPagination && (
                <Grid.Column width={16} only="mobile">
                  <Sticky context={stickyPaginationRef}>
                    <OrientedPagination
                      {...paginationProps}
                      boundaryRange={1}
                      siblingRange={0}
                      size="large"
                      pointing={false}
                      secondary={false}
                    />
                  </Sticky>
                </Grid.Column>
              )}
            </Grid.Row>
          </Grid>
        ) : (
          <Grid>
            <Grid.Column width={16} only="computer tablet">
              {showPagination && <OrientedPagination {...paginationProps} />}
            </Grid.Column>
            {showPagination && (
              <Grid.Column width={16} only="mobile">
                <Sticky context={stickyPaginationRef}>
                  <OrientedPagination
                    {...paginationProps}
                    boundaryRange={1}
                    siblingRange={0}
                    size="large"
                    pointing={false}
                    secondary={false}
                  />
                </Sticky>
              </Grid.Column>
            )}
          </Grid>
        )}

        <CollectionsTable
          transactions={transactionHistory}
          isStaff={isStaff} //toDo: see which account type we need
          isSupervisor={isSupervisor}
          isParticipant={isParticipant}
          stickyPaginationRef={stickyPaginationRef}
          onClearFilters={handleFilterClear}
          openDetails={handleDetailsOpen}
        />

        {isParticipant && <Divider hidden />}
      </Container>
      <Route path={`${match.url}/entry/:historyId`}>
        <HistoryDetail isParticipant={isParticipant} onClose={handleDetailsClose} />
      </Route>
    </>
  );
}

export default function AdminHistory() {
  const match = useRouteMatch();

  const pageTitle = 'Ιστορικό κινήσεων';
  return (
    <Container>
      <Helmet title={pageTitle} />
      <Header
        as="h1"
        textAlign="center"
        className="colored atmgreen"
        content={pageTitle}
      />
      <Divider className="orange" />
      <Divider section hidden />

      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Route path={[
              `${match.path}/page/:page(\\d+)`,
              `${match.path}/:historyId(\\d+)`,
              match.path,
            ]}>
              <TransactionHistory baseUrl={match.path} />
            </Route>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Divider hidden />
    </Container>
  );
}
