import { useQuery } from 'react-query';

import axios from 'axios';
import orderBy from 'lodash/orderBy';

export const useMaterialsCollected = (params = {}, options = {}) => {
  return useQuery(
    ['materials_collected', params],
    () =>
      axios
        .get(`/api/history/collections/categories/`, {
          params,
        })
        .then(({ data }) => orderBy(data, 'amount')),
    {
      refetchOnWindowFocus: false,
      ...options,
    }
  );
};

export const useMaterialsDelivered = (params = {}, options = {}) => {
  return useQuery(
    ['materials_delivered', params],
    () =>
      axios
        .get(`/api/history/deliveries/categories/`, {
          params,
        })
        .then(({ data }) => orderBy(data, 'amount')),
    {
      refetchOnWindowFocus: false,
      ...options,
    }
  );
};

export const useTransactionStats = (params = {}, options = {}) => {
  return useQuery(
    ['transaction_stats', params],
    () =>
      axios
        .get(`/api/history/stats/`, {
          params,
        })
        .then(({ data }) => data),
    {
      refetchOnWindowFocus: false,
      ...options,
    }
  );
};

export const useVisitorsStats = (params, options) =>
  useQuery(
    ['visitor_counts', params],
    () =>
      axios
        .get('/api/history/visitors_stats/', {
          params,
        })
        .then(({ data }) => data),
    {
      refetchOnWindowFocus: false,
      ...options,
    }
  );

export const useRepairStatistics = (params, options) =>
  useQuery(
    ['repair_stats', params],
    () =>
      axios.get('/api/repairmen/stats/', { params }).then(({ data }) => data),
    {
      refetchOnWindowFocus: false,
      ...options,
    }
  );
