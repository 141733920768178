import { Cell, Pie, PieChart, Tooltip } from 'recharts';

const COLORS = [
  '#218380',
  '#d81159',
  '#fbb13c',
  '#73d2de',
  '#255c99',
  '#b3001b',
  '#262626',
  '#7ea3cc',
  '#ccad8f',
  '#8f2d56',
  '#ff595e',
  '#ffca3a',
  '#8ac926',
  '#1982c4',
  '#6a4c93',
];

export default function ReusePieChart({ data }) {
  const totalAmount = data?.reduce((prev, curr) => prev + curr.amount, 0);

  return (
    <PieChart width={400} height={400} style={{ margin: 'auto' }}>
      <Pie data={data} dataKey="amount" startAngle={-90} endAngle={270}>
        {data?.map(({ id }, index) => (
          <Cell key={index} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
      <Tooltip
        formatter={(value) =>
          `${value} τεμαχια - ${Math.round((value / totalAmount) * 100) || 1} %`
        }
      />
    </PieChart>
  );
}
