import { useQuery } from 'react-query';

import axios from 'axios';

export const useDeliveryStatistics = (params = {}, options = {}) =>
  useQuery(
    ['delivery_statistics', params],
    () =>
      axios
        .get(`/api/history/stats/delivered/`, {
          params,
        })
        .then(({ data }) => data),
    {
      refetchOnWindowFocus: false,
      ...options,
    }
  );
