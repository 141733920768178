import React, { Fragment, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
  NavLink,
  Route,
  Switch,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router-dom';

import {
  Container,
  Divider,
  Grid,
  Header,
  List,
  Loader,
  Menu,
  Message,
  Rail,
  Segment,
} from 'semantic-ui-react';

import { useRepairmen } from '../queries/repairmen';

import { SearchFilterInput, useDebounce } from '@zerowaste/components';

import RepairmanProfileForm from './repairmen/RepairmanProfileForm';
import RepairmanOffersTable from './repairmen/RepairmanOffersTable';

export function RepairmanList({
  as: Root = Fragment,
  listHeight,
  parentPageTitle,
  parentMatch,
  pathSuffix = '',
  filter,
  ...props
}) {
  const match = useRouteMatch();
  const showDisabled = match.path.endsWith('disabled');

  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 300);

  const repairmen = useRepairmen({
    is_verified: showDisabled ? false : true,
    search: debouncedSearch,
    page: 'all',
  });
  const noRepairmen = !debouncedSearch && repairmen.data?.length === 0;

  const searchInput = (
    <SearchFilterInput
      loading={repairmen.isFetching}
      disabled={!debouncedSearch && repairmen.data?.length === 0}
      value={search}
      setValue={setSearch}
    />
  );

  return (
    <Root {...props}>
      {/* override title only if disabled are selected */}
      {showDisabled && <Helmet title="Διαχείριση ανενεργών επισκευαστών" />}

      {!filter && (
        <>
          <Menu secondary pointing size="huge">
            <Menu.Item
              as={NavLink}
              to={parentMatch.url}
              exact={showDisabled}
              content="Ενεργοί"
            />
            <Menu.Item
              as={NavLink}
              to={`${parentMatch.url}/disabled`}
              content="Ανενεργοί"
            />
          </Menu>
          {searchInput}
        </>
      )}

      <div
        style={{
          marginTop: '16px',
          position: 'relative',
          height: listHeight,
          overflow: listHeight ? 'auto' : undefined,
        }}
      >
        {repairmen.isLoading && <Loader active />}
        {repairmen.isError && (
          <Message
            error
            header="Οι πληροφορίες για τoυς επισκευαστές δεν είναι διαθέσιμες."
          />
        )}
        {noRepairmen && (
          <Message
            header={`Δεν υπάρχουν ${
              showDisabled ? 'ανενεργοί' : 'ενεργοί'
            } επισκευαστές.`}
          />
        )}

        <List selection divided className="narrow-list" size="large">
          {repairmen.data?.map(({ id, full_name, address }) => (
            <React.Fragment key={id}>
              {/* Add repairman name to page title, by using a route-based head rendering method */}
              <Route
                path={`${parentMatch.path}/${
                  showDisabled ? 'disabled/' : ''
                }${id}`}
              >
                <Helmet title={`${full_name} | ${parentPageTitle}`} />
              </Route>
              <Route
                path={`${parentMatch.path}/${
                  showDisabled ? 'disabled/' : ''
                }${id}/offers`}
              >
                <Helmet
                  title={`Προσφορές | ${full_name} | ${parentPageTitle}`}
                />
              </Route>

              <List.Item as={NavLink} to={`${match.url}/${id}${pathSuffix}`}>
                <List.Content>
                  <List.Header>{full_name}</List.Header>
                  <List.Description>{address}</List.Description>
                </List.Content>
              </List.Item>
            </React.Fragment>
          ))}
        </List>
      </div>
    </Root>
  );
}

function RepairmanView({ setFinishedMessage }) {
  const match = useRouteMatch();
  const { repairmanId } = useParams();

  return (
    <>
      <Menu secondary pointing size="huge">
        <Menu.Item name="details" as={NavLink} to={match.url} exact>
          Στοιχεία επισκευαστή
        </Menu.Item>
        <Menu.Item name="offers" as={NavLink} to={`${match.url}/offers`}>
          Προσφορές
        </Menu.Item>
      </Menu>
      <Switch>
        <Route path={match.path} exact>
          <RepairmanProfileForm
            repairmanId={repairmanId}
            setFinishedMessage={setFinishedMessage}
          />
        </Route>
        <Route path={`${match.path}/offers`}>
          <RepairmanOffersTable repairmanId={repairmanId} />
        </Route>
      </Switch>
    </>
  );
}

export default function AdminRepairmen() {
  const match = useRouteMatch();
  const location = useLocation();

  const pathSuffix = location.pathname.endsWith('/offers')
    ? '/offers'
    : undefined;

  const [finishedMessage, setFinishedMessage] = useState();
  useEffect(() => {
    if (finishedMessage) {
      const handle = setTimeout(() => setFinishedMessage(), 5000);
      return () => clearTimeout(handle);
    }
  }, [finishedMessage]);

  const pageTitle = 'Διαχείριση επισκευαστών';
  return (
    <Container>
      <Helmet title={pageTitle} />
      <Header
        as="h1"
        textAlign="center"
        className="colored atmgreen"
        content={pageTitle}
      />
      <Divider className="orange" />

      <Grid centered>
        <Grid.Row>
          <Grid.Column width={4}>
            <Route path={[`${match.path}/disabled`, match.path]}>
              <RepairmanList
                listHeight="50vh"
                parentPageTitle={pageTitle}
                parentMatch={match}
                pathSuffix={pathSuffix}
              />
            </Route>
          </Grid.Column>
          <Grid.Column width={12}>
            <Switch>
              <Route
                path={[
                  `${match.path}/disabled/:repairmanId(\\d+)`,
                  `${match.path}/:repairmanId(\\d+)`,
                ]}
              >
                <RepairmanView setFinishedMessage={setFinishedMessage} />
              </Route>
              <Route>
                <Divider hidden />
              </Route>
            </Switch>

            <Segment basic>
              {finishedMessage && (
                <Rail
                  internal
                  style={{ width: '100%' }}
                  as={Container}
                  textAlign="center"
                >
                  <Message
                    compact
                    floating
                    header={finishedMessage}
                    onMouseEnter={(e) =>
                      setFinishedMessage((f) => f && `${f} `)
                    }
                  />
                </Rail>
              )}
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Divider hidden section />
    </Container>
  );
}
