import { Fragment, useState } from 'react';
import { useQueryClient } from 'react-query';
import { Helmet } from 'react-helmet';

import {
  Route,
  Switch,
  useRouteMatch,
  NavLink,
  useParams,
  Redirect,
  Link,
  useHistory,
} from 'react-router-dom';

import {
  Button,
  Card,
  Container,
  Divider,
  Grid,
  Header,
  Icon,
  Image,
  Label,
  List,
  Loader,
  Menu,
  Message,
  Modal,
  Popup,
  Segment,
} from 'semantic-ui-react';

import useProfile from '../queries/useProfile';
import { useCategories } from '../queries/categories';
import { useMaterial, useMaterials } from '../queries/materials';
import { useSubcategories } from '../queries/subcategories';
import { useOffers } from '../queries/offers';
import { useMaterialItem, useMaterialItems } from '../queries/materialItems';
import { useMakeReservation } from '../queries/reservations';

import { ReservationForm } from './ParticipantApplicationForm';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Thumbs, FreeMode } from 'swiper';
import 'swiper/swiper.min.css';
import 'swiper/modules/navigation/navigation.min.css';
import 'swiper/modules/thumbs/thumbs.min.css';
import 'swiper/modules/free-mode/free-mode.min.css';

import './styles/SwiperStyles.css';

import groupBy from 'lodash/groupBy';

function ThumbSwiper({ images, style }) {
  const [thumbs, setThumbs] = useState(null);

  const width = '300px';
  const height = '300px';

  const thumbWidth = '30px';
  const thumbHeight = '30px';
  return (
    <div style={{ ...style }}>
      <Swiper
        className="thumb-swiper"
        modules={[Thumbs, Navigation]}
        thumbs={{ swiper: thumbs }}
        navigation
        style={{ height: '100%', maxWidth: width, maxHeight: height }}
      >
        {images.map(({ url, id }) => (
          <SwiperSlide key={id}>
            <Image style={{ width, height }} src={url} wrapped />
          </SwiperSlide>
        ))}
      </Swiper>
      {images.length > 1 && (
        <>
          <Divider hidden />
          <Swiper
            className="thumb-swiper"
            onSwiper={setThumbs}
            slidesPerView={images.length}
            freeMode
            watchSlidesProgress
            modules={[Navigation, Thumbs, FreeMode]}
            spaceBetween={0}
            style={{ maxWidth: '250px' }}
          >
            {images.map(({ url, id }) => (
              <SwiperSlide key={id} style={{ width: thumbWidth }}>
                <Image
                  style={{ width: thumbWidth, height: thumbHeight }}
                  src={url}
                  wrapped
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </>
      )}
    </div>
  );
}

export function OfferCard({ title, description, meta, images, to, ribbonText }) {
  return (
    <Card centered as={to ? Link : undefined} to={to}>
      {images?.length && <Image src={images[0].url} />}
      <Card.Content style={{ backgroundColor: '#77b0bd75' }}>
        <Card.Header>{title}</Card.Header>
        <Card.Meta>{meta}</Card.Meta>
        {ribbonText && (
          <Label ribbon color="teal" size="large">
            {ribbonText}
          </Label>
        )}
      </Card.Content>
      {description && (
        <Card.Content>
          <Card.Description>{description}</Card.Description>
        </Card.Content>
      )}
    </Card>
  );
}

function RepairmanPopup({ repairman }) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Popup
      onClose={() => setIsOpen(false)}
      wide
      on="click"
      open={isOpen}
      trigger={
        <Header
          onClick={() => setIsOpen(true)}
          as="h5"
          style={{
            textDecoration: 'underline',
            cursor: 'pointer',
          }}
        >
          {repairman.full_name}
        </Header>
      }
    >
      <Popup.Content>
        <List
          className="material-description full-width"
          divided
          relaxed="very"
        >
          <List.Item>
            <List.Header>Ονοματεπώνυμο:</List.Header>
            <List.Content floated="right">{repairman.full_name}</List.Content>
          </List.Item>
          <List.Item>
            <List.Header>Τηλέφωνο:</List.Header>
            <List.Content floated="right">{repairman?.telephone}</List.Content>
          </List.Item>
          <List.Item>
            <List.Header>Επάγγελμα:</List.Header>
            <List.Content floated="right">{repairman.profession}</List.Content>
          </List.Item>
          <List.Item>
            <List.Header>Διεύθυνση:</List.Header>
            <List.Content floated="right">{repairman.address}</List.Content>
          </List.Item>
          <List.Item>
            <List.Header>Email:</List.Header>
            <List.Content floated="right">{repairman.email}</List.Content>
          </List.Item>
        </List>
        <Button
          style={{ marginTop: '8px' }}
          onClick={() => {
            setIsOpen(false);
          }}
          floated="right"
        >
          Κλείσιμο
        </Button>
      </Popup.Content>
    </Popup>
  );
}

export function ImageSlider({ images, noMaxHeight, height }) {
  return (
    <>
      <Swiper modules={[Navigation]} navigation style={{ height: '100%' }}>
        {images.map(({ url, id }) => (
          <SwiperSlide key={id}>
            <Image
              style={{ maxHeight: noMaxHeight ? 'unset' : '250px', height }}
              src={url}
              wrapped
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}

export function MaterialCard({
  images,
  title,
  meta,
  button,
  height,
  complete,
}) {
  return (
    <Card centered className="custom-card">
      <Card.Content className="blue-bg big-height">
        <ImageSlider images={images} height={height} />
      </Card.Content>
      <Card.Content className="custom-content">
        <Card.Header className="text-ellipsis" textAlign="center">
          {title}
        </Card.Header>
        <Card.Meta>{meta}</Card.Meta>
      </Card.Content>
      <Card.Content textAlign="center" className="more-bt">
        {button}
        {complete === false && (
          <Message
            compact
            warning
            visible
            content="Μη ολοκληρωμένη καταγραφή!"
          />
        )}
      </Card.Content>
    </Card>
  );
}

function PickupInfo({ onClose }) {
  return (
    <Modal size="small" open onClose={onClose} dimmer="inverted">
      <Segment basic>
        <Message size="large">
          <Message.Content>
            Για την παραλαβή των υλικών που επιλέξατε θα πρέπει να επισκεφθείτε
            το Κέντρο Δημιουργικής Επαναχρησιμοποίησης Υλικών (ΚΔΕΥ) που σας
            βολεύει. Στοιχεία επικοινωνίας με το ΚΔΕΥ μπορείτε να βρείτε{' '}
            <Link to="/questions#contact">εδώ</Link>
          </Message.Content>
        </Message>
      </Segment>
      <Modal.Actions style={{ textAlign: 'left' }}>
        <Button content="Κλείσιμο" onClick={onClose} />
      </Modal.Actions>
    </Modal>
  );
}

export function MaterialItemList({
  submitOrder,
  onSelect,
  selectedItem,
  materialItems,
  showKdeys,
  disabledReserved,
  loading=false,
}) {
  const match = useRouteMatch();
  const history = useHistory();

  const handleMaterialItemSelect = (id) => {
    history.push(`${match.url}/item/${id}`);
  };

  const recycleCenterItems = groupBy(materialItems, 'recycle_center');

  return (
    <Segment
      basic
      loading={loading}
      placeholder={loading}
    >
      {!submitOrder && (
        <Header textAlign="center">Διαθέσιμα μοναδικά υλικά</Header>
      )}
      {Object.keys(recycleCenterItems).map((recycle_center_name) => (
        <Fragment key={recycle_center_name}>
          {(submitOrder || showKdeys) && (
            <Header textAlign="center" as="h3">
              {recycle_center_name}
            </Header>
          )}
          <Divider />
          <div className={submitOrder ? 'three-cols-grid' : 'two-cols-grid'}>
            {recycleCenterItems[recycle_center_name].map((item) => (
              <MaterialCard
                key={item.id}
                images={item.images}
                title={item.description}
                meta={item.material_name}
                complete={item.is_complete}
                button={
                  <Button
                    positive={selectedItem === item.id}
                    primary={submitOrder}
                    onClick={() => {
                      submitOrder
                        ? handleMaterialItemSelect(item.id)
                        : selectedItem === item.id
                        ? onSelect(null)
                        : onSelect(item);
                    }}
                    disabled={disabledReserved && item.reservation}
                  >
                    {submitOrder
                      ? 'Λεπτομέρειες'
                      : selectedItem === item.id
                      ? 'Επιλέχθηκε'
                      : disabledReserved && item.reservation
                      ? 'Κρατημένο'
                      : 'Επιλογή'}
                  </Button>
                }
              />
            ))}
          </div>
        </Fragment>
      ))}
    </Segment>
  );
}

function MaterialItemInfo() {
  const profile = useProfile();
  const isNotParticipant = profile.data && !profile.data?.participant;

  const queryClient = useQueryClient();

  const { itemId } = useParams();
  const match = useRouteMatch();
  const history = useHistory();

  const materialItem = useMaterialItem(itemId);
  const material = useMaterial(materialItem.data?.material);
  const offers = useOffers({ material_item: itemId });

  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  const makeReservation = useMakeReservation();

  const handleConfirmOpen = () => {
    setIsConfirmOpen(true);
  };
  const handleConfirmClose = () => {
    makeReservation.reset();
    setIsConfirmOpen(false);
  };
  const handleFormSubmit = () => {
    makeReservation.mutate(
      {
        item: itemId,
        participant: profile.data?.participant?.id,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries('materialItems');
        },
      }
    );
  };
  const handleAnonymousFormClose = () => {
    history.push(match.url);
  };
  return (
    <Segment
      loading={materialItem.isLoading}
      placeholder={materialItem.isLoading}
    >
      <div className="material-container">
        {materialItem.data && <ThumbSwiper images={materialItem.data.images} />}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            alignItems: 'center',
            gap: '16px',
          }}
        >
          <List className="material-description" divided relaxed="very">
            <List.Item>
              <List.Header>Περιγραφή:</List.Header>
              <List.Content floated="right">
                {materialItem.data?.material_name}{' '}
                {materialItem.data?.description}
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Header>ΚΔΕΥ:</List.Header>
              <List.Content>{materialItem.data?.recycle_center}</List.Content>
            </List.Item>
            <List.Item>
              <List.Header>Πόντοι ανά τεμάχιο(παράδοση):</List.Header>
              <List.Content>
                {material.data?.subcategory.turn_in_points}
              </List.Content>
            </List.Item>
            <List.Item
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <List.Header>Πόντοι ανά τεμάχιο(παραλαβή):</List.Header>
              <List.Content>
                {material.data?.subcategory.pick_up_points}
              </List.Content>
            </List.Item>
          </List>
          <Button
            as={profile.data ? Button : NavLink}
            to={`${match.url}/application-form`}
            primary
            onClick={() => {
              profile.data && handleConfirmOpen();
            }}
            disabled={!!materialItem.data?.reservation || isNotParticipant}
            content={!!materialItem.data?.reservation ? 'Κρατημένο' : 'Το θέλω'}
          />
        </div>
      </div>
      {offers.data?.length > 0 && (
        <Segment basic>
          <Header textAlign="center" as="h2">
            Διαθέσιμες προσφορές
          </Header>
          <Divider />
          <Grid columns="equal" stackable stretched>
            {offers.data?.map(({ id, title, description, repairman }) => (
              <Grid.Column key={id}>
                <OfferCard
                  title={title}
                  description={description}
                  meta={<RepairmanPopup repairman={repairman} />}
                />
              </Grid.Column>
            ))}
          </Grid>
        </Segment>
      )}
      {isConfirmOpen && (
        <Modal size="small" open onClose={handleConfirmClose} dimmer="inverted">
          <Modal.Header>Αίτηση παραλαβής</Modal.Header>
          <Segment basic>
            <Header as="h4">
              Πρόκειται να υποβάλετε αίτηση παραλαβής για το υλικό:{' '}
              {materialItem.data?.description}.
            </Header>
            {makeReservation.isSuccess && (
              <Message size="large" success>
                <Message.Header>Επιτυχία</Message.Header>
                <Message.Content>Η κράτηση υποβλήθηκε επιτυχώς</Message.Content>
              </Message>
            )}
            {makeReservation.isError && (
              <Message error>
                <Message.Header>Αποτυχία</Message.Header>
                <Message.Content>
                  Προέκυψε κάποιο σφάλμα κατά την υποβολή της κράτησης. <br />
                  {makeReservation.error?.response.status === 403 && (
                    <b>
                      Δεν έχετε δικαίωμα να εκτελέσετε την συγκεκριμένη ενέργεια
                    </b>
                  )}
                </Message.Content>
              </Message>
            )}
          </Segment>
          <Modal.Actions style={{ textAlign: 'left' }}>
            {makeReservation.isSuccess ? (
              <Button content="Επιστροφή" onClick={handleConfirmClose} />
            ) : (
              <>
                <Button
                  primary
                  content="Υποβολή"
                  onClick={handleFormSubmit}
                  loading={makeReservation.isLoading}
                  disabled={makeReservation.isLoading}
                />
                <Button content="Ακύρωση" onClick={handleConfirmClose} />
              </>
            )}
          </Modal.Actions>
        </Modal>
      )}
      {!profile.data && (
        <Route exact path={`${match.url}/application-form`}>
          <ReservationForm itemId={itemId} onClose={handleAnonymousFormClose} />
        </Route>
      )}
    </Segment>
  );
}

function MaterialInfo() {
  const { materialId } = useParams();
  const match = useRouteMatch();
  const backUrl = match.url.split('/').slice(0, -1).join('/');
  const material = useMaterial(materialId);
  const offers = useOffers({
    material: materialId,
    is_available: true,
    is_for_item: false,
  });

  const [displayPickupInfo, setDisplayPickupInfo] = useState(false);

  const handlePickupClick = () => {
    setDisplayPickupInfo(true);
  };

  const handleInfoHide = () => {
    setDisplayPickupInfo(false);
  };

  const hasMaterialItems = material.data?.has_items === true;
  const materialItems = useMaterialItems(
    {
      material: materialId,
      is_available: true,
      is_complete: true,
      page: 'all',
    },
    {
      enabled: hasMaterialItems,
    }
  );

  if (material.isLoading || materialItems.isLoading) {
    return (
      <>
        <Divider section hidden />
        <Loader active inline="centered" />
        <Divider section hidden />`
      </>
    );
  }

  return (
    <>
      <Header size="large" textAlign="center">
        <Button size="huge" icon floated="left" as={Link} to={backUrl}>
          <Icon
            name="arrow left"
            size="small"
            style={{ lineHeight: 'inherit' }}
          />
        </Button>
        {material.data?.body}
      </Header>
      <Segment
        loading={material.isLoading || offers.isLoading}
        placeholder={material.isLoading || offers.isLoading}
      >
        <div className="material-container">
          {material.data && <ThumbSwiper images={material.data.images} />}
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: '16px',
            }}
          >
            <List className="material-description" divided relaxed="very">
              <List.Item>
                <List.Header>Κατηγορία:</List.Header>
                <List.Content floated="right">
                  {material.data?.subcategory.category.name}
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Header>Υποκατηγορία:</List.Header>
                <List.Content>{material.data?.subcategory.name}</List.Content>
              </List.Item>
              <List.Item>
                <List.Header>Διαθεσιμότητα (σύνολο):</List.Header>
                <List.Content>
                  {material.data?.amount_available > 0
                    ? material.data?.amount_available
                    : 'Μη διαθέσιμο'}
                </List.Content>
              </List.Item>
              {material.data?.amount_available > 0 &&
                material.data?.availability.map((kdey) => (
                  <List.Item
                    key={kdey.recycle_center.id}
                    style={{ paddingLeft: '8px' }}
                  >
                    <List.Content>{kdey.recycle_center.name}:</List.Content>
                    <List.Content>{kdey.amount_available}</List.Content>
                  </List.Item>
                ))}
              <List.Item>
                <List.Header>Πόντοι ανά τεμάχιο (παράδοση):</List.Header>
                <List.Content>
                  {material.data?.subcategory.turn_in_points}
                </List.Content>
              </List.Item>
              <List.Item
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <List.Header>Πόντοι ανά τεμάχιο (παραλαβή):</List.Header>
                <List.Content>
                  {material.data?.subcategory.pick_up_points}
                </List.Content>
              </List.Item>
            </List>
            {!(hasMaterialItems && materialItems.data?.length > 0) && (
              <Button
                primary
                onClick={handlePickupClick}
                disabled={!material.data?.amount}
              >
                Το Θέλω
              </Button>
            )}
          </div>
        </div>

        {displayPickupInfo && <PickupInfo onClose={handleInfoHide} />}
      </Segment>

      {hasMaterialItems && materialItems.data?.length > 0 && (
        <MaterialItemList
          submitOrder={true}
          materialItems={materialItems.data}
        />
      )}

      {offers.data?.length > 0 && (
        <Segment basic>
          <Header textAlign="center" as="h2">
            Διαθέσιμες προσφορές
          </Header>
          <Divider />
          <Grid columns="equal" stackable stretched>
            {offers.data?.map(({ id, title, description, repairman }) => (
              <Grid.Column key={id}>
                <OfferCard
                  title={title}
                  description={description}
                  meta={<RepairmanPopup repairman={repairman} />}
                />
              </Grid.Column>
            ))}
          </Grid>
        </Segment>
      )}
    </>
  );
}

function MaterialList({ baseUrl }) {
  const { subcategoryId } = useParams();

  const match = useRouteMatch();

  const materials = useMaterials({
    params: { subcategory: subcategoryId, page_size: 400 },
    options: { enabled: !!subcategoryId },
  });

  return (
    <>
      <Segment
        basic
        loading={materials.isLoading}
        placeholder={materials.isLoading}
        style={{ width: '100%' }}
      >
        <Header
          textAlign="center"
          as="h2"
          content={materials.data?.results[0]?.subcategory.name}
        />
        <div className="custom-grid">
          {materials.data?.results.map(({ id, images, body }) => (
            <MaterialCard
              key={id}
              images={images}
              title={body}
              button={
                <Button primary as={NavLink} to={`${match.url}/${id}`}>
                  Άνοιγμα
                </Button>
              }
            />
          ))}
        </div>
        {materials.data?.results.length === 0 && (
          <Message info>
            <Message.Header>Δεν βρέθηκαν υλικά</Message.Header>
            <Message.Content>
              Δεν υπάρχουν υλικά για την συγκεκριμένη υποκατηγορία.{' '}
              <Link to={baseUrl}>Επιστροφή στις κατηγορίες</Link>
            </Message.Content>
          </Message>
        )}
      </Segment>
    </>
  );
}

function SubcategoriesList({ baseUrl }) {
  const { categoryId } = useParams();
  const subcategories = useSubcategories({
    params: { page_size: 400, category: categoryId },
  });
  const match = useRouteMatch();

  if (subcategories.isFetched && match.isExact) {
    return (
      <Redirect
        to={`${match.url}/subcategory/${subcategories.data?.results[0].id}/materials`}
      />
    );
  }

  return (
    <Container style={{ display: 'flex' }}>
      <Menu
        vertical
        style={{
          position: 'sticky',
          top: 0,
          flexShrink: 0,
          alignSelf: 'start',
        }}
      >
        <Menu.Item icon="arrow left" as={Link} to={baseUrl}></Menu.Item>
        <Menu.Item>
          <Menu.Header>
            {subcategories.data?.results[0].category.name}
          </Menu.Header>
        </Menu.Item>
        {subcategories.data?.results.map(({ id, name }) => (
          <Menu.Item
            as={NavLink}
            to={`${match.url}/subcategory/${id}/materials`}
            key={id}
          >
            {name}
          </Menu.Item>
        ))}
      </Menu>
      <Route path={`${match.path}/subcategory/:subcategoryId/materials`}>
        <MaterialList baseUrl={baseUrl} />
      </Route>
    </Container>
  );
}

function ViewSubcategories({ categoryId }) {
  const match = useRouteMatch();

  return (
    <Button
      labelPosition="right"
      icon="right chevron"
      content="Δείτε περισσότερα"
      as={Link}
      to={`${match.path}/category/${categoryId}`}
    />
  );
}

function CategoryCard({ image, name, categoryId }) {
  return (
    <Card centered className="custom-card">
      <Card.Content className="blue-bg">
        <Image src={image} wrapped />
      </Card.Content>
      <Card.Content className="custom-content">
        <Card.Header textAlign="center">{name}</Card.Header>
      </Card.Content>
      <Card.Content textAlign="center" className="more-bt">
        <ViewSubcategories categoryId={categoryId} />
      </Card.Content>
    </Card>
  );
}

function ReuseCategories() {
  const categories = useCategories({ params: { page_size: 400 } });

  return (
    <Segment
      basic
      loading={categories.isLoading}
      placeholder={categories.isLoading}
    >
      <div className="custom-grid">
        {categories.data?.results.map(({ url, name, id }) => (
          <CategoryCard
            key={id}
            image={url || '/placeholder.jpg'}
            name={name}
            categoryId={id}
          />
        ))}
      </div>
    </Segment>
  );
}

function MaterialRedirectHandler({ baseUrl }) {
  const { materialId, itemId } = useParams();

  const materialItem = useMaterialItem(itemId);
  // in case we don't have the materialId, we wait to get it from the material item
  const material = useMaterial(materialId || materialItem.data?.material);

  if (material.isLoading || material.isIdle) {
    return <Loader active />;
  }

  return (
    <Redirect
      to={`${baseUrl}/category/${material.data?.subcategory.id}/subcategory/${
        material.data?.subcategory.id
      }/materials/${material.data?.id}${itemId ? `/item/${itemId}` : ''}`}
    />
  );
}

export default function ReusePickup() {
  const pageTitle = 'Θέλω να παραλάβω';
  const match = useRouteMatch();

  return (
    <>
      <Helmet title={pageTitle} />

      <Container>
        <Header
          textAlign="center"
          as="h1"
          className="colored atmgreen"
          content={pageTitle}
        />
        <Divider className="orange" />
        <Divider hidden />
      </Container>
      <Container>
        <Switch>
          <Route path={`${match.path}`} exact>
            <ReuseCategories />
          </Route>
          <Route
            path={[
              `${match.path}/material/:materialId/item/:itemId(\\d+)?`,
              `${match.path}/material/:materialId`,
              `${match.path}/materialitem/:itemId(\\d+)`,
            ]}
          >
            <MaterialRedirectHandler baseUrl={match.url} />
          </Route>
          <Route 
            path={`${match.path}/category/:categoryId/subcategory/:subcategoryId(\\d+)/materials/:materialId/item/:itemId(\\d+)`}
          >
            <MaterialItemInfo />
          </Route>

          <Route
            path={`${match.path}/category/:categoryId/subcategory/:subcategoryId(\\d+)/materials/:materialId(\\d+)`}
          >
            <MaterialInfo />
          </Route>
          <Route path={`${match.path}/category/:categoryId`}>
            <SubcategoriesList baseUrl={match.url} />
          </Route>

          <Route path="*">
            <Redirect to={match.path} />
          </Route>
        </Switch>
      </Container>
      <Divider section hidden />
    </>
  );
}
