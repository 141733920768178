import { Helmet } from 'react-helmet';

import { Container, Divider, Header, List, Segment } from 'semantic-ui-react';

function Terms() {
  const pageTitle = 'Όροι και προϋποθέσεις';
  return (
    <>
      <Helmet title={pageTitle} />
      <Container>
        <Header
          as="h1"
          textAlign="center"
          className="colored atmgreen"
          content={pageTitle}
        />
        <Divider className="orange" />
        <Divider hidden />
      </Container>

      <Container text>
        <section>
          <p>
            Ο κάθε πολίτης θα μπορεί είτε να παραδίδει αντικείμενα, που δεν
            είναι απόβλητα και δεν τα χρειάζεται, είτε να αποκτά υλικά από τους
            χώρους των ΚΔΕΥ, χωρίς κόστος.
          </p>

          <strong>
            Τα υλικά που συγκεντρώνονται προς επαναχρησιμοποίηση περιλαμβάνουν:
          </strong>

          <List bulleted style={{ paddingLeft: '16px' }}>
            <List.Item>
              Βιβλία – Χαρτικά – Είδη γραφείου – Γραφική ύλη
            </List.Item>
            <List.Item>
              Οικιακά σκεύη (πιάτα, ποτήρια, μαγειρικά σκεύη, κλπ.)
            </List.Item>
            <List.Item> Είδη οικιακής χρήσης – Διακοσμητικά</List.Item>
            <List.Item>
              Είδη προσωπικής περιποίησης (σεσουάρ, ξυριστικές μηχανές, κλπ.)
            </List.Item>
            <List.Item> Εργαλεία (παντός τύπων)</List.Item>
            <List.Item>
              Ηλεκτρικές Ηλεκτρονικές Συσκευές (ψυγεία, πλυντήρια, Η/Υ,
              μικροσυσκευές, κλπ.)
            </List.Item>
            <List.Item>
              Είδη ένδυσης – Υπόδησης – Αξεσουάρ – Τσάντες – Είδη ταξιδιού
            </List.Item>
            <List.Item> Λευκά ειδή (πετσέτες, σεντόνια, κλπ.)</List.Item>
            <List.Item> Μοκέτες – χαλιά</List.Item>
            <List.Item>
              Είδη επίπλωσης (καρέκλες, τραπέζια, καναπέδες, σύνθετα, κλπ.)
            </List.Item>
            <List.Item> Είδη χόμπι-Δραστηριότητες (διάφορα είδη)</List.Item>
            <List.Item> Παιχνίδια (διάφορα είδη)</List.Item>
            <List.Item>
              Παιδικά είδη (είδη βρεφοανάπτυξης, παιδικά καθίσματα, κλπ.)
            </List.Item>
            <List.Item> CD/DVD (ταινιών, μουσικής, παιχνιδιών, κλπ.)</List.Item>
            <List.Item>
              Είδη για κατοικίδια ζώα (σπίτια, κλουβιά, είδη περιποίησης, κλπ.)
            </List.Item>
            <List.Item>
              Εποχιακά είδη (χριστουγεννιάτικα, πασχαλιάτικα, καλοκαιρινά, κλπ.)
            </List.Item>
          </List>

          <strong>Τα υλικά θα πρέπει:</strong>

          <List
            relaxed
            className="decorated green check icon"
            style={{ paddingLeft: '16px' }}
          >
            <List.Item>
              <span>Να είναι σε καλή κατάσταση/λειτουργικά</span>
            </List.Item>
            <List.Item>
              <span>
                {' '}
                Να μην έχουν φθαρεί σε βαθμό που δεν μπορούν να
                επαναχρησιμοποιηθούν
              </span>
            </List.Item>
            <List.Item>
              <span>Οι ηλεκτρικές συσκευές να λειτουργούν</span>
            </List.Item>
          </List>
          <p>
            Το ραντεβού για την επίσκεψη είναι προαιρετικό, όμως πρέπει να
            τηρούνται τα ωράρια λειτουργίας (Δε-Τρ-Τε-Πε-Πα 08:00-15:00 και
            Σάββατο 09:00-13:00). Προτείνεται να επικοινωνείτε με το ΚΔΕΥ πριν
            την επικείμενη επίσκεψη, για την καλύτερη εξυπηρέτησή σας.
          </p>
          <p>
            Ο κάθε πολίτης που παραλαμβάνει κάποιο υλικό από τα ΚΔΕΥ, υπογράφει
            την παρακάτω υπεύθυνη δήλωση:
          </p>
          <Segment size="large" placeholder raised>
            <strong>
              Με ατομική μου ευθύνη, και γνωρίζοντας τις κυρώσεις
              <sup>(1)</sup> που προβλέπονται από τις διατάξεις της παρ. 6 του
              άρθρου 22 του Ν. 1599/1986, δηλώνω ότι:
            </strong>

            <List bulleted>
              <List.Item>
                Τα υλικά που παρέλαβα προορίζονται για ιδία χρήση (εμού ή της
                οικογενείας μου)
              </List.Item>

              <List.Item>
                Δε θα μεταβιβάσω την κυριότητά τους σε τρίτους με οποιονδήποτε
                τρόπο, με ή χωρίς αντάλλαγμα
              </List.Item>

              <List.Item>
                Εξέτασα σχολαστικώς τα προϊόντα που παρέλαβα και ενημερώθηκα για
                τυχόν ελαττώματα ή φθορές
              </List.Item>

              <List.Item>
                H ΔΙΑΔΥΜΑ ΑΕ δεν μου εγγυήθηκε για την ποιότητα των προϊόντων
                που παρέλαβα, ούτε μου δήλωσε ότι αυτά στερούνται φθορών ή
                ελαττωμάτων
              </List.Item>
            </List>

            <p>
              (1) «Όποιος εν γνώσει του δηλώνει ψευδή γεγονότα ή αρνείται ή
              αποκρύπτει τα αληθινά με έγγραφη υπεύθυνη δήλωση του άρθρου 8
              τιμωρείται με φυλάκιση τουλάχιστον τριών μηνών. Εάν ο υπαίτιος
              αυτών των πράξεων σκόπευε να προσπορίσει στον εαυτόν του ή σε
              άλλον περιουσιακό όφελος βλάπτοντας τρίτον ή σκόπευε να βλάψει
              άλλον, τιμωρείται με κάθειρξη μέχρι 10 ετών».
            </p>
          </Segment>
        </section>

        <section>
          <Divider section horizontal>
            <Header as="h2">Πολιτική απορρήτου</Header>
          </Divider>
          <p>
            Η παρούσα σελίδα εξηγεί την προσέγγισή των ιδιοκτητών της πλατφόρμας
            ως προς την πολιτική απορρήτου και πώς αυτή επηρεάζει εσάς κατά την
            πλοήγηση στον ιστότοπο μας.
          </p>
          <p>
            <strong>Γενικά</strong>
          </p>
          <p>
            Συλλέγουμε ανώνυμα στατιστικά στοιχεία σχετικά με την επίσκεψή σας,
            όπως το ποιες από τις σελίδες μας επισκεφθήκατε. Στον ιστότοπο μας
            φιλοξενούμε κομμάτια κώδικα από τρίτα μέρη, όπως η Facebook και η
            Twitter, οι οποίες ενδέχεται να γνωρίζουν ότι επισκεφθήκατε τον
            παρόντα ιστότοπο, εάν χρησιμοποιείτε τις υπηρεσίες τους. Δεν
            μπορούμε να παρέμβουμε σ’ αυτά τα τρίτα μέρη, αλλά δεν πιστεύουμε
            ότι αυτή η γνώση εκ μέρους τους συνιστά απειλή για σας.
          </p>
          <p>
            Αν εγγραφείτε στις υπηρεσίες μας, σας διαβεβαιώνουμε πως διατηρούμε
            τις πληροφορίες σας ασφαλείς και δεν θα τις μοιραστούμε με άλλους
            χωρίς τη ρητή άδειά σας.
          </p>
          <p>
            Δεν δίνουμε ποτέ προσωπικές πληροφορίες των επισκεπτών μας σε
            τρίτους, παρά μόνο για τη διασφάλιση των προσφερόμενων υπηρεσιών του
            ιστότοπου.
          </p>
        </section>

        <section>
          <Divider section horizontal>
            <Header as="h2">Πολιτική cookies</Header>
          </Divider>
          <p>
            Οι περισσότερες ιστοσελίδες που επισκέπτεστε χρησιμοποιούν cookies
            για τη βελτίωση της δικής σας εμπειρίας, επιτρέποντας στην κάθε
            ιστοσελίδα να σας «θυμάται», είτε κατά τη διάρκεια της επίσκεψής σας
            (χρησιμοποιώντας ένα «προσωρινό cookie»), είτε για επανειλημμένες
            επισκέψεις (χρησιμοποιώντας ένα «μόνιμο cookie»).
          </p>
          <p>
            Οι λειτουργίες των cookies είναι πολλές και διαφορετικές, όπως το να
            σας επιτρέπουν να πλοηγήστε μεταξύ σελίδων αποτελεσματικά, να
            αποθηκεύουν τις προτιμήσεις σας, και γενικά να βελτιώνουν την
            εμπειρία της πλοήγησής σας στην ιστοσελίδα. Τα cookies κάνουν την
            αλληλεπίδρασή σας με την ιστοσελίδα ταχύτερη και ευκολότερη. Αν ένας
            ιστότοπος δεν χρησιμοποιεί cookies, τότε θα νομίζει ότι είστε ένας
            νέος επισκέπτης κάθε φορά που μεταβαίνετε σε μια νέα σελίδα του –
            για παράδειγμα, αν εισάγετε τα στοιχεία εισόδου σας (όνομα χρήστη
            και κωδικός) και μετά μεταβείτε σε μια άλλη σελίδα, τότε ο ιστότοπος
            δεν θα σας αναγνωρίζει και δεν θα είναι σε θέση να σας διατηρήσει σε
            κατάσταση σύνδεσης.
          </p>
          <p>
            Κάποιοι ιστότοποι χρησιμοποιούν επίσης cookies ώστε να μπορούν να
            στοχεύσουν τις διαφημίσεις τους ή τα προωθητικά τους μηνύματα βάσει,
            π.χ. της τοποθεσίας σας και / ή των συνηθειών πλοήγησής σας.
          </p>
          <p>
            Τα cookies μπορεί να ενεργοποιούνται από την ιστοσελίδα που
            επισκέπτεστε («cookies πρώτου μέρους») ή μπορεί να ενεργοποιούνται
            από άλλες ιστοσελίδες που «τρέχουν» περιεχόμενο στην ιστοσελίδα
            («cookies τρίτων μερών»).
          </p>
          <Header as="h3">Τι εμπεριέχεται σ’ ένα cookie;</Header>
          <p>
            Ένα cookie είναι ένα απλό αρχείο κώδικα που αποθηκεύεται στον
            υπολογιστή ή στην κινητή συσκευή σας από τον διακομιστή ενός
            ιστότοπου και μόνο αυτός ο διακομιστής είναι σε θέση να ανακτήσει ή
            να διαβάσει τα περιεχόμενα αυτού του cookie. Κάθε cookie είναι
            μοναδικό για το φυλλομετρητή σας. Περιέχει κάποιες ανώνυμες
            πληροφορίες, όπως ένα μοναδικό αναγνωριστικό και το όνομα του
            ιστότοπου και κάποια ψηφία και αριθμούς. Το cookie επιτρέπει σε έναν
            ιστότοπο να θυμάται πράγματα, όπως οι προτιμήσεις σας ή το τι
            περιέχεται στο καλάθι αγορών σας.
          </p>
          <Header as="h3">Προσαρμόστε τα cookies στο φυλλομετρητή</Header>
          <p>
            Αν δεν θέλετε να λαμβάνετε cookies, μπορείτε να τροποποιήσετε το
            φυλλομετρητή σας ώστε να σας ειδοποιεί όταν σας αποστέλλονται
            cookies, ή μπορείτε και να τα απορρίψετε εντελώς. Επίσης, μπορείτε
            να διαγράψετε cookies που έχουν ήδη αποθηκευτεί.
          </p>
          <p>
            Αν θέλετε να περιορίσετε ή να μπλοκάρετε τα cookies που έχουν
            αποθηκευτεί στο φυλλομετρητή ή τη συσκευή σας, μπορείτε να το κάνετε
            μέσω των ρυθμίσεων του φυλλομετρητή σας. Το κουμπί «Βοήθεια» εντός
            του φυλλομετρητή θα σας πει πώς. Εναλλακτικά, μπορείτε να
            επισκεφθείτε τον ιστότοπο www.aboutcookies.org, ο οποίος διαθέτει
            περιεκτικές πληροφορίες ως προς το πώς να το κάνετε αυτό σε μια
            μεγάλη ποικιλία φυλλομετρητών για desktop.
          </p>
          <p>
            Ο ιστότοπός μας λειτουργεί και χωρίς cookies, αλλά θα χάσετε κάποια
            χαρακτηριστικά και λειτουργίες του αν επιλέξετε να απενεργοποιήσετε
            τα cookies, και ιδιαίτερα από τρίτα μέρη, όπως κουμπιά κοινωνικού
            διαμοιρασμού κλπ.
          </p>
          <p>
            Cookies τρίτων μερών όπως Facebook, Twitter και άλλα κοινωνικά
            δίκτυα
          </p>
          <p>
            Αυτές οι υπηρεσίες παρέχουν κουμπιά κοινωνικών δικτύων και παρόμοια
            χαρακτηριστικά που χρησιμοποιούμε στον ιστότοπό μας – όπως τα
            κουμπιά ‘Like’ και ‘Tweet’.
          </p>
          <p>
            Για να το κάνουμε αυτό, ενσωματώνουμε κώδικα που παρέχουν αυτοί και
            που εμείς δεν ελέγχουμε. Για να λειτουργήσουν, τα κουμπιά τους
            γενικά γνωρίζουν αν είστε συνδεδεμένος. Για παράδειγμα, το Facebook
            χρησιμοποιεί αυτή τη λειτουργία για να σας ενημερώσει ότι η σελίδα
            που επισκέπτεστε «αρέσει σε x φίλους σας». Δεν έχουμε καμία πρόσβαση
            σε αυτές τις πληροφορίες και ούτε μπορούμε να ελέγξουμε πώς αυτά τα
            δίκτυα χρησιμοποιούν αυτές τις πληροφορίες.
          </p>
          <p>
            Τα κοινωνικά δίκτυα, συνεπώς, μπορεί να γνωρίζουν ότι παρακολουθείτε
            τον παρόντα ιστότοπο, αν χρησιμοποιείτε τις υπηρεσίες τους (δεν
            σημαίνει ότι το κάνουν, αλλά οι πολιτικές τους συχνά υπόκεινται σε
            αλλαγές). Εφόσον ο ιστότοπός μας είναι εντελώς άκακος, φανταζόμαστε
            ότι αυτό δεν αποτελεί αιτία ανησυχίας για τους περισσότερους
            χρήστες.
          </p>
          <Header as="h3">Καταγραφή του πλήθους των επισκεπτών μας</Header>
          <p>
            Μετράμε τους επισκέπτες μας στον ιστότοπό μας χρησιμοποιώντας το
            Google Analytics. Αυτό το εργαλείο καταγράφει ποιες σελίδες
            παρακολουθείτε εντός του ιστότοπού μας, πώς φτάσατε στον ιστότοπό
            μας, καθώς και κάποιες βασικές πληροφορίες σχετικά με τον
            ηλεκτρονικό υπολογιστή σας. Όλες αυτές οι πληροφορίες είναι ανώνυμες
            – συνεπώς δεν γνωρίζουμε ποιος είστε, παρά μόνο ότι κάποιος
            επισκέφθηκε τον ιστότοπό μας.
          </p>
          <p>
            Οι πληροφορίες που εξάγουμε από τη μέτρηση και την αξιολόγηση των
            στατιστικών που συλλέγουμε, μάς βοηθούν να καταλάβουμε ποια τμήματα
            του ιστότοπού μας πηγαίνουν καλά, πόσοι άνθρωποι επισκέπτονται τον
            ιστότοπό μας, κ.ο.κ. Όπως οι περισσότεροι ιστότοποι, χρησιμοποιούμε
            αυτές τις πληροφορίες για να κάνουμε τον ιστότοπό μας καλύτερο.
          </p>
          <p>
            Μπορείτε να μάθετε περισσότερα σχετικά με το Google Analytics ή να
            εξαιρεθείτε αν θέλετε
          </p>
          <p>Τα cookies που συλλέγουμε είναι τα εξής:</p>
          <Header as="h3">
            Google Analytics: _utma | _utmb | _utmc | _utmz
          </Header>
          <p>
            Αυτά τα cookies χρησιμοποιούνται για τη συλλογή πληροφοριών σχετικά
            με το πώς οι επισκέπτες χρησιμοποιούν τον ιστότοπό μας.
            Χρησιμοποιούμε τις πληροφορίες για να καταρτίζουμε αναφορές και να
            βοηθούμαστε στη βελτίωση της ιστοσελίδας. Τα cookies συλλέγουν
            πληροφορίες σε μια ανώνυμη μορφή, συμπεριλαμβανομένων του αριθμού
            των επισκεπτών στην ιστοσελίδα, από πού ήρθαν οι επισκέπτες στην
            ιστοσελίδα, και ποιες σελίδες επισκέφθηκαν. Κάντε κλικ εδώ για μια
            επισκόπηση της πολιτικής απορρήτου και ασφάλειας δεδομένων της
            Google
          </p>
        </section>
        <Divider hidden />
      </Container>
    </>
  );
}

export default Terms;
