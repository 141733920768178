import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';

import axios from 'axios';

export const useMaterialItems = (
  {
    ordering,
    page,
    is_complete,
    search,
    is_available,
    recycle_center,
    page_size,
    material,
    referenced_in,
  },
  options
) =>
  useQuery(
    [
      'materialItems',
      {
        ordering,
        page,
        is_complete,
        search,
        is_available,
        recycle_center,
        page_size,
        material,
        referenced_in,
      },
    ],
    () =>
      axios
        .get(`/api/material_items/`, {
          params: {
            ordering,
            page,
            is_complete,
            search,
            is_available,
            recycle_center,
            page_size,
            material,
            referenced_in,
          },
        })
        .then(({ data }) => data),
    {
      refetchOnWindowFocus: false,
      ...options,
    }
  );

export const useMaterialItem = (
  id,
  { enabled = true, refetchOnWindowFocus = false, ...options } = {}
) =>
  useQuery(
    ['materialItems', { id }],
    () => axios.get(`/api/material_items/${id}/`).then(({ data }) => data),
    {
      enabled: id ? enabled : false,
      refetchOnWindowFocus,
      ...options,
    }
  );

export const useUpdateMaterialItem = (id, onSuccess) =>
  useMutation(
    (data) =>
      axios.patch(`/api/material_items/${id}/`, data).then(({ data }) => data),
    { onSuccess }
  );

export const useEnableMaterialItem = (id, onSuccess) =>
  useMutation(
    () =>
      axios
        .patch(`/api/material_items/${id}/`, { is_complete: true })
        .then(({ data }) => data),
    { onSuccess }
  );

export const useDisableMaterialItem = (id, onSuccess) =>
  useMutation(
    () =>
      axios
        .patch(`/api/material_items/${id}/`, { is_complete: false })
        .then(({ data }) => data),
    { onSuccess }
  );

export const useDeleteMaterialItemImage = (id, onSuccess) =>
  useMutation(
    () =>
      axios.delete(`/api/material_item_images/${id}/`).then(({ data }) => data),
    { onSuccess }
  );

export const useUploadMaterialItemImage = (id, onSuccess) => {
  const [progress, setProgress] = useState(0);

  const resetProgress = () => {
    setProgress(0);
  };
  const uploadMutation = useMutation(
    (data) =>
      axios
        .post(`/api/material_items/${id}/images/`, data, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: (progressEvent) => {
            const progress = (progressEvent.loaded / progressEvent.total) * 100;
            setProgress(progress);
          },
          onDownloadProgress: (progressEvent) => {
            const progress = (progressEvent.loaded / progressEvent.total) * 100;
            setProgress(progress);
          },
        })
        .then(({ data }) => data),
    {
      onSuccess,
      onMutate: resetProgress,
    }
  );
  return { progress, uploadMutation };
};
