import { useQuery } from 'react-query';

import axios from 'axios';
import orderBy from 'lodash/orderBy';

export const useMaterialAvailability = (
  categoryId,
  params = {},
  options = {}
) => {
  return useQuery(
    ['categories_availability', categoryId, params],
    () =>
      axios
        .get(
          `/api/categories${
            categoryId === 'all' ? '' : `/${categoryId}`
          }/availability/`,
          {
            params,
          }
        )
        .then(({ data }) => orderBy(data, 'amount')),
    {
      refetchOnWindowFocus: false,
      ...options,
    }
  );
};
