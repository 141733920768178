import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useQueryClient } from 'react-query';
import { NavLink } from 'react-router-dom';

import {
  Button,
  Container,
  Divider,
  Form,
  Header,
  List,
  Loader,
  Message,
  Modal,
} from 'semantic-ui-react';
import { useMaterialItem } from '../queries/materialItems';

import { useMaterials } from '../queries/materials';
import { useMakeReservation } from '../queries/reservations';
import { ImageSlider } from './ReusePickup';

export function ReservationForm({ itemId, onClose }) {
  const pageTitle = 'Φόρμα Παραλαβής Υλικού';

  const queryClient = useQueryClient();

  const materialItem = useMaterialItem(itemId);

  const [formData, setFormData] = useState(() => ({}));
  const handleChange = (e, { name, value }) => {
    setFormData((formData) => ({ ...formData, [name]: value }));
  };
  const makeReservation = useMakeReservation();
  console.log(formData);
  const handleSubmit = () => {
    makeReservation.mutate(
      {
        item: itemId,
        ...formData,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries('materialItems');
        },
      }
    );
  };
  const handleClose = () => {
    onClose();
    makeReservation.reset();
  };
  return (
    <>
      <Helmet title={pageTitle} />
      <Modal size="small" dimmer="inverted" open onClose={handleClose}>
        <Modal.Header>{pageTitle}</Modal.Header>
        <Modal.Content>
          <Form
            id="reservation-form"
            onSubmit={handleSubmit}
            success={makeReservation.isSuccess}
            error={makeReservation.isError}
          >
            <Header textAlign="center" as="h3">
              {materialItem.data?.material_name}{' '}
              {materialItem.data?.description}
            </Header>
            <div
              style={{ maxWidth: '250px', margin: '0 auto', display: 'flex' }}
            >
              <ImageSlider
                images={materialItem.data?.images || []}
                noMaxHeight
              />
            </div>
            <Divider />
            <Form.Group widths="equal">
              <Form.Input
                readOnly={makeReservation.isSuccess}
                label="Όνομα"
                name="first_name"
                onChange={handleChange}
                required
              />
              <Form.Input
                readOnly={makeReservation.isSuccess}
                name="last_name"
                onChange={handleChange}
                label="Επώνυμο"
                required
              />
            </Form.Group>
            <Form.Input
              readOnly={makeReservation.isSuccess}
              name="email"
              onChange={handleChange}
              label="Email"
              type="email"
            />
            <Form.Input
              readOnly={makeReservation.isSuccess}
              name="telephone"
              onChange={handleChange}
              label="Τηλέφωνο"
              type="telephone"
              required
            />
            <Message
              success
              header="Επιτυχία"
              content="Η κράτηση υποβλήθηκε επιτυχώς"
            />
            <Message
              error
              header="Αποτυχία"
              content=" Προέκυψε κάποιο σφάλμα κατά την υποβολή της κράτησης"
              onDismiss={() => {
                makeReservation.reset();
              }}
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          {makeReservation.isSuccess ? (
            <Button content="Επιστροφή" onClick={handleClose} />
          ) : (
            <>
              <Button
                primary
                form="reservation-form"
                content="Υποβολή"
                disabled={makeReservation.isLoading}
                loading={makeReservation.isLoading}
              />
              <Button content="Ακύρωση" onClick={handleClose} />
            </>
          )}
        </Modal.Actions>
      </Modal>
    </>
  );
}

export function ParticipantApplicationFormInfo() {
  const pageTitle = 'Πληροφορίες φόρμας παραλαβής υλικού';

  const eligibleMaterials = useMaterials({
    params: { page_size: 400, eligible: true },
  });
  return (
    <>
      <Helmet title={pageTitle} />
      <Container>
        <Header
          as="h1"
          textAlign="center"
          className="colored atmgreen"
          content={pageTitle}
        />
        <Divider className="orange" />
      </Container>

      <Container text>
        <Divider hidden />
        <p>
          Ο κάθε πολίτης μπορεί να παραλαμβάνει δωρεάν τα υλικά που επιθυμεί από
          τους χώρους των Κέντρων Δημιουργικής Επαναχρησιμοποίησης Υλικών
          (ΚΔΕΥ). Εκτός της επί τόπου επίσκεψης, ο πολίτης έχει τη δυνατότητα να
          «κάνει κράτηση» σε κάποια συγκεκριμένα υλικά μέσα από την παρούσα
          ηλεκτρονική πλατφόρμα, εφόσον αυτά υπάρχουν σε κάποιο ΚΔΕΥ. Τα υλικά
          αυτά είναι:
        </p>
        {eligibleMaterials.isLoading && <Loader active inline="centered" />}
        {eligibleMaterials.data && (
          <List
            relaxed
            bulleted
            className="decorated orange"
            style={{
              display: 'grid',
              gridAutoFlow: 'row',
              // gridTemplateRows: 'repeat(4, max-content)',
              gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
              alignItems: 'center',
            }}
          >
            {eligibleMaterials.data?.results.map(
              ({ body, id, subcategory }) => (
                <List.Item
                  key={id}
                  as={NavLink}
                  to={`/pick-up/category/${subcategory.category.id}/subcategory/${subcategory.id}/materials/${id}`}
                >
                  <span>{body}</span>
                </List.Item>
              )
            )}
          </List>
        )}
        <Divider hidden />
        <p>
          Για να «κάνει κράτηση» θα πρέπει να είναι εγγεγραμμένος χρήστης της
          πλατφόρμας. Όταν συνδεθεί στο λογαριασμό του, θα ψάξει το υλικό που
          χρειάζεται και θα πατήσει στην επιλογή «Αίτηση παραλαβής». Η επιλογή
          αυτή θα τον οδηγήσει σε μια φόρμα επικοινωνίας στην οποία θα αναφέρει
          οπωσδήποτε το όνομα του, ένα τηλέφωνο επικοινωνίας και το ΚΔΕΥ από το
          οποίο επιθυμεί να παραλάβει το υλικό. Κατόπιν ο υπάλληλος του ΚΔΕΥ θα
          του τηλεφωνήσει για να κλείσουν ραντεβού για την παραλαβή του υλικού.
          Η δέσμευση του υλικού διαρκεί έως και 7 μέρες.
        </p>

        <Divider section hidden />
      </Container>
    </>
  );
}
