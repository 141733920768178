import { MapContainer, Marker, Popup } from 'react-leaflet';
import { Header } from 'semantic-ui-react';
import GoogleLayer from 'react-leaflet-google-layer';

import 'leaflet-gesture-handling';
import 'leaflet-gesture-handling/dist/leaflet-gesture-handling.css';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png').default,
  iconUrl: require('leaflet/dist/images/marker-icon.png').default,
  shadowUrl: require('leaflet/dist/images/marker-shadow.png').default,
});
export default function KdeyMap({ kdey }) {
  return (
    <MapContainer
      className="kdey-map"
      center={kdey}
      zoom={11}
      scrollWheelZoom={false}
      gestureHandling
    >
      <GoogleLayer
        apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
        language="el"
        type="roadmap"
      />
      <Marker position={kdey}>
        <Popup>
          <Header as="h5">{kdey.name}</Header>
        </Popup>
      </Marker>
    </MapContainer>
  );
}
