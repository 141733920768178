import { useState } from 'react';
import {
  Link,
  Route,
  useHistory,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useQuery, useQueryClient } from 'react-query';

import {
  Button,
  Container,
  Divider,
  Form,
  Header,
  Label,
  Loader,
  Menu,
  Message,
  Segment,
} from 'semantic-ui-react';

import { ConfirmPopup } from '@zerowaste/components';
import { useCategories } from '../../queries/categories';
import {
  useDeleteRepairman,
  useDisableRepairman,
  useEnableRepairman,
  useRepairman,
  useSaveRepairman,
} from '../../queries/repairmen';
import { PasswordChangeForm } from '../PasswordReset';

import axios from 'axios';

export default function RepairmanProfileForm({
  repairmanId,
  setFinishedMessage,
}) {
  const history = useHistory();
  const match = useRouteMatch();
  const params = useParams();
  const queryClient = useQueryClient();

  const isRepairmanProfile = !params.repairmanId;
  if (!repairmanId) {
    repairmanId = params.repairmanId;
  }

  const [formChanged, setFormChanged] = useState(false);
  const [formData, setFormData] = useState(() => ({}));

  const repairman = useRepairman(repairmanId, {
    onSettled: () => setFormData({}),
  });

  const invalidateRepairmen = () => queryClient.invalidateQueries('repairmen');

  const saveRepairman = useSaveRepairman(repairmanId);
  const verifyRepairman = useEnableRepairman(repairmanId);
  const disableRepairman = useDisableRepairman(repairmanId);
  const deleteRepairman = useDeleteRepairman(repairmanId);

  const handleSubmit = () => {
    saveRepairman.mutate(formData, {
      onSuccess: () => {
        invalidateRepairmen();
        setFormChanged(false);
      },
    });
  };
  const handleEnable = () =>
    verifyRepairman.mutate(
      {},
      {
        onSuccess: () => {
          setFinishedMessage?.('Ο επισκευαστής ενεργοποιήθηκε');
          invalidateRepairmen();
          history.replace(`/admin/repairmen/${repairmanId}`);
        },
      }
    );
  const handleDisable = () =>
    disableRepairman.mutate(
      {},
      {
        onSuccess: () => {
          setFinishedMessage?.('Ο επισκευαστής απενεργοποιήθηκε');
          invalidateRepairmen();
          history.replace(`/admin/repairmen/disabled/${repairmanId}`);
        },
      }
    );
  const handleDelete = () =>
    deleteRepairman.mutate(
      {},
      {
        onSuccess: () => {
          setFinishedMessage?.('Ο επισκευαστής διαγράφηκε');
          invalidateRepairmen();
          history.replace('../disabled');
        },
      }
    );

  const handleChange = (e, { name, value }) => {
    setFormChanged(true);
    saveRepairman.reset();
    setFormData((data) => ({ ...data, [name]: value }));
  };

  const handleReset = () => {
    setFormData({});
    saveRepairman.reset();
    setFormChanged(false);
  };

  const { data: municipalities } = useQuery(
    'municipalities',
    () =>
      axios.get('/api/municipalities/').then(({ data }) => {
        // transform to options format
        return data.map((m) => ({ value: m.id, text: m.name }));
      }),
    { initialData: [] }
  );

  const { data: categories } = useCategories({
    params: { page_size: 400 },
    options: {
      select: (data) => {
        // transform to options format
        return data.results.map((m) => ({
          key: m.id,
          value: m.id,
          text: m.name,
        }));
      },
    },
  });

  const getValue = (name) =>
    formData[name] === undefined
      ? repairman.data?.[name]
      : formData[name] ?? '';

  return (
    <>
      <Route path={match.path}>
        <Helmet title="Διαχείριση στοιχείων λογαριασμού" />
        <Container text>
          <Segment secondary clearing disabled={repairman.isLoading}>
            <Loader active={repairman.isLoading} />
            <Label ribbon="right" color="teal" size="large">
              Ηλεκτρονική διεύθυνση: {repairman.data?.email}
            </Label>
            <Form onSubmit={handleSubmit} success={saveRepairman.isSuccess}>
              <Form.Input
                required
                label="Όνομα"
                name="first_name"
                value={getValue('first_name')}
                onChange={handleChange}
                error={saveRepairman.error?.response.data?.first_name}
              />
              <Form.Input
                required
                label="Επώνυμο"
                name="last_name"
                value={getValue('last_name')}
                onChange={handleChange}
                error={saveRepairman.error?.response.data?.last_name}
              />
              <Form.Input
                required
                label="Τηλέφωνο"
                name="telephone"
                value={getValue('telephone')}
                onChange={handleChange}
                error={saveRepairman.error?.response.data?.telephone}
              />
              <Form.Input
                required
                label="Διεύθυνση"
                name="address"
                value={getValue('address')}
                onChange={handleChange}
                error={saveRepairman.error?.response.data?.address}
              />
              <Form.Dropdown
                required
                selection
                label="Δήμος"
                name="municipality"
                value={formData.municipality || repairman.data?.municipality}
                onChange={handleChange}
                options={municipalities}
                error={saveRepairman.error?.response.data?.municipality}
              />
              <Form.Dropdown
                required
                selection
                label="Κατηγορίες ειδών προς επισκευή"
                placeholder={
                  isRepairmanProfile
                    ? 'Επιλέξτε τις κατηγορίες για τα υλικά που επισκευάζετε '
                    : 'Επιλέξτε τις κατηγορίες για τα υλικά που επισκευάζει ο επισκευαστής'
                }
                name="categories"
                multiple
                options={categories}
                value={formData.categories || repairman.data?.categories}
                onClick={() => saveRepairman.reset()}
                onChange={handleChange}
                error={
                  saveRepairman.error?.response.data?.categories &&
                  'Αυτό το πεδίο δεν μπορεί να είναι κενό'
                }
              />
              <Form.Input
                required
                label="Δραστηριότητα"
                name="profession"
                placeholder={
                  isRepairmanProfile
                    ? 'Συμπληρώστε το επάγγελμα σας'
                    : 'Συμπληρώστε το επάγγελμα του επισκευαστή'
                }
                value={getValue('profession')}
                onChange={handleChange}
                error={saveRepairman.error?.response.data?.profession}
              />

              <Divider />

              <Menu text>
                {isRepairmanProfile && (
                  <Menu.Item>
                    <Button
                      secondary
                      content="Αλλαγή συνθηματικού"
                      as={Link}
                      to={`${match.url}/password`}
                    />
                  </Menu.Item>
                )}
                {!isRepairmanProfile &&
                  repairman.data?.is_verified === false && (
                    <Menu.Item>
                      <ConfirmPopup
                        position="top center"
                        trigger={
                          <Button
                            type="button"
                            negative
                            content="Διαγραφή"
                            loading={deleteRepairman.isLoading}
                          />
                        }
                        onConfirm={handleDelete}
                        error={
                          deleteRepairman.isError &&
                          'Δεν ήταν δυνατή η διαγραφή του επισκευαστή'
                        }
                        onDismissError={() => deleteRepairman.reset()}
                        positive="Διαγραφή"
                        negative="Επιστροφή"
                      >
                        <Header size="small" dividing>
                          Διαγραφή επισκευαστή
                        </Header>
                        <p style={{ marginBottom: '1em' }}>
                          Η διαδικασία είναι <strong>μη αντιστρέψιμη</strong>{' '}
                          και ενδείκνυται παρά μόνο σε εξαιρετικές περιπτώσεις.
                          Οι πληροφορίες για τυχόν υπάρχουσες προσφορές θα
                          διαγραφούν επίσης.
                        </p>
                      </ConfirmPopup>
                    </Menu.Item>
                  )}
                {!isRepairmanProfile &&
                  repairman.data?.is_verified === true && (
                    <Menu.Item>
                      <ConfirmPopup
                        position="top center"
                        wide
                        trigger={
                          <Button
                            type="button"
                            negative
                            content="Απενεργοποίηση"
                            loading={disableRepairman.isLoading}
                          />
                        }
                        onConfirm={handleDisable}
                        error={
                          deleteRepairman.isError &&
                          'Δεν ήταν δυνατή η απενεργοποίηση του επισκευαστή'
                        }
                        onDismissError={() => disableRepairman.reset()}
                        positive="Απενεργοποίηση"
                        negative="Επιστροφή"
                      >
                        <Header size="small" dividing>
                          Απενεργοποίηση Επισκευαστή
                        </Header>
                        <p>
                          Με την απενεργοποίηση του επισκευαστή, δεν θα είναι
                          πλέον δυνατή προσθήκη προσφορών απο τον επισκευαστή
                          εκτός και αν τον ενεργοποιήσετε εκ νέου.
                        </p>
                        <p style={{ marginBottom: '1em' }}>
                          Επίσης, τυχόν ενεργές προσφορές του επισκευαστή δεν θα
                          είναι διαθέσιμες
                        </p>
                      </ConfirmPopup>
                    </Menu.Item>
                  )}

                <Menu.Menu position="right">
                  {!isRepairmanProfile &&
                    repairman.data?.is_verified === false && (
                      <Menu.Item>
                        <ConfirmPopup
                          position="right center"
                          trigger={
                            <Button
                              type="button"
                              positive
                              content="Ενεργοποίηση"
                              loading={verifyRepairman.isLoading}
                            />
                          }
                          onConfirm={handleEnable}
                          onDismissError={() => verifyRepairman.reset()}
                        />
                      </Menu.Item>
                    )}
                  <Menu.Item>
                    <Form.Button
                      primary
                      content="Ενημέρωση"
                      disabled={!formChanged}
                      loading={saveRepairman.isLoading}
                    />
                  </Menu.Item>
                  <Menu.Item>
                    <Form.Button
                      type="reset"
                      content="Επαναφορά"
                      onClick={handleReset}
                      disabled={!formChanged}
                    />
                  </Menu.Item>
                </Menu.Menu>
              </Menu>
              <Message
                success
                header="Επιτυχία"
                content="Η ενυμέρωση ολοκληρώθηκε"
                onDismiss={() => saveRepairman.reset()}
              />
            </Form>
          </Segment>
        </Container>
      </Route>
      {isRepairmanProfile && (
        <Route path={`${match.path}/password`}>
          <PasswordChangeForm />
        </Route>
      )}
    </>
  );
}
