import { Grid } from 'semantic-ui-react';

import StatCard from './StatCard';

import { useVisitorsStats } from '../../queries/dashboard/transactions';

export default function VisitorStats({ params, raised }) {
  const { data: visitorCounts } = useVisitorsStats(params);

  const { participants = 0, anonymous = 0 } = visitorCounts ?? {};

  return (
    <Grid stackable columns={2}>
      <Grid.Column>
        <StatCard
          title="Εγγεγραμμένοι χρήστες"
          value={participants}
          icon="user"
          color="purple"
          raised={raised}
        />
      </Grid.Column>
      <Grid.Column>
        <StatCard
          title="Μη εγγεγραμμένοι χρήστες"
          value={anonymous}
          icon="user secret"
          color="grey"
          raised={raised}
        />
      </Grid.Column>
    </Grid>
  );
}
