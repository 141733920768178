import { useMemo, useState } from 'react';
import {
  generatePath,
  useHistory,
  useParams,
  useRouteMatch,
} from 'react-router-dom';

import {
  Button,
  Divider,
  Dropdown,
  Form,
  List,
  Modal,
  Placeholder,
  Radio,
} from 'semantic-ui-react';
import Barcode from 'react-barcode';
import SemanticDatepicker from 'react-semantic-ui-datepickers';

import { convertArrayToOptions } from '../utils/utils';

import { useCategories } from '../queries/categories';
import {
  useCreateMaterial,
  useDeleteMaterial,
  useDeleteMaterialImage,
  useMaterial,
  useMaterials,
  useMaterialsStockExport,
  useUpdateMaterial,
  useUploadMaterialImage,
} from '../queries/materials';
import { useSubcategories } from '../queries/subcategories';
import { useKdeys } from '../queries/kdeys';
import useProfile from '../queries/useProfile';

import { ConfirmPopup, ImageModal } from '@zerowaste/components';
import ManageDataContainer from './ManageDataContainer';
import SearchForm from './SearchForm';

import useCreateUpdate from '../hooks/useCreateUpdate';
import SuccessMessage, { ErrorMessage } from './SuccessMessage';

import omit from 'lodash/omit';

function MaterialImageModal({ onClose }) {
  const { materialId } = useParams();
  const profile = useProfile();
  const isSupervisor = profile.data?.is_supervisor;
  const isEmployee = profile.data?.is_employee;

  const material = useMaterial(materialId);

  const status = {
    isLoading: material.isLoading,
    isFetching: material.isFetching,
    isFetched: material.isFetched,
  };

  return (
    <ImageModal
      mutationId={materialId}
      mutationType="materials"
      useUploadMutation={useUploadMaterialImage}
      useDeleteMutation={useDeleteMaterialImage}
      images={material.data?.images}
      status={status}
      onClose={onClose}
      readOnly={!(isEmployee || isSupervisor)}
    />
  );
}

function MaterialsStockExportButton({ filter }) {
  const profile = useProfile();
  const isEmployeeOnly =
    profile.data?.is_employee && !profile.data?.is_supervisor;
  const allowedRecycleCenter = isEmployeeOnly
    ? profile.data.recycle_center
    : null;

  const [modalOpen, setModalOpen] = useState(false);

  const [selectedDate, setSelectedDate] = useState();
  const [recycleCenter, setRecycleCenter] = useState();

  const exportExcel = useMaterialsStockExport({
    recycle_center: allowedRecycleCenter || recycleCenter,
    created: selectedDate,
    ...filter,
  });

  const handleDownload = () => {
    exportExcel.refetch().then(({ data }) => {
      if (data) {
        const href = URL.createObjectURL(data);
        window.location = href;
        URL.revokeObjectURL(href);
      }
      // close export modal
      setModalOpen(false);
    });
  };

  const recycleCenters = useKdeys();

  const allowedRecycleCenterName = useMemo(
    () =>
      recycleCenters.data?.find(({ id }) => allowedRecycleCenter === id)?.name,
    [allowedRecycleCenter, recycleCenters]
  );

  return (
    <>
      <Button
        active={modalOpen}
        onClick={() => setModalOpen(true)}
        icon="download"
        labelPosition="left"
        content="Εξαγωγή σε Excel"
      />
      <Modal
        size="tiny"
        dimmer="inverted"
        open={modalOpen}
        onClose={() => setModalOpen(false)}
      >
        <Modal.Header>Εξαγωγή σε Excel</Modal.Header>
        <Modal.Content as={Form}>
          {allowedRecycleCenterName ? (
            <>
              Θα εξαγάγετε την διαθεσιμότητα των υλικών για το{' '}
              <strong>{allowedRecycleCenterName}</strong>
            </>
          ) : (
            <>
              <p>
                Για ποιο ΚΔΕΥ θέλετε να εξαγάγετε την διαθεσιμότητα των υλικών;
              </p>
              <Form.Field>
                <Radio
                  label="Όλα τα ΚΔΕΥ"
                  name="recycle_center"
                  checked={!Boolean(recycleCenter)}
                  onChange={() => setRecycleCenter()}
                />
              </Form.Field>
              {recycleCenters.data?.map(({ id, name }) => (
                <Form.Field key={id}>
                  <Radio
                    label={name}
                    name="recycle_center"
                    value={id}
                    checked={recycleCenter === id}
                    onChange={() => setRecycleCenter(id)}
                  />
                </Form.Field>
              ))}
            </>
          )}

          <p>
            Μπορείτε προαιρετικά να επιλέξετε την ημερομηνία για την οποία
            θέλετε να εξαγάγετε την διαθεσιμότητα των υλικών:
          </p>
          <SemanticDatepicker
            format="DD/MM/YYYY"
            locale="el-GR"
            datePickerOnly
            placeholder="Ημερομηνία"
            value={selectedDate}
            onChange={(e, { value }) => {
              setSelectedDate(value);
            }}
            maxDate={new Date()}
            showToday={false}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button
            icon="download"
            primary
            content="Εξαγωγή"
            onClick={handleDownload}
            disabled={exportExcel.isFetching}
            loading={exportExcel.isFetching}
          />
          <Button
            floated="left"
            onClick={() => setModalOpen(false)}
            content="Ακύρωση"
          />
        </Modal.Actions>
      </Modal>
    </>
  );
}

function MaterialFilter({ loading, values, onChange, resetPage }) {
  const { category, subcategory, search } = values;

  const categories = useCategories({
    params: { page_size: 400 },
  });
  const subcategories = useSubcategories({
    params: { page_size: 400, category },
    options: { enabled: !!category },
  });

  return (
    <div style={{ display: 'flex' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '8px',
        }}
      >
        <Dropdown
          className="ellipsis"
          style={{ width: '250px' }}
          clearable
          selection
          value={category}
          name="category"
          onChange={(e, { name, value }) => {
            onChange(e, { name, value: value === '' ? null : value });
            onChange(e, { name: 'subcategory', value: null });
            resetPage && resetPage();
          }}
          placeholder="Όλες οι κατηγορίες"
          selectOnBlur={false}
          loading={categories.isLoading}
          options={convertArrayToOptions({
            array: categories.data?.results,
            key: 'id',
            text: 'name',
            value: 'id',
          })}
        />
        <Dropdown
          className="ellipsis"
          style={{ width: '250px' }}
          clearable
          selection
          disabled={!category}
          value={subcategory}
          name="subcategory"
          onChange={(e, { name, value }) => {
            onChange(e, { name, value });
            resetPage && resetPage();
          }}
          placeholder="Όλες οι υποκατηγορίες"
          selectOnBlur={false}
          loading={subcategories.isLoading}
          options={convertArrayToOptions({
            array: subcategories.data?.results,
            key: 'id',
            text: 'name',
            value: 'id',
          })}
        />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
          alignItems: 'flex-end',
        }}
      >
        <SearchForm
          name="search"
          mobile
          value={search}
          loading={loading}
          onSearch={(name, value, e) => {
            onChange(e, { name, value });
            resetPage && resetPage();
          }}
        />

        <MaterialsStockExportButton filter={values} />
      </div>
    </div>
  );
}

function MaterialForm({ baseUrl, onClose }) {
  const { materialId } = useParams();

  const profile = useProfile();
  const isSupervisor = profile.data?.is_supervisor;
  const isEmployee = profile.data?.is_employee;

  const {
    formData,
    getValue,
    handleSubmit,
    handleChange,
    handleDelete,
    isNew,
    isUpdated,
    codeError,
    serverError,
    formError,
    isMutationLoading,
    isDeleteLoading,
    type: material,
  } = useCreateUpdate({
    typeId: materialId,
    baseUrl: `${baseUrl}/material`,
    deleteUrl: baseUrl,
    typeName: 'materials',
    sideEffect: 'subcategories',
    useCreateMutation: useCreateMaterial,
    useUpdateMutation: useUpdateMaterial,
    useDeleteMutation: useDeleteMaterial,
    useType: useMaterial,
  });

  const isButtonDisabled =
    materialId === 'new' && !(formData.body && formData.subcategory_id);

  const categories = useCategories({
    params: { page_size: 400 },
    options: { enabled: isSupervisor },
  });

  const activeCategoryId =
    formData.category === undefined
      ? material.data?.subcategory.category.id
      : formData.category;

  const subcategories = useSubcategories({
    params: {
      page_size: 400,
      category: activeCategoryId,
    },
    options: { enabled: isSupervisor && activeCategoryId != null },
  });

  const activeSubcategory =
    formData.subcategory_id === undefined
      ? material.data?.subcategory
      : subcategories.data?.results?.find(
          ({ id }) => id === formData.subcategory_id
        );

  const activeSubcategoryId = activeSubcategory?.id;

  return (
    <>
      {isNew && <SuccessMessage message="Το υλικό δημιουργήθηκε επιτυχώς!" />}
      {isUpdated && <SuccessMessage message="Το υλικό ενημερώθηκε επιτυχώς!" />}
      {isSupervisor && (
        <Form autoComplete="off" id="material-form" onSubmit={handleSubmit}>
          <Form.Dropdown
            className="ellipsis"
            selection
            compact
            label="Κατηγορία"
            name="category"
            value={activeCategoryId}
            onChange={(e, { name, value }) => {
              handleChange(e, { name, value });
              handleChange(e, { name: 'subcategory_id', value: null });
            }}
            placeholder="Επιλέξτε κατηγορία"
            loading={categories.isLoading}
            options={convertArrayToOptions({
              array: categories.data?.results,
              key: 'id',
              text: 'name',
              value: 'id',
            })}
          />
          <Form.Dropdown
            disabled={!activeCategoryId}
            className="ellipsis"
            selection
            label="Υποκατηγορία"
            name="subcategory_id"
            value={activeSubcategoryId}
            onChange={handleChange}
            placeholder="Επιλέξτε υποκατηγορία"
            loading={subcategories.isLoading}
            options={
              subcategories.data?.results?.map(({ id, name }) => ({
                key: id,
                text: name,
                value: id,
              })) || []
            }
          />

          <Form.Group widths={2}>
            <Form.Input
              readOnly
              fluid
              label="Πόντοι παραλαβής"
              value={activeSubcategory?.pick_up_points ?? ''}
            />
            <Form.Input
              readOnly
              fluid
              label="Πόντοι παράδοσης"
              value={activeSubcategory?.turn_in_points ?? ''}
            />
          </Form.Group>

          <Form.TextArea
            required
            label="Όνομα"
            name="body"
            value={getValue('body')}
            onChange={handleChange}
          />
          <Form.Input
            label="Κωδικός (αφήστε το κενό για αυτόματη απόδοση κωδικού)"
            name="code"
            className={isNew ? 'highlighted' : ''}
            type="number"
            value={getValue('code')}
            onChange={handleChange}
            error={codeError}
          />

          <Form.Checkbox
            label="Παραλαβή μέσω ηλεκτρονικής αίτησης"
            name="eligible"
            disabled={!!getValue('has_items')}
            checked={!!getValue('eligible')}
            onChange={(e, { name, checked }) => {
              handleChange(e, { name, value: checked });
            }}
          />

          {serverError && (
            <ErrorMessage message="Προέκυψε κάποιο σφάλμα, παρακαλώ προσπαθήστε ξανά" />
          )}
          {formError && (
            <ErrorMessage message="Υπάρχει κάποιο σφάλμα με την φόρμα. Παρακαλώ ελέγξτε τα στοιχεία και προσπαθήστε ξανά" />
          )}
        </Form>
      )}
      {materialId !== 'new' && (
        <>
          {isSupervisor && <Divider hidden />}
          <List
            className="material-description full-width"
            divided
            relaxed="very"
          >
            {isEmployee && !isSupervisor && (
              <>
                <List.Item>
                  <List.Header>Όνομα:</List.Header>
                  <List.Content floated="right">
                    {material.data?.body}
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Header>Κατηγορία:</List.Header>
                  <List.Content floated="right">
                    {material.data?.subcategory.category.name}
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Header>Υποκατηγορία:</List.Header>
                  <List.Content floated="right">
                    {material.data?.subcategory.name}
                  </List.Content>
                </List.Item>
              </>
            )}
            <List.Item>
              <List.Header>Διαθεσιμότητα (σύνολο):</List.Header>
              <List.Content>
                {material.data?.amount > 0
                  ? material.data?.amount
                  : 'Μη διαθέσιμο'}
              </List.Content>
            </List.Item>
            {material.data?.availability.map((kdey) => (
              <List.Item
                key={kdey.recycle_center.id}
                style={{ paddingLeft: '8px' }}
              >
                <List.Content>{kdey.recycle_center.name}:</List.Content>
                <List.Content>{kdey.amount}</List.Content>
              </List.Item>
            ))}
            <List.Item>
              <List.Header>Barcode:</List.Header>
              <List.Content floated="right">
                {material.data?.barcode}
              </List.Content>
            </List.Item>
          </List>
          <div style={{ textAlign: 'center' }}>
            {material.isLoading ? (
              <Placeholder style={{ margin: '8px auto' }}>
                <Placeholder.Image />
              </Placeholder>
            ) : (
              <Barcode value={material.data?.barcode} />
            )}
          </div>
        </>
      )}
      <Divider hidden />
      {isSupervisor && (
        <Button
          disabled={isButtonDisabled}
          loading={isMutationLoading}
          form="material-form"
          primary
          content={materialId === 'new' ? 'Αποθήκευση' : 'Ενημέρωση'}
        />
      )}
      <Button
        onClick={(e) => {
          e.preventDefault();
          onClose();
        }}
        content={materialId === 'new' ? 'Ακύρωση' : 'Επιστροφή'}
      />
      {isSupervisor &&
        material.data?.transaction_count.total_transactions === 0 && (
          <ConfirmPopup
            position="right center"
            header="Θέλετε να διαγραφεί το υλικό αυτό;"
            trigger={
              <Button
                negative
                floated="right"
                content="Διαγραφή"
                loading={isDeleteLoading}
                disabled={isDeleteLoading}
              />
            }
            onConfirm={handleDelete}
          />
        )}
    </>
  );
}

export default function AdminMaterials() {
  const profile = useProfile();
  const isSupervisor = profile.data?.is_supervisor;
  const isEmployee = profile.data?.is_employee;

  const pageTitle = isSupervisor
    ? 'Δημιουργία / Επεξεργασία υλικού'
    : 'Προβολή υλικών';

  const match = useRouteMatch();
  const history = useHistory();
  const params = useParams();
  const { page } = params;
  const baseUrl = generatePath(match.path, omit(params, ['page', 'pageKey']));

  const [filters, setFilters] = useState(() => ({}));

  const [ordering, setOrdering] = useState('barcode');

  const materials = useMaterials({
    params: {
      page,
      ordering,
      ...filters,
    },
    options: { keepPreviousData: true },
  });

  const handleFilterChange = (e, { name, value }) => {
    setFilters((filters) => ({ ...filters, [name]: value }));
  };

  return (
    <ManageDataContainer
      baseUrl={baseUrl}
      data={materials.data}
      loading={materials.isLoading}
      fetching={materials.isFetching && materials.isPreviousData}
      error={materials.isError}
      pageTitle={pageTitle}
      filters={
        <MaterialFilter
          resetPage={() => {
            history.replace(`${baseUrl}/page/1`);
          }}
          values={filters}
          onChange={handleFilterChange}
          loading={materials.isFetching}
        />
      }
      type="material"
      nameGenitive="υλικού"
      ordering={ordering}
      setOrdering={setOrdering}
      headerCells={['Κωδικός', 'Όνομα', 'Τεμάχια', 'Κατηγορία', 'Υποκατηγορία']}
      orderingNames={[
        'barcode',
        'body',
        'amount_in_stock',
        'subcategory__category__name',
        'subcategory__name',
      ]}
      dataAttributes={[
        'barcode',
        'body',
        'amount_in_stock',
        'subcategory.category.name',
        'subcategory.name',
      ]}
      form={
        <MaterialForm
          baseUrl={match.url}
          onClose={() => {
            history.push(match.url);
          }}
        />
      }
      imageModal={MaterialImageModal}
      readOnly={isEmployee && !isSupervisor}
    />
  );
}
