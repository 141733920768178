import { useState } from 'react';

import { ResponsiveContainer } from 'recharts';
import { Dropdown, Loader } from 'semantic-ui-react';

import { useCategories } from '../../queries/categories';
import { useMaterialAvailability } from '../../queries/dashboard/availability';
import { useSubcategories } from '../../queries/subcategories';
import ReusePieChart from './ReusePieChart';

export default function AvailabilityPieChart({ params }) {
  const [selectedCategory, setSelectedCategory] = useState('all');
  const categories = useCategories({ params: { page_size: 400 } });
  const subcategories = useSubcategories({
    params: { page_size: 400, category: selectedCategory },
    options: { enabled: selectedCategory !== 'all' },
  });

  const { data: materialAvailability, isFetching } = useMaterialAvailability(
    selectedCategory,
    params,
    { enabled: categories.isFetched }
  );

  const names =
    selectedCategory === 'all'
      ? categories.data?.results
      : subcategories.data?.results;

  const pieData = materialAvailability?.map((cat) => ({
    ...cat,
    name: names?.find((name) => name.id === cat.id)?.name,
  }));

  const handleCategoryChange = (e, { value }) => {
    setSelectedCategory(value);
  };

  return (
    <>
      <Dropdown
        style={{ maxWidth: '200px', display: 'flex', margin: '0 auto' }}
        className="ellipsis"
        button
        scrolling
        value={selectedCategory}
        loading={categories.isLoading}
        onChange={handleCategoryChange}
        options={[
          { key: 'all', value: 'all', text: 'Συνολικά' },
          ...(categories.data?.results.map(({ id, name }) => ({
            key: id,
            value: id,
            text: name,
          })) || []),
        ]}
      />
      <ResponsiveContainer width="100%" height={400}>
        {isFetching ? (
          <Loader active size="massive" />
        ) : (
          <ReusePieChart data={pieData} />
        )}
      </ResponsiveContainer>
    </>
  );
}
