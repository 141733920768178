import { Helmet } from 'react-helmet';
import {
  generatePath,
  Redirect,
  Route,
  useHistory,
  useParams,
  useRouteMatch,
} from 'react-router-dom';

import {
  Button,
  Container,
  Divider,
  Grid,
  Header,
  List,
  Message,
  Modal,
  Segment,
  Table,
} from 'semantic-ui-react';

import { OrientedPagination } from '@zerowaste/components';
import { useCategories } from '../queries/categories';
import { useOffers } from '../queries/offers';
import { useRepairman, useRepairmen } from '../queries/repairmen';

import { OfferCard } from './ReusePickup';

import { omit } from 'lodash';

function RepairmanInfoModal({ onClose }) {
  const { repairmanId } = useParams();
  const categories = useCategories({ params: { page_size: 400 } });
  const repairman = useRepairman(repairmanId, {
    enabled: categories.isFetched,
    select: (data) => ({
      ...data,
      categories: data.categories.map(
        (cat) =>
          categories.data?.results.find((catName) => catName.id === cat)?.name
      ),
    }),
  });
  const offers = useOffers({ repairman: repairmanId, is_available: true });
  return (
    <Modal size="small" open onClose={onClose} dimmer="inverted">
      <Modal.Header>Λεπτομέρειες Επισκευαστή</Modal.Header>
      <Modal.Content>
        <Segment
          as={Grid}
          columns="2"
          stackable
          centered
          basic
          loading={
            categories.isLoading || repairman.isLoading || offers.isLoading
          }
        >
          <Grid.Column width={16}>
            <List
              className="material-description full-width"
              divided
              relaxed="very"
            >
              <List.Item>
                <List.Header>Ονοματεπώνυμο:</List.Header>
                <List.Content floated="right">
                  {repairman.data?.full_name}
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Header>Τηλέφωνο:</List.Header>
                <List.Content floated="right">
                  {repairman.data?.telephone}
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Header>Επάγγελμα:</List.Header>
                <List.Content floated="right">
                  {repairman.data?.profession}
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Header>Κατηγορίες:</List.Header>
                <List.Content floated="right">
                  {repairman.data?.categories.join(', ')}
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Header>Διεύθυνση:</List.Header>
                <List.Content floated="right">
                  {repairman.data?.address}
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Header>Email:</List.Header>
                <List.Content floated="right">
                  {repairman.data?.email}
                </List.Content>
              </List.Item>
            </List>
          </Grid.Column>
          <Grid.Column width={16}>
            {offers.isFetched && offers.data?.length > 0 ? (
              <>
                <Header textAlign="center" as="h3">
                  Προσφορές
                </Header>
                <Grid columns="2" stackable stretched centered>
                  {offers.data?.map(
                    ({
                      id,
                      description,
                      title,
                      material,
                      material_name,
                      material_item,
                    }) => (
                      <Grid.Column key={id}>
                        <OfferCard
                          description={description}
                          title={title}
                          meta={`${material_name} ${material_item?.description || ''}`}
                          images={material_item?.images}
                          to={
                            material_item
                              ? `/pick-up/material/${material}/item/${material_item.id}`
                              : `/pick-up/material/${material}`
                          }
                        />
                      </Grid.Column>
                    )
                  )}
                </Grid>
              </>
            ) : (
              <Message
                info
                content="Δεν υπάρχουν ενεργές προσφορές για τον επισκευαστή αυτόν"
              />
            )}
          </Grid.Column>
        </Segment>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose} content="Επιστροφή" />
      </Modal.Actions>
    </Modal>
  );
}

function RepairmanList() {
  const match = useRouteMatch();
  const history = useHistory();
  const params = useParams();
  const { page } = params;
  const baseUrl = generatePath(match.path, omit(params, ['page', 'pageKey']));

  const categories = useCategories({ params: { page_size: 400 } });
  const repairmen = useRepairmen(
    { is_verified: true, page },
    {
      enabled: categories.isFetched,
      select: (data) => ({
        ...data,
        results: data.results.map((data) => ({
          ...data,
          categories: data.categories.map(
            (cat) =>
              categories.data?.results.find((catName) => catName.id === cat)
                ?.name
          ),
        })),
      }),
      keepPreviousData: true,
    }
  );

  const showPagination = repairmen.data?.total_pages > 1;
  const paginationProps = {
    padded: false,
    activePage: page,
    totalPages: repairmen.data?.total_pages,
    onPageChange: (e, { activePage }) =>
      history.push({ pathname: `${activePage}` }),
    disabled: repairmen.data?.total_pages === 1,
  };

  const handleModalClose = () => {
    history.push(match.url);
  };

  if (!repairmen.isPreviousData && repairmen.data?.total_pages > 1 && !page) {
    return <Redirect to={{ pathname: `${baseUrl}/page/1` }} />;
  }
  return (
    <Container>
      <Divider hidden />
      <Grid>
        <Grid.Row>
          <Grid.Column width={7}>
            {showPagination && <OrientedPagination {...paginationProps} />}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Segment
              basic
              loading={categories.isLoading || repairmen.isLoading}
              placeHolder={categories.isLoading || repairmen.isLoading}
            >
              <Table
                fixed
                celled
                selectable
                className="pointable"
                style={{
                  opacity:
                    repairmen.isFetching && repairmen.isPreviousData
                      ? '0.5'
                      : '1',
                }}
              >
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Ονοματεπώνυμο</Table.HeaderCell>
                    <Table.HeaderCell>Επάγγελμα</Table.HeaderCell>
                    <Table.HeaderCell>Κατηγορίες</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {repairmen.data?.results.map(
                    ({ id, full_name, categories, profession }) => (
                      <Table.Row
                        key={id}
                        onClick={(e) => {
                          e.target.tagName !== 'BUTTON' &&
                            history.push(`${match.url}/repairman/${id}`);
                        }}
                      >
                        <Table.Cell>{full_name}</Table.Cell>
                        <Table.Cell>{profession}</Table.Cell>
                        <Table.Cell>{categories.join(', ')}</Table.Cell>
                      </Table.Row>
                    )
                  )}
                  {repairmen.data?.results.length === 0 && (
                    <Table.Row>
                      <Table.Cell colSpan={3} textAlign="center">
                        Δεν υπάρχουν πληροφορίες για Επισκευαστές
                      </Table.Cell>
                    </Table.Row>
                  )}
                </Table.Body>
              </Table>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Route path={`${match.url}/repairman/:repairmanId(\\d+)`}>
        <RepairmanInfoModal onClose={handleModalClose} />
      </Route>
      <Divider section hidden />
    </Container>
  );
}

export default function ReuseRepairmen() {
  const match = useRouteMatch();
  const pageTitle = 'Επισκευαστές';

  return (
    <>
      <Helmet title={pageTitle} />
      <Container>
        <Header
          textAlign="center"
          as="h1"
          className="colored atmgreen"
          content={pageTitle}
        />
        <Divider className="orange" />
      </Container>
      <Route path={`${match.path}/:pageKey(page)?/:page(\\d+)?`}>
        <RepairmanList />
      </Route>
    </>
  );
}
