import { useCallback, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import {
  generatePath,
  Link,
  useHistory,
  useParams,
  useRouteMatch,
} from 'react-router-dom';

import { Button, Divider, Form, Icon, Message, Popup } from 'semantic-ui-react';

import useCreateUpdate from '../hooks/useCreateUpdate';

import {
  useDeleteMaterialItemImage,
  useDisableMaterialItem,
  useEnableMaterialItem,
  useMaterialItem,
  useMaterialItems,
  useUpdateMaterialItem,
  useUploadMaterialItemImage,
} from '../queries/materialItems';
import useProfile from '../queries/useProfile';

import ManageDataContainer from './ManageDataContainer';
import SuccessMessage, { ErrorMessage } from './SuccessMessage';
import SearchForm from './SearchForm';
import { ImageModal } from '@zerowaste/components';
import KdeyDropdown from './KdeyDropdown';

import { omit } from 'lodash';

function MaterialItemImageModal({ onClose }) {
  const { itemId } = useParams();

  const materialItem = useMaterialItem(itemId, { staleTime: Infinity });
  const status = {
    isLoading: materialItem.isLoading,
    isFetching: materialItem.isFetching,
    isFetched: materialItem.isFetched,
  };

  return (
    <ImageModal
      mutationId={itemId}
      mutationType="materialItems"
      images={materialItem.data?.images}
      useDeleteMutation={useDeleteMaterialItemImage}
      useUploadMutation={useUploadMaterialItemImage}
      status={status}
      onClose={onClose}
    />
  );
}

function MaterialItemsFilter({ filters, loading, onChange, resetPage }) {
  const { search } = filters;
  const profile = useProfile();

  useEffect(() => {
    if (profile.data?.is_employee) {
      onChange(null, {
        name: 'recycle_center',
        value: profile.data?.recycle_center,
      });
    }
  }, [profile.data, onChange]);

  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'end',
        gap: '8px',
      }}
    >
      {profile.data?.is_supervisor && (
        <KdeyDropdown
          kdey={filters.recycle_center}
          onSelect={(value, e) => {
            onChange(e, { name: 'recycle_center', value });
          }}
        />
      )}
      <SearchForm
        name="search"
        value={search}
        loading={loading}
        onSearch={(name, value, e) => {
          onChange(e, { name, value });
          resetPage?.();
        }}
      />
    </div>
  );
}

function MaterialItemForm({ baseUrl, onClose, parentUrl }) {
  const { itemId } = useParams();
  const queryClient = useQueryClient();

  const {
    handleSubmit,
    handleChange,
    getValue,
    isUpdated,
    serverError,
    formError,
    isMutationLoading,
    type: materialItem,
  } = useCreateUpdate({
    typeId: itemId,
    baseUrl: `${baseUrl}/item`,
    typeName: 'materialItems',
    useUpdateMutation: useUpdateMaterialItem,
    useType: useMaterialItem,
    extractCategoryId: true,
  });

  const enableMaterialItem = useEnableMaterialItem(itemId, () => {
    queryClient.invalidateQueries('materialItems');
  });
  const disableMaterialItem = useDisableMaterialItem(itemId, () => {
    queryClient.invalidateQueries('materialItems');
  });

  const handleEnable = () => {
    enableMaterialItem.mutate();
  };
  const handleDisable = () => {
    disableMaterialItem.mutate();
  };

  const { is_complete, has_offers, reservation } = materialItem.data || {};

  const toggleButton =
    has_offers || !!reservation || is_complete === true ? (
      <Button
        loading={disableMaterialItem.isLoading}
        disabled={disableMaterialItem.isLoading || has_offers || !!reservation}
        negative
        content="Απενεργοποίηση"
        onClick={handleDisable}
      />
    ) : (
      is_complete === false && (
        <Button
          loading={enableMaterialItem.isLoading}
          disabled={enableMaterialItem.isLoading}
          positive
          content="Ενεργοποίηση"
          onClick={handleEnable}
        />
      )
    );

  return (
    <>
      {isUpdated && (
        <SuccessMessage message="Το μοναδικό υλικό ενημερώθηκε επιτυχώς!" />
      )}
      <Form autoComplete="off" id="material-item-form" onSubmit={handleSubmit}>
        <Form.Input
          label="Κωδικός"
          name="barcode"
          readOnly
          value={getValue('barcode')}
        />
        <Form.Input
          label="Υλικό"
          name="material_name"
          readOnly
          value={getValue('material_name')}
        />
        <Form.TextArea
          required
          label="Περιγραφή"
          name="description"
          value={getValue('description')}
          onChange={handleChange}
        />

        {serverError && (
          <ErrorMessage message="Προέκυψε κάποιο σφάλμα, παρακαλώ προσπαθήστε ξανά" />
        )}
        {formError && (
          <ErrorMessage message="Υπάρχει κάποιο σφάλμα με την φόρμα. Παρακαλώ ελέγξτε τα στοιχεία και προσπαθήστε ξανά" />
        )}
      </Form>
      <Divider hidden />
      {materialItem.data?.reservation && (
        <Message
          info
          content={
            <>
              Αυτο το υλικό ειναι κρατημένο.
              <Link
                style={{ textDecoration: 'underline' }}
                to={`${parentUrl}/reservations/page/1/reservation/${materialItem.data?.reservation}`}
              >
                {' '}
                Δείτε εδώ την κράτηση
              </Link>
            </>
          }
        />
      )}
      {materialItem.data?.is_complete && !materialItem.data?.reservation ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'end',
            marginBottom: '16px',
          }}
        >
          <Button
            as={Link}
            to={`${parentUrl}/reservations/page/1/reservation/new/item/${materialItem.data?.id}`}
            positive
            content="Δημιουργία κράτησης"
          />
        </div>
      ) : (
        <Divider hidden />
      )}
      <Button
        floated="right"
        onClick={(e) => {
          e.preventDefault();
          onClose();
        }}
        content="Επιστροφή"
      />
      <Button
        floated="right"
        loading={isMutationLoading}
        form="material-item-form"
        primary
        content="Ενημέρωση"
      />
      {toggleButton}
      <Divider hidden clearing fitted />
    </>
  );
}

export default function AdminMaterialItems({ parentUrl }) {
  const pageTitle = 'Επεξεργασία μοναδικών υλικών';
  const match = useRouteMatch();
  const history = useHistory();
  const params = useParams();
  const { page } = params;
  const baseUrl = generatePath(match.path, omit(params, ['page', 'pageKey']));
  const profile = useProfile();
  const isSupervisor = profile.data?.is_supervisor;

  const [ordering, setOrdering] = useState('barcode');
  const [filters, setFilters] = useState(() => ({
    search: null,
  }));

  const handleChange = useCallback((e, { name, value }) => {
    setFilters((filters) => ({ ...filters, [name]: value }));
  }, []);

  const materialItems = useMaterialItems(
    { ordering, page, is_available: true, ...filters },
    {
      select: ({ results, ...data }) => ({
        results: results.map(({ collected_at, ...item }) => ({
            collected_at: new Date(collected_at),
            ...item,
          }))
          .map(({ collected_at, is_complete, ...item }) => ({
            collected_at: (
              <Popup
                trigger={<span>{collected_at.toLocaleDateString('el')}</span>}
              >
                <Popup.Content>
                  {collected_at.toLocaleTimeString('el')}
                </Popup.Content>
              </Popup>
            ),
            is_reserved: item.reservation ? <Icon name="check" color="green" /> : null,
            is_complete: is_complete ? <Icon name="check" color="green" /> : null,
            ...item,
          })),
        ...data,
      }),
      keepPreviousData: true,
    }
  );

  return (
    <ManageDataContainer
      baseUrl={baseUrl}
      data={materialItems.data}
      loading={materialItems.isLoading}
      fetching={materialItems.isPreviousData && materialItems.isFetching}
      error={materialItems.isError}
      pageTitle={pageTitle}
      type="item"
      nameGenitive="Μοναδικού υλικού"
      headerCells={
        isSupervisor
          ? ['Ημερομηνία', 'Κωδικός', 'Υλικό', 'Περιγραφή', 'ΚΔΕΥ', 'Κρατημένο', 'Ενεργό']
          : ['Ημερομηνία', 'Κωδικός', 'Υλικό', 'Περιγραφή', 'Κρατημένο', 'Ενεργό']
      }
      orderingNames={isSupervisor
        ? ['collected_in__created', 'barcode', 'collected_in__material__body', 'description', 'collected_in__recycle_center', 'is_reserved', 'is_complete']
        : ['collected_in__created', 'barcode', 'collected_in__material__body', 'description', 'is_reserved', 'is_complete']
      }
      ordering={ordering}
      setOrdering={setOrdering}
      dataAttributes={
        isSupervisor
          ? ['collected_at', 'barcode', 'material_name', 'description', 'recycle_center', 'is_reserved', 'is_complete']
          : ['collected_at', 'barcode', 'material_name', 'description', 'is_reserved', 'is_complete']
      }
      dataCellProps={{
        is_reserved: { textAlign: 'center' },
        is_complete: { textAlign: 'center' },
      }}
      disableAdd
      filters={
        <MaterialItemsFilter
          filters={filters}
          loading={materialItems.isLoading}
          resetPage={() => {
            history.replace(`${baseUrl}/page/1`);
          }}
          onChange={handleChange}
        />
      }
      form={
        <MaterialItemForm
          baseUrl={match.url}
          parentUrl={parentUrl}
          onClose={() => {
            history.push(match.url);
          }}
        />
      }
      imageModal={MaterialItemImageModal}
    />
  );
}
