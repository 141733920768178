import { Helmet } from 'react-helmet';
import {
  Redirect,
  Route,
  Switch,
  useRouteMatch,
  NavLink,
} from 'react-router-dom';

import { Container, Divider, Header, Menu } from 'semantic-ui-react';

import useProfile from '../queries/useProfile';
import AdminCategories from './AdminCategories';
import AdminMaterialItems from './AdminMaterialItems';
import AdminMaterials from './AdminMaterials';
import AdminReservations from './AdminReservations';
import AdminSubcategories from './AdminSubcategories';

export default function AdminMaterialManage() {
  const match = useRouteMatch();

  const profile = useProfile();
  const isSupervisor = profile.data?.is_supervisor;

  const pageTitle = isSupervisor ? 'Διαχείριση υλικών' : 'Προβολή υλικών';

  return (
    <>
      <Helmet title={pageTitle} />
      <Container>
        <Header
          as="h1"
          textAlign="center"
          className="colored atmgreen"
          content={pageTitle}
        />
        <Divider className="orange" />
        <Divider hidden />
      </Container>
      <Container>
        <Menu secondary pointing size="huge">
          {isSupervisor && (
            <>
              <Menu.Item as={NavLink} to={`${match.url}/categories`}>
                Κατηγορίες
              </Menu.Item>
              <Menu.Item as={NavLink} to={`${match.url}/subcategories`}>
                Υποκατηγορίες
              </Menu.Item>
            </>
          )}
          <Menu.Item as={NavLink} to={`${match.url}/materials`}>
            Υλικά
          </Menu.Item>
          <Menu.Item as={NavLink} to={`${match.url}/material-items`}>
            Μοναδικά υλικά
          </Menu.Item>
          <Menu.Item as={NavLink} to={`${match.url}/reservations`}>
            Κρατήσεις
          </Menu.Item>
        </Menu>
        <Switch>
          <Route path={match.path} exact>
            <Redirect
              to={`${match.url}/${isSupervisor ? 'categories' : 'materials'}`}
            />
          </Route>
          {isSupervisor && (
            <Route
              path={`${match.path}/categories/:pageKey(page)?/:page(\\d+)?`}
            >
              <AdminCategories />
            </Route>
          )}
          {isSupervisor && (
            <Route
              path={`${match.path}/subcategories/:pageKey(page)?/:page(\\d+)?`}
            >
              <AdminSubcategories />
            </Route>
          )}
          <Route path={`${match.path}/materials/:pageKey(page)?/:page(\\d+)?`}>
            <AdminMaterials />
          </Route>
          <Route
            path={`${match.path}/material-items/:pageKey(page)?/:page(\\d+)?`}
          >
            <AdminMaterialItems parentUrl={match.url} />
          </Route>
          <Route
            path={[
              `${match.path}/reservations/:pageKey(page)/:page(\\d+)`,
              `${match.path}/reservations/:reservationId(\\d+)`,
              `${match.path}/reservations`,
            ]}
          >
            <AdminReservations baseUrl={`${match.url}/reservations`} />
          </Route>
          <Route path="*">
            <Redirect to={match.url} />
          </Route>
        </Switch>
        <Divider section hidden />
      </Container>
    </>
  );
}
