import { useMutation, useQuery } from 'react-query';

import axios from 'axios';


export const fetchAnnouncements = ({ queryKey }) => {
  // eslint-disable-next-line no-unused-vars
  const [_key, params ] = queryKey;
  return axios.get('/api/announcements/', { params }).then(({data}) => data);
}

export const fetchAnnouncement = ({ queryKey }) => {
  // eslint-disable-next-line no-unused-vars
  const [_key, postId ] = queryKey;
  return axios.get(`/api/announcements/${postId}/`).then(({data}) => data);
};

export const announcementsKeys = {
  all: ['announcements'],
  list: (params) => ['announcements', params],
  detail: (postId) => ['announcement', postId],
};

export const useAnnouncements = (params, config) =>
  useQuery(params ? announcementsKeys.list(params) : announcementsKeys.all, fetchAnnouncements, config);

export const useAnnouncement = (postId, config) => {
  return useQuery(announcementsKeys.detail(postId), fetchAnnouncement, config);
};

export const useAnnouncementImages = (id, options) =>
  useQuery(
    ['announcementImages', id],
    () =>
      axios.get(`/api/announcements/${id}/images/`).then(({ data }) => data),
    { ...options }
  );

export const useSelectAnnouncementCover = (id) =>
  useMutation(
    (data) => axios.patch(`/api/announcement_images/${id}/`, data).then(({ data }) => data)
  );

