import { Link } from 'react-router-dom';
import { Header, Grid, Image, Icon, Segment, Container, List, Divider } from 'semantic-ui-react';

import becircularImg from '../images/becircular_logo.png';
import wasterreactImg from '../images/wasterreact_logo.png';

import diadymaImg from '../images/DIADYMA_L_WHITE.png';
import symbiolabsImg from '../images/SymbiolabsLogo.png';
import useProfile from '../queries/useProfile';

function ReuseFooter() {
  const profile = useProfile();
  return (
    <>
      <Segment basic className="footer-menu" inverted>
        <Grid doubling stackable container columns={4} style={{ marginTop: 0, marginBottom: 0}}>
          <Grid.Column>
            <Image href="https://diadyma.gr" src={diadymaImg} />
            <Divider hidden />

            <Image.Group size="medium">
              <Image
                rounded
                bordered
                href="https://becircularproject.eu/"
                src={becircularImg}
              />

              <Image
                rounded
                bordered
                href="https://keep.eu/projects/19510/Integrated-waste-management-EN/"
                src={wasterreactImg}
              />
            </Image.Group>

          </Grid.Column>
            
          <Grid.Column>
            <Header className="footer-header" inverted>Σχεδιασμός και ανάπτυξη</Header>
            <Image size="small" href="https://symbiolabs.gr" src={symbiolabsImg} />
          </Grid.Column>

          <Grid.Column>
            <Header className="footer-header" inverted>Επικοινωνία</Header>
            <List items={[
              '6ο χλμ. Κοζάνης - Πτολεμαΐδας',
              'Κόμβος Εγνατίας',
              'Τ.Κ. 50 100 - Τ.Θ. 155',
            ]} />

            <List items={[
              {
                key: 'tel',
                icon: <Icon className="t green-icon" />,
                content: '24610 45531 - 24610 45533',
              },
              {
                key: 'fax',
                icon: <Icon className="f green-icon" />,
                content: '24610 45532',
              },
            ]} />
            
            <List items={[{
              key: 'email',
              icon: <Icon name="mail outline" className="green-icon" />,
              content: 'zerowaste@diadyma.gr',
            }]} />

          </Grid.Column>

          <Grid.Column>
            <Header className="footer-header" inverted>Site map</Header>
            <List inverted className="footer-links" items={[
              { key: 'home', content: <Link to="/" className="footer-link">Αρχικη</Link> },
              { key: 'application-form', content: <Link to="/application-form" className="footer-link">Ηλεκτρονική Αίτηση Παραλαβής Υλικού</Link> },
              { key: 'reuse-centers', content: <Link to="/reuse-centers" className="footer-link">Κέντρα Επαναχρησιμοποίησης</Link> },
              { key: 'categories', content: <Link to="/pick-up" className="footer-link">Θέλω να παραλάβω</Link> },
              { key: 'turn-in', content: <Link to="/turn-in" className="footer-link">Θέλω να παραδώσω</Link> },
              { key: 'repairmen', content: <Link to="/repairmen" className="footer-link">Επισκευή</Link> },
              { key: 'questions', content: <Link to="/questions" className="footer-link">Συχνες ερωτησεις</Link> },
              { key: 'news', content: <Link to="/news" className="footer-link">Νεα</Link> },
            ]} />

           {!profile.data && <List inverted className="footer-links" items={[
              { key: 'repairman-signup', content: <Link to="/signup" className="footer-link">Εγγραφή επισκευαστή</Link> },
              { key: 'repairman-signin', content: <Link to={{ pathname: '/repair', state:{ cancelTo: window.location.pathname }}} className="footer-link">Σύνδεση επισκευαστή</Link> },
            ]} />}

            <List inverted className="footer-links" items={[
              { key: 'admin', content: <Link to={{ pathname: '/admin', state:{ cancelTo: window.location.pathname }}} className="footer-link">Περιβάλλον διαχείρισης</Link> },
            ]} />

            <List inverted className="footer-links" items={[
              { key: 'guide', content: <a href={`${process.env.PUBLIC_URL}/Reuse_User_Manual_v1.0.pdf`} target="_blank" rel="noreferrer" className="footer-link">Οδηγίες χρήσης</a> },
              { key: 'terms', content: <Link to="/terms" className="footer-link">Οροι και προϋποθεσεις</Link> },
            ]} />

          </Grid.Column>

        </Grid>

        <Container>
          <Header className="footer-header" inverted>&nbsp;</Header>

          <p>The Project co-funded by the European Union and national funds of the participating countries.<br />
            &copy; 2022. All Rights Reserved.
          </p>
          
          <p>DISCLAIMER<br/>
            This webpage has been produced with the financial assistance of the European Union.
            The contents of the webpage are the sole responsibility of Waste Management of Western Macedonia DIADYMA SA
            and can in no way be taken to reflect the views of the European Union, the participating countries and the Managing Authority.
          </p>
        </Container>

      </Segment>
    </>
  );
}

export default ReuseFooter;
