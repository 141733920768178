import { useState } from 'react';
import { useQueryClient } from 'react-query';
import {
  Link,
  Route,
  useHistory,
  useParams,
  useRouteMatch,
} from 'react-router-dom';

import {
  Button,
  Divider,
  Form,
  Header,
  Icon,
  Message,
  Modal,
  Segment,
  Table,
} from 'semantic-ui-react';

import { useMaterialItems } from '../../queries/materialItems';
import { useMaterials } from '../../queries/materials';
import {
  useCreateOffer,
  useDeleteOffer,
  useOffer,
  useOffers,
  useToggleOffer,
  useUpdateOffer,
} from '../../queries/offers';
import { useRepairman } from '../../queries/repairmen';
import { MaterialCard, MaterialItemList } from '../ReusePickup';

function MaterialItems({
  isMaterialItemListOpen,
  setIsMaterialItemListOpen,
  selectedItem,
  onSelect,
  selectedMaterial,
}) {
  const handleMaterialItemListToggle = () => {
    setIsMaterialItemListOpen((state) => !state);
  };

  const materialItems = useMaterialItems(
    {
      material: selectedMaterial.id,
      is_available: true,
      is_complete: true,
      page: 'all',
    },
    {
      enabled: selectedMaterial.eligible === true && isMaterialItemListOpen,
    }
  );
  return (
    <>
      <Button
        basic
        icon={`caret ${isMaterialItemListOpen ? 'up' : 'down'}`}
        content={
          isMaterialItemListOpen
            ? 'ακύρωση'
            : 'Επιλογή μοναδικών υλικών (προαιρετικό)'
        }
        labelPosition="right"
        style={{ marginTop: '16px', display: 'flex', marginLeft: 'auto' }}
        onClick={handleMaterialItemListToggle}
      />
      {isMaterialItemListOpen && (
        <>
          {materialItems.data?.length === 0 ? (
            <Message info>Δεν υπάρχουν διαθέσιμα μοναδικά υλικά</Message>
          ) : (
            <MaterialItemList
              selectedItem={selectedItem?.id}
              materialItems={materialItems.data}
              onSelect={onSelect}
              showKdeys={true}
              loading={materialItems.isLoading}
            />
          )}
        </>
      )}
    </>
  );
}

function OfferForm({ onClose, baseUrl, id }) {
  const history = useHistory();
  const { offerId } = useParams();

  const queryClient = useQueryClient();

  const onMutationSuccess = (data) => {
    queryClient.invalidateQueries('offers');
    offerId === 'new' ? history.replace(`${baseUrl}/${data.id}`) : onClose?.();
  };

  const createOffer = useCreateOffer();
  const updateOffer = useUpdateOffer(offerId);
  const deleteOffer = useDeleteOffer(offerId);
  const toggleOffer = useToggleOffer(offerId);

  const offer = useOffer(offerId, {
    enabled: offerId !== 'new',
  });

  const repairman = useRepairman(id);

  const [formData, setFormData] = useState(() => ({}));
  const [formChanged, setFormChanged] = useState(false);

  const handleSubmit = () => {
    const dataToSubmit = { ...formData };
    if (dataToSubmit.material_item) {
      // make sure we send the id
      dataToSubmit.material_item = dataToSubmit.material_item.id;

      // API does not like when sending both
      delete dataToSubmit.material;
    }

    if (offerId === 'new') {
      dataToSubmit.repairman = id;
      createOffer.mutate(dataToSubmit, { onSuccess: onMutationSuccess });
    } else {
      updateOffer.mutate(dataToSubmit, { onSuccess: onMutationSuccess });
    }
  };

  const handleChange = (e, { name, value }) => {
    setFormChanged(true);
    setFormData((data) => ({
      ...data,
      [name]: value,
    }));
  };

  const handleOfferToggle = () =>
    toggleOffer.mutate(
      {},
      {
        onSuccess: () => {
          queryClient.invalidateQueries('offers');
        },
      }
    );
  const handleOfferDelete = () =>
    deleteOffer.mutate(
      {},
      {
        onSuccess: () => {
          queryClient.invalidateQueries('offers');
          onClose?.(true);
        },
      }
    );

  const materials = useMaterials({
    params: {
      page_size: 400,
      ordering: 'barcode',
      category: repairman.data?.categories,
    },
    options: {
      select: (data) => {
        // transform to options format
        return data.results.map((m) => ({
          key: m.id,
          value: JSON.stringify(m),
          text: m.body,
        }));
      },
      enabled: !!repairman.data?.categories,
    },
  });

  const getValue = (name) =>
    formData[name] === undefined ? offer.data?.[name] : formData[name] ?? '';

  const selectedMaterial = JSON.parse(
    materials.data?.find(
      (material) => JSON.parse(material.value).id === getValue('material')
    )?.value || '{}'
  );

  const handleMaterialItemSelect = (value) => {
    setFormChanged(true);
    setFormData((data) => ({
      ...data,
      material_item: value || null,
    }));
  };

  const [isMaterialItemListOpen, setIsMaterialItemListOpen] = useState(false);
  //find if the repairman has offer for material item
  const materialItem = getValue('material_item');

  const displaySelectedMaterialItem =
    materialItem?.material === getValue('material') &&
    formData.material_item !== null;

  return (
    <Modal size="small" dimmer="inverted" open onClose={onClose}>
      <Modal.Header>
        {offerId === 'new' ? 'Καταχώρηση νέας προσφοράς' : 'Μεταβολή προσφοράς'}
      </Modal.Header>
      <Modal.Content>
        <Form id="new-offer" onSubmit={handleSubmit}>
          <Form.Input
            required
            label="Τιτλος"
            placeholder="Προσθέστε τίτλο για την προσφορά"
            name="title"
            value={getValue('title') || ''}
            onChange={handleChange}
          />
          <Form.TextArea
            label="Περιγραφή"
            name="description"
            placeholder="Προσθέστε περιγραφή για την προσφορά (προαιρετικό)"
            value={getValue('description') || ''}
            onChange={handleChange}
          />
          <Form.Dropdown
            loading={offer.isLoading || materials.isLoading}
            error={
              createOffer.error?.response?.data?.material &&
              'Αυτό το πεδίο δεν μπορεί να είναι κενό'
            }
            selection
            label="Υλικό προς επισκευή"
            name="material"
            placeholder="Επιλέξτε το υλικό που αφορά αυτη η προσφορά"
            value={
              materials.data?.find(
                (material) =>
                  JSON.parse(material.value).id === getValue('material')
              )?.value || ''
            }
            options={materials.data}
            onClick={() => createOffer.reset()}
            onChange={(e, { name, value }) => {
              setIsMaterialItemListOpen(false);
              handleChange(e, { name, value: JSON.parse(value).id });
              handleMaterialItemSelect(undefined);
            }}
          />
        </Form>
        {selectedMaterial.eligible && (
          <>
            <MaterialItems
              isMaterialItemListOpen={isMaterialItemListOpen}
              setIsMaterialItemListOpen={setIsMaterialItemListOpen}
              onSelect={handleMaterialItemSelect}
              selectedItem={getValue('material_item')}
              selectedMaterial={selectedMaterial}
            />
            {displaySelectedMaterialItem && !isMaterialItemListOpen && (
              <>
                <Header textAlign="center">Επιλεγμένο μοναδικό υλικό</Header>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <MaterialCard
                    images={materialItem?.images}
                    meta={materialItem?.material_name}
                    title={materialItem?.description}
                    button={
                      <>
                        <Button
                          primary
                          content="Αλλαγή"
                          onClick={() => {
                            setIsMaterialItemListOpen(true);
                          }}
                        />
                        <Button
                          negative
                          content="Αφαίρεση"
                          onClick={() => {
                            handleMaterialItemSelect(null);
                          }}
                        />
                      </>
                    }
                  />
                </div>
              </>
            )}
          </>
        )}
      </Modal.Content>
      <Modal.Actions>
        {offerId !== 'new' && (
          <>
            {!createOffer.isLoading && (
              <Button
                floated="left"
                negative
                content={offer.data?.is_active ? 'Απενεργοποίηση' : 'Διαγραφή'}
                onClick={
                  offer.data?.is_active ? handleOfferToggle : handleOfferDelete
                }
                loading={
                  offer.data?.is_active
                    ? toggleOffer.isLoading
                    : deleteOffer.isLoading
                }
              />
            )}
            {offer.data?.is_active === false && (
              <Button
                positive
                content="Ενεργοποίηση"
                onClick={handleOfferToggle}
                loading={toggleOffer.isLoading}
              />
            )}
          </>
        )}

        <Button
          form="new-offer"
          primary
          content={offerId === 'new' ? 'Αποθήκευση' : 'Ενημέρωση'}
          loading={createOffer.isLoading || updateOffer.isLoading}
          disabled={!formChanged}
        />
        <Button
          onClick={() => onClose?.()}
          content={offerId === 'new' ? 'Ακύρωση' : 'Επιστροφή'}
        />
      </Modal.Actions>
    </Modal>
  );
}

function RowWithMaterialItems({
  offerId,
  title,
  materialName,
  materialItem,
  isActive,
}) {
  const history = useHistory();
  const match = useRouteMatch();

  return (
    <Table.Row onClick={() => history.push(`${match.url}/${offerId}`)}>
      <Table.Cell>{title}</Table.Cell>
      <Table.Cell>{materialName}</Table.Cell>
      <Table.Cell>{materialItem?.description}</Table.Cell>
      <Table.Cell textAlign="center">
        {isActive && <Icon name="check" color="green" />}
      </Table.Cell>
    </Table.Row>
  );
}

export default function RepairmanOffersTable({ repairmanId }) {
  const history = useHistory();
  const match = useRouteMatch();
  const params = useParams();
  if (!repairmanId) {
    repairmanId = params.repairmanId;
  }

  const offers = useOffers({ repairman: repairmanId });

  const handleOfferClose = (deleted) => {
    if (deleted) {
      history.replace(match.url);
    } else {
      history.push(match.url);
    }
  };

  return (
    <Segment basic loading={offers.isLoading}>
      <Table celled selectable className="pointable">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Τίτλος</Table.HeaderCell>
            <Table.HeaderCell colSpan={2}>Υλικό</Table.HeaderCell>
            <Table.HeaderCell collapsing>Ενεργή</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {offers.data?.map(
            ({ id, title, material_name, is_active, material_item }) => (
              <RowWithMaterialItems
                key={id}
                offerId={id}
                title={title}
                materialName={material_item?.material_name ?? material_name}
                isActive={is_active}
                materialItem={material_item}
              />
            )
          )}
          {offers.data?.length === 0 && (
            <Table.Row>
              <Table.Cell colSpan={3} textAlign="center">
                Δεν υπάρχουν καταχωρημένες προσφορές
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>

      <Button
        floated="right"
        positive
        icon="plus"
        labelPosition="left"
        content="Προσθήκη προσφοράς"
        as={Link}
        to={`${match.url}/new`}
      />
      <Divider clearing hidden />

      <Route path={`${match.path}/:offerId(\\d+|new)`}>
        <OfferForm
          onClose={handleOfferClose}
          baseUrl={match.url}
          id={repairmanId}
        />
      </Route>
    </Segment>
  );
}
