import { useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useHistory } from 'react-router-dom';

import {
  Container,
  Divider,
  Grid,
  Header,
  Image,
  List,
  Segment,
} from 'semantic-ui-react';

import useProfile from '../queries/useProfile';

import pickupImg from '../images/pickup.png';
import turninImg from '../images/turnin.png';
import infoImg from '../images/info.png';

function ReuseIntro({ onRegister }) {
  const history = useHistory();
  const profile = useProfile();

  const isStaff = profile.data && !profile.data.participant;

  const handleRegisterButton = useCallback(() => {
    if (profile.data) {
      // logged in
      history.push('/profile');
    } else {
      onRegister();
    }
  }, [profile, history, onRegister]);
  const actionHeaders = {
    register: (
      <List.Item
        as={isStaff ? Link : 'a'}
        to={isStaff ? '/admin' : undefined}
        onClick={isStaff ? undefined : handleRegisterButton}
      >
        Κάνε εγγραφή!
      </List.Item>
    ),
    points: (
      <List.Item as={Link} to="/questions#rewards">
        Κέρδισε πόντους!
      </List.Item>
    ),
    rewards: (
      <List.Item as="a" href="https://rewards.diadyma.gr">
        Εξαργύρωσε τους πόντους που μάζεψες!
      </List.Item>
    ),
  };
  return (
    <>
      <Helmet title="Αρχική" />
      <Container textAlign="center">
        <Header as="h3" size="large" className="atmgreen" textAlign="center">
          Βρες αυτό που χρειάζεσαι! Δώσε και πάρε υλικά χωρίς κόστος!!!
        </Header>
        <Divider hidden />
        <div className="intro-steps">
          <Header as="h3">
            Επαναχρησιμοποίησε και βγες κερδισμένος σε 3 απλά βήματα
          </Header>
          <List ordered>
            {actionHeaders.register}
            {actionHeaders.points}
            {actionHeaders.rewards}
          </List>
          <p>
            Δώσε και πάρε υλικά προς επαναχρησιμοποίηση και βγες κερδισμένος!
          </p>
        </div>
        <Divider hidden />
        <Grid className="compact" stackable stretched columns={2}>
          <Grid.Column>
            <Header
              as={Link}
              to="/pick-up/"
              block
              size="huge"
              className="action-header blue"
            >
              Θέλω να παραλάβω
            </Header>
          </Grid.Column>

          <Grid.Column>
            <Header
              as={Link}
              to="/turn-in/"
              block
              size="huge"
              className="action-header green"
            >
              Θέλω να παραδώσω
            </Header>
          </Grid.Column>
        </Grid>
        <Divider hidden />
        <Divider className="orange" />
      </Container>
      <Segment padded="very" attached basic className=" colored lightgreen">
        <Container text>
          <Header as="h3">Λίγα λόγια για την επαναχρησιμοποίηση</Header>
          <p>
            Η επαναχρησιμοποίηση είναι η πλέον επιθυμητή επιλογή σε σχέση με
            άλλες πρακτικές διαχείρισης των αποβλήτων, λόγω του ότι αυξάνεται ο
            χρόνος ζωής των υλικών, εξοικονομείται ενέργεια και πολύτιμες πρώτες
            ύλες, καθώς επίσης μειώνεται ο όγκος των αποβλήτων που οδηγούνται
            προς ταφή.
          </p>
          <p>
            Το 2020 η Δυτική Μακεδονία περνάει στην εποχή της
            επαναχρησιμοποίησης, με τη λειτουργία των Κέντρων Δημιουργικής
            Επαναχρησιμοποίησης Υλικών (ΚΔΕΥ). Τα ΚΔΕΥ αποτελούν μια απλή
            κτιριακή υποδομή για την ταξινόμηση, την εν δυνάμει επιδιόρθωση και
            την αποθήκευση των υλικών, τα οποία θα διαθέτουν και χώρους έκθεσης
            των υλικών. Τα υλικά που δέχονται τα ΚΔΕΥ πρέπει να είναι σε καλή
            κατάσταση και οι ηλεκτρικές συσκευές να λειτουργούν.
          </p>
          <p style={{ fontWeight: 'bold' }}>
            Ο κάθε πολίτης μπορεί είτε να παραδίδει αντικείμενα που δεν είναι
            απόβλητα και δεν τα χρειάζεται, είτε να αποκτά υλικά από τους χώρους
            των ΚΔΕΥ, χωρίς κόστος.
          </p>
          <p>Το ωράριο επίσκεψης στους χώρους των ΚΔΕΥ είναι:</p>
          <List>
            <List.Item>Καθημερινά: 08:00-15:00</List.Item>
            <List.Item>Σάββατο: 09:00-13:00</List.Item>
          </List>
          <p>Λίγα λόγια για την ΔΙ.Α.ΔΥ.ΜΑ</p>
          <p>
            <a className="underline-on-hover" href="https://diadyma.gr/">
              Η Ανώνυμη Εταιρεία Διαχείρισης Απορριμμάτων Δυτικής Μακεδονίας
              (ΔΙΑΔΥΜΑ ΑΕ)
            </a>{' '}
            ιδρύθηκε το 1998 και είναι ο Φορέας Διαχείρισης Απορριμμάτων (ΦοΔΣΑ)
            της Περιφέρεια Δυτικής Μακεδονίας.Έχοντας ως προτεραιότητα "Τον
            Πολίτη στο Περιβάλλον του", αξιοποιήθηκαν οι διαθέσιμοι πόροι και τα
            πλεονεκτήματα της περιοχής, σχεδιάστηκε ένα ολοκληρωμένο σύστημα
            διαχείρισης απορριμμάτων, ικανό να προσαρμόζεται στις ολοένα
            αυξανόμενες απαιτήσεις της περιβαλλοντικής νομοθεσίας. Η επίτευξη
            των στόχων και των υποχρεώσεων διαδέχονται η μία την άλλη και
            κατατάσσουν την περιφέρειά μας μεταξύ των "Καλών Πρακτικών" στην
            διαχείριση των στερεών αποβλήτων, σε επίπεδο Ευρωπαϊκής Ένωσης.
          </p>
          <p style={{ fontWeight: 'bold' }}>Join us</p>
          <List relaxed className="decorated green check icon">
            <List.Item>
              <span>
                για να προωθήσουμε την ιδέα και τα οφέλη της επαναχρησιμοποίησης
              </span>
            </List.Item>
            <List.Item>
              <span>
                για να μειώσουμε τα απόβλητα που καταλήγουν σε υγειονομική ταφή
              </span>
            </List.Item>
            <List.Item>
              <span>
                για να βοηθήσουμε τους συνανθρώπους μας να έχουν πρόσβαση σε
                αγαθά
              </span>
            </List.Item>
            <List.Item>
              <span>
                για να δημιουργήσουμε ένα θετικό τοπικό/ κοινωνικό αντίκτυπο
              </span>
            </List.Item>
          </List>
        </Container>
      </Segment>
      <Container text>
        <Grid
          padded="vertically"
          className="compact"
          divided
          stackable
          verticalAlign="bottom"
          stretched
          columns={3}
        >
          <Grid.Column>
            <Link to="questions" className="underline-on-hover">
              <Image className="centered" src={pickupImg} size="small" />
              <Header as="h3" textAlign="center">
                Διαδικασία Παραλαβής
              </Header>
            </Link>
          </Grid.Column>
          <Grid.Column>
            <Link to="questions" className="underline-on-hover">
              <Image className="centered" src={turninImg} size="small" />
              <Header as="h3" textAlign="center">
                Διαδικασία Παράδοσης
              </Header>
            </Link>
          </Grid.Column>
          <Grid.Column>
            <Link to="questions" className="underline-on-hover">
              <Image className="centered" src={infoImg} size="small" />
              <Header as="h3" textAlign="center">
                Πληροφορίες
              </Header>
            </Link>
          </Grid.Column>
        </Grid>
      </Container>
      <Divider section hidden />
    </>
  );
}

export default ReuseIntro;
