import { useState } from 'react';
import { Message } from 'semantic-ui-react';

export default function SuccessMessage({ message }) {
  const [visible, setVisible] = useState(true);

  return (
    visible && (
      <Message
        onDismiss={() => {
          setVisible(false);
        }}
        success
      >
        {message}
      </Message>
    )
  );
}

export function ErrorMessage({ message }) {
  const [visible, setVisible] = useState(true);

  return (
    visible && (
      <Message
        onDismiss={() => {
          setVisible(false);
        }}
        negative
      >
        {message}
      </Message>
    )
  );
}
