import { useEffect, useState } from 'react';
import { Form, Grid, Icon, Menu, Segment } from 'semantic-ui-react';

import SemanticDatepicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';

import dayjs from 'dayjs';
import 'dayjs/locale/el';

dayjs.locale('el');

const dateRangePresets = [
  { text: 'από πάντα', value: 'bigbang' },
  { text: 'τελευταία εβδομάδα', value: 'week' },
  { text: 'τελευταίος μήνας', value: 'month' },
  { text: 'τελευταίο εξάμηνο', value: 'semester' },
  { text: 'τελευταίο έτος', value: 'year' },
  { text: 'ελεύθερη επιλογή', value: 'custom' },
];

export default function DateFilter({
  dateFrom,
  setDateFrom,
  dateTo,
  setDateTo,
  onDateChange,
  basic = false,
}) {
  const [dateRangePreset, setDateRangePreset] = useState('bigbang');

  const [showDateFilter, setShowDateFilter] = useState(false);

  const handlePresetChanged = (e, { value: preset }) => {
    if (preset === 'custom') {
      // update the preset and leave the date unchanged
      return setDateRangePreset(preset);
    }

    // it's a preset, all of them end with "now"
    let dateFrom = null,
      dateTo = new Date();
    if (preset === 'week') {
      dateFrom = dayjs(dateTo).subtract(1, 'week').toDate();
    } else if (preset === 'month') {
      dateFrom = dayjs(dateTo).subtract(1, 'month').toDate();
    } else if (preset === 'semester') {
      dateFrom = dayjs(dateTo).subtract(6, 'month').toDate();
    } else if (preset === 'year') {
      dateFrom = dayjs(dateTo).subtract(1, 'year').toDate();
    } else {
      // default is since forever (null)
      dateTo = null;
    }
    setDateRangePreset(preset);
    setDateFrom(dateFrom);
    setDateTo(dateTo);
    onDateChange && onDateChange();
  };

  const handleDateFromChanged = (e, { value }) => {
    if (!e) return;

    setDateRangePreset('custom');
    setDateFrom(value);
    onDateChange && onDateChange();
  };

  const handleDateToChanged = (e, { value }) => {
    if (!e) return;

    setDateRangePreset('custom');
    setDateTo(value);
    onDateChange && onDateChange();
  };

  // if all is clear, preset is bigbang
  useEffect(() => {
    if (!dateFrom && !dateTo) {
      setDateRangePreset('bigbang');
    }
  }, [dateFrom, dateTo]);
  // open custom dates when user selects 'custom'
  useEffect(() => {
    if (dateRangePreset === 'custom') {
      setShowDateFilter(true);
    } else {
      setShowDateFilter(false);
    }
  }, [dateRangePreset]);

  const DateFilters = ({ mobile }) => (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: mobile ? 'column' : 'row',
      }}
    >
      <SemanticDatepicker
        format="DD/MM/YYYY"
        locale="el-GR"
        datePickerOnly
        placeholder="αρχή"
        value={dateFrom}
        onChange={handleDateFromChanged}
      />
      &nbsp;
      <Icon name={`${mobile ? 'down' : 'right'} arrow`} />
      {mobile && <div>&nbsp;</div>}
      <SemanticDatepicker
        format="DD/MM/YYYY"
        locale="el-GR"
        datePickerOnly
        placeholder="σήμερα"
        value={dateTo}
        onChange={handleDateToChanged}
      />
    </div>
  );

  return (
    <Segment raised={!basic} basic={basic} size="large" style={{ margin: 0 }}>
      <Grid verticalAlign="middle" className="compact">
        <Grid.Column tablet={16} computer={basic ? 8 : 6} mobile={16}>
          <Form.Dropdown
            label={<strong>Εύρος ανάλυσης: </strong>}
            options={dateRangePresets}
            value={dateRangePreset}
            onChange={handlePresetChanged}
          />
        </Grid.Column>

        <Grid.Column
          width={basic ? 8 : 10}
          only="computer"
          style={{
            visibility: dateRangePreset === 'custom' ? 'visible' : 'hidden',
          }}
        >
          <DateFilters mobile={false} />
        </Grid.Column>

        {showDateFilter && (
          <Grid.Column only=" tablet mobile" width={16}>
            <DateFilters mobile={true} />
          </Grid.Column>
        )}

        <Grid.Column only="mobile" width={16} textAlign="center">
          {dateRangePreset === 'custom' && (
            <Menu compact secondary size="large">
              <Menu.Item
                active
                icon={`caret ${showDateFilter ? 'up' : 'down'}`}
                content={
                  showDateFilter ? undefined : (
                    <span
                      style={{ display: showDateFilter ? 'none' : undefined }}
                    >
                      {dateFrom?.toLocaleDateString('el') || 'αρχή'}
                      &nbsp;
                      <Icon name="right arrow" size="small" />
                      {dateTo?.toLocaleDateString('el') || 'σήμερα'}
                    </span>
                  )
                }
                onClick={() => setShowDateFilter((v) => !v)}
              />
            </Menu>
          )}
        </Grid.Column>
      </Grid>
    </Segment>
  );
}
