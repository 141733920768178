import { useState } from 'react';
import { useQueryClient } from 'react-query';

import {
  Button,
  Divider,
  Image,
  Loader,
  Message,
  Modal,
  Progress,
} from 'semantic-ui-react';

import { ConfirmPopup } from '@zerowaste/components';

function SuccessMessage({ message }) {
  const [visible, setVisible] = useState(true);

  return (
    visible && (
      <Message
        onDismiss={() => {
          setVisible(false);
        }}
        success
      >
        {message}
      </Message>
    )
  );
}

function ImagePreview({ onClose, src, onSubmit }) {
  return (
    <Modal size="small" open onClose={onClose}>
      <Modal.Header>Επισκόπηση φωτογρφαφίας</Modal.Header>
      <Modal.Content>
        <Image
          style={{ margin: '0 auto', backgroundColor: 'hsl(0, 0%, 90%)' }}
          alt="preview"
          src={src}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button
          positive
          onClick={() => {
            onSubmit();
            onClose();
          }}
        >
          Αποθήκευση
        </Button>
        <Button negative onClick={onClose}>
          Ακύρωση
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

function ImageCard({
  id,
  useDeleteMutation,
  useSelectMutation,
  mutationType,
  url,
  restData = {},
  setIsDeleted,
  onDelete,
  readOnly,
  imageBg,
  isFetching,
}) {
  const queryClient = useQueryClient();

  const [isHovered, setIsHovered] = useState(false);

  const onSuccess = () => {
    queryClient.invalidateQueries(mutationType);
    setIsDeleted(true);
  };

  const deleteMutation = useDeleteMutation(id, onSuccess);
  const selectMutation = useSelectMutation(id);

  const handleDelete = () => {
    onDelete();
    deleteMutation.mutate && deleteMutation.mutate();
  };
  const { is_cover } = restData;

  const handleSelect = () => {
    if (selectMutation.mutate) {
      selectMutation.mutate(
        { is_cover: !is_cover },
        { onSuccess: () => queryClient.invalidateQueries(mutationType) }
      );
    }
  };

  return (
    <div
      style={{ position: 'relative' }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Image
        style={{
          borderWidth: '1ex',
          borderColor: 'green',
          filter: isHovered && !readOnly ? 'blur(2px)' : 'blur(0)',
          transition: 'all .2s ease',
          height: '100%',
          objectFit: 'contain',
          backgroundColor: imageBg ? 'hsl(0, 0%, 90%)' : 'hsl(0, 0%, 100%)',
        }}
        bordered={is_cover}
        rounded
        alt="cover"
        src={url}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          position: 'absolute',
          gap: '8px',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          transition: 'opacity .2s ease-in-out',
          opacity: isHovered && !readOnly ? 1 : 0,
        }}
      >
        {!readOnly && deleteMutation.mutate && (
          <ConfirmPopup
            trigger={
              <Button
                disabled={deleteMutation.isLoading || deleteMutation.isSuccess}
                loading={deleteMutation.isLoading || deleteMutation.isSuccess}
                color="red"
              >
                Διαγραφή
              </Button>
            }
            header="Θέλετε να Διαγράψετε αυτή τη φωτογραφία;"
            onConfirm={handleDelete}
          />
        )}
        {!readOnly && selectMutation.mutate && (
          <Button
            onClick={handleSelect}
            disabled={selectMutation.isLoading || isFetching}
            loading={selectMutation.isLoading || isFetching}
            color={is_cover ? 'green' : undefined}
          >
            {is_cover ? 'Επιλεγμένο' : 'Επιλογή'}
          </Button>
        )}
      </div>
    </div>
  );
}

function ImageContainer({
  hasOneImage,
  mutationId,
  mutationType,
  useDeleteMutation,
  useSelectMutation,
  images,
  isDeleted,
  setIsDeleted,
  isUploading,
  isUploaded,
  uploadProgress,
  isFetching,
  resetMutations,
  readOnly,
}) {
  if ((!images || images?.length === 0) && !isUploading && !isFetching) {
    return (
      <Message info>
        <Message.Header>Δεν Υπάρχουν εικόνες</Message.Header>
        <Message.Content>
          {readOnly
            ? 'Το συγκεκριμένο υλικό δεν έχει εικόνες'
            : 'Μπορείτε να προσθέσετε εικόνες πατώντας το κουμπί "Επιλογή φωτογραφίας"'}
        </Message.Content>
      </Message>
    );
  }
  if (hasOneImage) {
    return (isUploading || isFetching) && !isDeleted ? (
      <Progress indicating percent={uploadProgress} />
    ) : (
      images && (
        <ImageCard
          id={mutationId}
          mutationType={mutationType}
          useDeleteMutation={useDeleteMutation}
          useSelectMutation={useSelectMutation}
          url={images}
          setIsDeleted={setIsDeleted}
          onDelete={resetMutations}
          readOnly={readOnly}
          imageBg={true}
          isFetching={isFetching}
        />
      )
    );
  } else {
    return (
      <>
        {images.map(({ url, id, ...rest }) => (
          <ImageCard
            key={id}
            id={id}
            restData={rest}
            mutationType={mutationType}
            useDeleteMutation={useDeleteMutation}
            useSelectMutation={useSelectMutation}
            url={url}
            setIsDeleted={setIsDeleted}
            onDelete={resetMutations}
            readOnly={readOnly}
            imageBg={false}
            isFetching={isFetching}
            />
        ))}
        {(isUploading || (isFetching && isUploaded)) && !isDeleted && (
          <Progress indicating percent={uploadProgress} />
        )}
      </>
    );
  }
}

export default function ImageModal({
  hasOneImage = false,
  images,
  onClose,
  mutationId,
  mutationType,
  useUploadMutation = () => ({}),
  useDeleteMutation = () => ({}),
  useSelectMutation = () => ({}),
  status = {},
  readOnly,
  preview = false,
  hideUpload = false,
}) {
  const [userSelectImage, setUserSelectImage] = useState();
  const [isDeleted, setIsDeleted] = useState(false);

  const queryClient = useQueryClient();

  const onSuccess = () => {
    queryClient.invalidateQueries(mutationType);
  };

  const { progress, uploadMutation } = useUploadMutation(mutationId, onSuccess);

  const handleSubmit = () => {
    const formData = new FormData();
    userSelectImage && formData.append('image', userSelectImage);
    uploadMutation && uploadMutation.mutate(formData);
  };

  const handleImageSelect = (e) => {
    setUserSelectImage(e.target.files ? e.target.files[0] : null);
  };
  const handlePreviewClose = () => {
    setUserSelectImage(null);
  };

  const resetMutations = () => {
    uploadMutation && uploadMutation.reset();
    setIsDeleted(false);
  };

  const clearSelectValue = (e) => {
    e.target.value = null;
    resetMutations();
  };

  const { isLoading, isFetching, isFetched } = status;
  return (
    <>
      <Modal size="small" open onClose={onClose} dimmer="inverted">
        <Modal.Header>Φωτογραφίες</Modal.Header>
        <Modal.Content>
          {isDeleted && !isFetching && (
            <SuccessMessage message="Η φωτογραφία διαγράφηκε επιτυχώς" />
          )}
          {uploadMutation?.isSuccess && !isFetching && (
            <SuccessMessage message="Η φωτογραφία προστέθηκε επιτυχώς" />
          )}
          {isLoading ? (
            <Loader inline="centered" style={{ margin: '0 auto' }} />
          ) : (
            <div
              style={{
                display: 'grid',
                justifyContent: 'center',
                gridTemplateColumns:
                  (isFetched && images && images?.length > 0) ||
                  uploadMutation?.isLoading ||
                  isFetching ||
                  (readOnly && images?.length > 0)
                    ? 'repeat(auto-fit, minmax(100px, 200px))'
                    : '100%',
                gap: '16px',
                gridAutoRows: '1fr',
              }}
            >
              <ImageContainer
                hasOneImage={hasOneImage}
                mutationId={mutationId}
                images={images}
                setIsDeleted={setIsDeleted}
                isUploading={uploadMutation?.isLoading}
                isUploaded={uploadMutation?.isSuccess}
                uploadProgress={progress}
                isFetching={isFetching}
                isDeleted={isDeleted}
                useDeleteMutation={useDeleteMutation}
                useSelectMutation={useSelectMutation}
                mutationType={mutationType}
                resetMutations={resetMutations}
                readOnly={readOnly}
              />
            </div>
          )}
          {!readOnly && !hideUpload && (
            <>
              <Divider />
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '24px',
                  justifyContent: 'center',
                }}
              >
                <Button
                  as="label"
                  htmlFor="upload-photo"
                  type="button"
                  icon="file image outline"
                  content="Επιλογή φωτογραφίας"
                />
              </div>
              <input
                hidden
                type="file"
                id="upload-photo"
                name="image"
                accept="image/*"
                onChange={handleImageSelect}
                onClick={clearSelectValue}
              />
            </>
          )}
        </Modal.Content>
        {preview && (
          <Modal.Actions>
            <Button content="Κλείσιμο" onClick={onClose} />
          </Modal.Actions>
        )}
      </Modal>
      {userSelectImage && (
        <ImagePreview
          src={URL.createObjectURL(userSelectImage)}
          onClose={handlePreviewClose}
          onSubmit={handleSubmit}
        />
      )}
    </>
  );
}
