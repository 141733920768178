import { useQuery } from 'react-query';

import axios from 'axios';

export const useParticipants = (params, options) =>
  useQuery(
    ['participants', params],
    () => axios.get('/api/participants/', { params }).then(({ data }) => data),
    { keepPreviousData: true, ...options }
  );
